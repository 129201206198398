
.media-viewer {
    display: flex;
    cursor: pointer;
    &__image {
        width: 144px;
        height: 144px;
        object-fit: cover;
    }

    &__video {
        width: 144px;
        height: 144px;
        object-fit: cover;
    }
}

.media-viewer-blur {
    width: 144px;
    height: 144px;
    position: relative;
    cursor: pointer;

    .blur {
        width: 100%;
        height: 100%;
        background-color: #b9b9b9;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: blur(5px);
    }

    &::before {
        content: '';
        width: 50px;
        height: 50px;
        background-image: url('../../../assets/search-icons/download.svg');
        background-size: cover;
        position: absolute;
        filter: none;
        z-index: 1;
        top: 31%;
        left: 31%;
    }
}
