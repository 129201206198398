
.wrap-preview {
    .clear-chat-button {
        padding: 15px;
        border: none;
        cursor: pointer;
        color: #1C1C1C;
        background: none;
    }

    .row-button {
        display: flex;
        justify-content: flex-end;
    }

    flex: 1;

    .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .container {
        position: relative;
        height: 628px;
        overflow: auto;
        scrollbar-width: none;
        background: #E9E3D8;
        border-radius: 8px;
        padding: 16px;
    }

}

.send-loader {
    width: 20px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
    background: var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }
    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }
    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }
    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}

.form-preview {
    display: flex;
    width: 100%;
    padding: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #E9E3D8;

    .input {
        width: 100%;
        padding: 10px 12px;
        background: #FFFFFF;
        border-radius: 8px 0 0 8px;
        border: 1px solid #A6A6A6;

        &:focus {
            border: 1px solid #B9439D;
        }
    }

    .button {
        cursor: pointer;
        border: none;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 0 8px 8px 0;
        background: #B9439D;

        &:disabled {
            background: #A6A6A6;
        }
    }

}

