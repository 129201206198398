
.button {
    cursor: pointer;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 10px 13px;
    border-radius: 5px;
    background: #A23A99;
    color: #ffffff;
    border: none;

    &--inverse {
        background: #ffffff;
        border: 1px solid #A23A99;
        color: #A23A99;
    }

    &:active {
        transform: scale(1.01);
    }

    &:disabled {
        cursor: initial;
        opacity: 0.8;

        &:active {
            transform: none;
        }

    }

}

.container {
    display: flex;
    align-items: center;
    column-gap: 11px;
}

.plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
    border: 1.5px solid #FFFFFF;
    border-radius: 3px;
}
