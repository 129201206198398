
.row-events-button {
    position: absolute;
    bottom: 13px;
    left: 15px;
    display: flex;
    column-gap: 10px;
}
.events-button {
    display: flex;
    column-gap: 13px;
    align-items: center;
    //max-width: 103px;
    min-height: 22px;
    cursor: pointer;
    border: none;
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    background: #4DB048;
    border-radius: 5px;
    z-index: 100;

    &--empty {
        background: #575757;
    }
}

.events-arrow {
    width: 8px;
    height: 4px;
    background: url('../../assets/deal-modal-arrow.svg') no-repeat center center;
    background-size: cover;
    transform: rotate(180deg);
    transition: 0.3s;

    &.show {
        transform: rotate(0deg);
    }
}

.events-list {
    display: none;
    position: absolute;
    background: white;
    cursor: initial;
    left: 15px;
    bottom: 41px;
    color: black;
    max-height: 80%;
    overflow: auto;
    width: 315px;
    padding: 15px 16px 11px;
    border: 1px solid #dddddd;
    border-radius: 5px 5px 5px 0px;
    z-index: 1000;

    &.show {
        display: block;
    }
}

.events-list-item {
    &:not(:last-child) {
        border-bottom: 1px solid #e3e3e3;
        margin-bottom: 16px;
    }

    padding-bottom: 16px;

    &__row {
        display: flex;
        text-align: left;
        padding-right: 10px;
        margin-bottom: 9px;

        & > * {
            flex-basis: 50%;
        }

        & > :first-child {
            display: flex;
            align-items: center;
            column-gap: 9px;
        }

        &:last-child {
            margin-bottom: 12px;
        }

        & > :first-child {
            font-weight: 600;
        }

        & > :last-child {
            font-weight: 400;
        }
    }
}

.button-link-calendar {
    display: flex;
    align-items: center;
    padding: 5px 13px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
}
