
@import '@/styles/_variables.scss';
.profile__carousel {
    width: 550px;
    @media (max-width: 960px){
        width: 350px;
    }
    @media (max-width: 475px){
        width: 250px;
    }
}

.profile__deals-head {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    @media (max-width: 960px){
       width: 350px;
    }
    @media (max-width: 475px){
       width: 250px;
    }
}

.slick-track {
    display: flex;
    gap: 20px;
}

.current-deals {
    display: flex;
    align-items: center;
    gap: 10px;
}

.current-deal {
    font-family: 'Roboto Flex', sans-serif;
    padding-bottom: 5px;
    //width: 100px !important;
    text-align: center;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    &.--active {
        font-weight: 500;
        color: $color-main-purple;
        border-bottom: 2px solid $color-main-purple;
    }
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    left: 30px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    z-index: 20;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.component {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.show-animation {
    opacity: 1;
}
