
.quick-templates-messages {
    height: 100%;
}

.quick_messages  {
    &__function {
        display: flex;
        justify-content: end;
        font-size: 12px;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none;
        }

       -ms-overflow-style: none;

      ul {
          display: grid;
          row-gap: 5px;
          padding: 5px;
      }

        &-without-topic {
            padding-top: 6px;

            ul {
                li:last-child {
                    .message-box {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        &-media-answers {
            ul {
                display: grid;
                row-gap: 5px;
                padding: 5px;


                li:last-child {
                    .quick_messages-answer-value {
                        margin-bottom: 12px;
                    }
                }

                .quick_messages-answer-value {
                    width: fit-content;
                    border-radius: 8px;
                    padding: 12px;
                    cursor: pointer;
                    text-overflow: ellipsis;
                    justify-self: end;
                    background-color: #DBEAFE;

                    &:hover {
                        outline: 1px solid #B9439D;
                    }


                    &-documents {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-info {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        &-button {
                            border-radius: 8px;
                            background-color: inherit;
                            color: #3B82F6;
                            cursor: pointer;
                        }
                    }

                    &-image-preview {
                        width: 135px;
                        height: 92px;
                        border-radius: 8px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            display: block;
                        }
                    }
                }
            }

            &-no-category {
                ul {
                    padding-bottom: 50px;

                    max-height: 200px;
                }
            }
        }
    }
}

.message-box {
    width: fit-content;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    text-overflow: ellipsis;
    justify-self: end;
    background-color: #E4F3E5;
    border: 1px solid #B6DFB8;

    &:hover {
        outline: 1px solid #B9439D;
    }
}

.topic-message-box {
    background-color: #E6E6E6;
    border: 1px solid #E6E6E6;
}

ul {
    display: grid;
    row-gap: 5px;

  li {
      width: auto;
      border-radius: 4px;
      cursor: pointer;
      text-overflow: ellipsis;

  }
}

.fadeOnRightAnimationClass {
    animation-name: fadeInQMList;
    animation-duration: 1ms;
}

@keyframes fadeInQMList {
  from {
      opacity: 0;
      transform: translateX(40px);
  }

  to {
      opacity: 1;
  }
}

@keyframes fadeInQMListReverse {
    from {
        opacity: 0;
        transform: translateX(-40px);
    }

    to {
        opacity: 1;
    }
}

.nextListAnimation {
    animation-name: fadeInQMList;
    animation-duration: 0.3s;
    scrollbar-width: none;


    ::-webkit-scrollbar {
        display: none;
    }
}

.nextListReverseAnimation {
    animation-name: fadeInQMListReverse;
    animation-duration: 0.3s;
}

.paidList {
    max-height: 360px;
    overflow-y: auto;
}

.not-allowed-templates {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
        font-size: 12px;
        text-align: center;
        padding: 5px;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
