
.invalid-feedback {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
}
.text-right {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #575757;
    cursor: pointer;
    gap: 5px;
}
