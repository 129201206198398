.deals .scrolly-bar.axis-y {
  width: 8px !important;
  border: 1px solid transparent !important;
  right: -9px !important;
}

.cols .scrolly-bar.axis-x {
  height: 8px !important;
  border: 1px solid transparent !important;
  bottom: -8px !important;
}

.scrolly .scrolly-bar {
  //opacity: .2 !important;
}

.scrolly:hover .scrolly-bar, .scrolly.is-scrolling .scrolly-bar {
  opacity: 0 !important;
}

.scrolly:hover > .scrolly-bar {
  opacity: 1 !important;
}

.scrolly.is-scrolling > .scrolly-bar {
  opacity: 1 !important;
}