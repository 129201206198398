
.select-country {
    margin-bottom: 10px;
}

.open-after-create {
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-top: 10px;

    input {
        margin: 0;
    }
}

.event-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
