.multiselect__tags {
  border: 1px solid #A6A6A6;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  height: 40px;
}

.multiselect__tag {
  color: #1C1C1C;
  background-color: inherit;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background-color: inherit;
}

.multiselect__tags-wrap {
  padding: 4px 0 0 4px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.multiselect__option--selected {
  background-color: transparent !important;
  color: inherit !important;
}

.multiselect__option--highlight {
  background-color: transparent !important;
  color: inherit !important;
}

.multiselect__placeholder {
  margin: 0;
}

.multiselect__content-wrapper {
  font-size: 14px;
}
