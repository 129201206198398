
.emoji-mart {
    max-height: 300px !important;
    overflow-y: auto !important;
}
.smiles-dropdown {
    position: absolute !important;
    bottom: 80%;
    left: calc(50% + 50px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translateY(10px) translateX(-270px);
    background: #fff;
    border-radius: 4px;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    z-index: 99999;

    &.--show {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(0) translateX(-270px);
    }

    & > div {
        max-height: 230px;
        overflow-y: auto;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 3px;
    }

    &:after, &:before {
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        top: 100%;
        right: 13px;
    }
}

