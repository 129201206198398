
.modal-box-1 {
    padding: 10px;

}


.modal-box-1__body {
    padding: 25px 32px 31px 55px;
}

.row {
    display: flex;
    align-items: center;
    column-gap: 55px;
    font-size: 13px;
    color: #262626;

    label {
        font-weight: 400;
    }
}

.input-wrap {
    max-width: 140px;
}

.time-wrap {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.input {
    width: 51px;
    height: 29px;
    padding: 10px;
    font-size: 12px;
    color: #3E3E3E;
}
