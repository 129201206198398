
.date-picker-container{
    display: flex;
    position: relative;
    .date-picker-input{
        flex: 1;
        padding: 4px 22px 4px 8px;
        border: 1px solid #A6A6A6;
        border-radius: 4px;
        &:disabled{
            background: #C7C7C7;
            border-color: #C7C7C7;
            cursor: not-allowed;
            color: #A6A6A6;
        }
    }
    .icon{
        position: absolute;
        background: url("../assets/calendar-input.svg");
        top: 5px;
        right: 8px;
        width: 14px;
        height: 14px;
    }
}

