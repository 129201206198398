
.call-icon {
  width: 15px;
  height: 15px;
  left: -5px;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;

  &:hover {
    width: 25px;
    height: 22px;
    background: #63BA7C;
    left: -12px;
    padding: 4px;
    border-radius: 50%;
  }
}

.inst-icon {
  width: 15px;
  height: 15px;
  left: -5px;
  cursor: not-allowed;
  position: absolute;
  box-sizing: border-box;
}
