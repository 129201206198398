
.row {
    margin-top: 15px;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
}

.modal-box-1 {
    padding: 10px;
    min-width: 480px;

    &__head {
        padding: 0;
        border: none;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        color: #1c1c1c;
    }

    &__phones {
        margin-top: 20px;
        display: flex;
        align-items: start;
        gap: 30px;
        background: #e3e3e8;
        padding: 12px;
        border-radius: 8px;
    }

    &__phones-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__phone-item {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 2px;

        &.--not-allow {
            // opacity: 0.6;
        }
    }

    &__contact-name {
        font-weight: 500;
        color: #17171c;
    }

    &__phone-name {
        font-weight: 400;
        color: #505162;
    }

    &__phone-number {
        font-weight: 500;
        color: #17171c;
    }
}

.btn {
    &__close {
        cursor: pointer;
    }

    &__cancel {
        padding: 10px 0;
        cursor: pointer;
        background: #fff;
        border: none;
        font-weight: 500;
    }

    &__delete {
        padding: 10px 0;
        cursor: pointer;
        border: none;
        color: #e41b1b;
        background: transparent;
        font-weight: 500;
    }
}

.error-message {
    color: #e41b1b;
    font-size: 12px;
}
