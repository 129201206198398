
.search-button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: #575757;
    cursor: pointer;
}

.search-area {
    display: flex;
    align-items: center;
    color: #575757;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.close-button {
    cursor: pointer;
    font-size: 12px;
}

.arrows-wrapper {
    display: flex;
    gap: 14px;
    margin-left: 20px;
    margin-right: 6px;
}

.input-wrapper {
    position: relative;
    margin-right: 4px;

    .search-input {
        width: 361px;
        background: #E6E6E6;
        border: none;
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 30px;
        padding-right: 8px;

        &:focus {
            border: 1px solid #B9439D;
            background: transparent;
        }
    }

    &::before {
        content: url('../../../assets/search-icons/input-search.svg');
        position: absolute;
        left: 7px;
        top: 15px;
        transform: translateY(-50%);
        pointer-events: none; 
        color: #A6A6A6;
    }
}

.calendar-button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: #575757;
    cursor: pointer;
}

.date-active {
  color: #a23a99;
}

.clear-icon {
	cursor: pointer;
	position: absolute;
	top: 4px;
	right: 5px;
}

/deep/ .vc-popover-content-wrapper {
  z-index: 9999 !important;
}
