
.header-call {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    transition: 0.2s;
    cursor: pointer;
    padding: 15px 10px 14px 10px;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }

    &__icon {
        background: url('./../assets/img/phone-1.svg') no-repeat -32px 0px;
        width: 16px;
        height: 16px;
        position: relative;
        background-size: cover;
        margin: 2px 0 0;
    }

    &__client {
        color: #fff;
        padding: 0 0 0 10px;
    }
}

.tooltip {
    position: relative;

    &:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%) translateY(5px);
        background: black;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        white-space: nowrap;
        font-size: 12px;
        z-index: 1000;
        opacity: 1;
        transition: opacity 0.6s ease, transform 0.6s ease;
    }

    &:hover::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%) translateY(5px);
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
        z-index: 1000;
        opacity: 1;
        transition: opacity 0.6s ease, transform 0.6s ease;
    }

    &::after,
    &::before {
        opacity: 0;
        transform: translateX(-50%) translateY(0);
        transition: opacity 0.6s ease, transform 0.6s ease;
    }
}
