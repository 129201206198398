@import "./../variables";

.transfer-result-modal {
  position: fixed;
  top: -1px;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 60px;

  display: flex;
  align-items: flex-end; 
  justify-content: center;

  &.--scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__main {
    display: flex;
  }

  &__box {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 24px;
    position: relative;
    max-width: 1200px;
    color: #FFFFFF;
    padding: 16px 25px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .transfer-result-message {
    display: flex;
    justify-content: center;
    gap: 7px;

    img {
      width: 15px;
      height: 12px;
      margin-top: 3px;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
}

.transfer-result-modal-transition-1 {

  &-enter {
    //Начало анимации появления

    &.transfer-modal {
      opacity: 0;
    }

    & .modal__box {
      opacity: 0;
      transform: scale(.1, .1)
    }
  }

  &-enter-active {
    //Анимация появления идет

    &.transfer-modal {
      transition: .3s ease;
    }

    & .modal__box {
      transition: .3s ease;
    }
  }

  &-enter-to {
    //Анимация появления закончилась
  }

  &-leave {
    //Начало анимации исчезновения
  }

  &-leave-active {
    //Анимация исчезнованеия идет
  }

  &-leave-to {
    //Анимация исчзноваения закончилась
  }

}

.transfer-result-modal-box-1__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  div {
    cursor: pointer;
    font-weight: 500;
    color: #B9439D;
  }
}

@media (max-width: 820px) {
  .transfer-result-modal-deal__col-1 {
    display: none;
  }
  .transfer-result-modal-deal__tab-item {
    margin: 15px 0 0 5px;
  }
  .transfer-result-modal-deal__close {
    margin: 0 0 0 10px;
  }
  .transfer-result-modal {
    padding: 10px;
  }
}

@media screen and (max-width: 524px) {
  .transfer-result-modal-deal__tab-name{
    display: none !important;
  }
  
}