
@import '@/styles/_variables.scss';

.modal__box {
    overflow: visible;
}

.modal-box-1 {
    padding: 10px;
    min-width: 480px;

    &__head {
        padding: 0;
        border: none;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        color: #1c1c1c;
    }
}

.btn {
    &__close {
        cursor: pointer;
    }
}

.txt-right {
    margin-top: 25px;
    text-align: right;
}

.btn-primary {
    margin-left: auto;
    cursor: pointer;
    padding: 12px 15px;
    background-color: $color-main-purple;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        color: #8a8a8a;
    }

    &:hover {
        background-color: darken($color-main-purple, 5%);
    }
}
