$namespace: 'mx';

$primary-color: #a23a99;

@import '~vue2-datepicker/scss/index.scss';

// .mx-btn-icon-left, .mx-btn-icon-double-left{
//     display: none !important;
// }

.mx-btn-icon-double-left{
    display: none;
}

.mx-btn-icon-double-right{
    display: none;
}

.mx-datepicker-range {
    width: auto;
    
}

.mx-datepicker-content {
    border-radius: 8px;
}

.mx-datepicker-popup {
    border-radius: 8px;
}

.mx-datepicker-popup::after {
    content: ''; 
    top: -12px;
    right: 104.5px; 
    z-index: 1; 
    width: 15px;
    height: 15px;
    position: absolute;
    background-image: url('/img/TimeTriangle.svg');
    background-repeat: no-repeat;
    background-size: contain;  
}

.mx-time {
    border-radius: 8px;
}

.mx-table {
    thead {
        text-transform: uppercase;
        color: #575757;
    }

    tbody::after {
        content: '';
        display: block;
        position: absolute;
        top: 30px;
        width: 100%;
        height: 1px;
        background-color: #D4D4D4;
    }

    .cell {
        color: #1c1c1c;
    }

    .today {
        color: #ab4ea3
    }
}

.mx-btn-current-month {
    color: #575757;

    &::first-letter {
        text-transform: capitalize;
    }
}

.mx-btn-current-year {
    color: #A6A6A6;
}

.mx-time-column .mx-time-list::after {
    content: '';
    display: block;
    height: 0px;
}