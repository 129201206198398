$vt-namespace: 'Vue-Toastification' !default;
$vt-toast-min-width: 326px !default;
$vt-toast-max-width: 600px !default;
$vt-toast-background: #ffffff !default;

$vt-toast-min-height: 64px !default;
$vt-toast-max-height: 800px !default;

$vt-color-default: #1976d2 !default;
$vt-text-color-default: #fff !default;
$vt-color-info: #2196f3 !default;
$vt-text-color-info: #fff !default;
$vt-color-success: #4caf50 !default;
$vt-text-color-success: #fff !default;
$vt-color-warning: #ffc107 !default;
$vt-text-color-warning: #fff !default;
$vt-color-error: #ff5252 !default;
$vt-text-color-error: #fff !default;

$vt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !default;

$vt-mobile: "only screen and (max-width : 600px)" !default;
$vt-not-mobile: "only screen and (min-width : 600px)" !default;
$vt-font-family: "Lato", Helvetica, "Roboto", Arial, sans-serif !default;
$vt-z-index: 9999 !default;


.#{$vt-namespace}__container {
	z-index: $vt-z-index;
	position: fixed;
	padding: 4px;
	width: $vt-toast-max-width;
	box-sizing: border-box;
	display: flex;
	min-height: 100%;
	color: #fff;
	flex-direction: column;
	pointer-events: none;

	@media #{$vt-not-mobile} {
		&.top-left,
		&.top-right,
		&.top-center {
			top: 1em;
		}

		&.bottom-left,
		&.bottom-right,
		&.bottom-center {
			bottom: 1em;
			flex-direction: column-reverse;
		}

		&.top-left,
		&.bottom-left {
			left: 1em;
			.#{$vt-namespace}__toast {
				margin-right: auto;
			}
			.#{$vt-namespace}__toast--rtl {
				margin-right: unset;
				margin-left: auto;
			}
		}

		&.top-right,
		&.bottom-right {
			right: 1em;
			.#{$vt-namespace}__toast {
				margin-left: auto;
			}
			.#{$vt-namespace}__toast--rtl {
				margin-left: unset;
				margin-right: auto;
			}
		}

		&.top-center,
		&.bottom-center {
			left: 50%;
			margin-left: -($vt-toast-max-width / 2);
			.#{$vt-namespace}__toast {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	@media #{$vt-mobile} {
		width: 100vw;
		padding: 0;
		left: 0;
		margin: 0;
		.#{$vt-namespace}__toast {
			width: 100%;
		}
		&.top-left,
		&.top-right,
		&.top-center {
			top: 0;
		}
		&.bottom-left,
		&.bottom-right,
		&.bottom-center {
			bottom: 0;
			flex-direction: column-reverse;
		}
	}
}


.#{$vt-namespace}__toast {
	display: inline-flex;
	position: relative;
	max-height: $vt-toast-max-height;
	min-height: $vt-toast-min-height;
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding: 22px 24px;
	border-radius: 8px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	justify-content: space-between;
	font-family: $vt-font-family;
	max-width:$vt-toast-max-width;
	min-width: $vt-toast-min-width;
	pointer-events: auto;
	overflow: hidden;
	direction: ltr;
	&--rtl {
		direction: rtl;
	}

	&--default {
		background-color: $vt-color-default;
		color: $vt-text-color-default;
	}
	&--info {
		background-color: $vt-color-info;
		color: $vt-text-color-info;
	}
	&--success {
		background-color: $vt-color-success;
		color: $vt-text-color-success;
	}
	&--error {
		background-color: $vt-color-error;
		color: $vt-text-color-error;
	}
	&--warning {
		background-color: $vt-color-warning;
		color: $vt-text-color-warning;
	}

	@media #{$vt-mobile} {
		border-radius: 0px;
		margin-bottom: 0.5rem;
	}

	&-body {
		flex: 1;
		line-height: 24px;
		font-size: 16px;
		word-break: break-word;
		white-space: pre-wrap;
	}

	&-component-body {
		flex: 1;
	}

	&.disable-transition {
		transition: none !important;
		animation: none !important;
	}
}


.#{$vt-namespace}__close-button {
	font-weight: bold;
	font-size: 24px;
	line-height: 24px;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	padding-left: 10px;
	cursor: pointer;
	transition: 0.3s ease;
	align-items: center;
	color: #fff;
	opacity: 0.3;
	transition: visibility 0s, opacity 0.2s linear;
	&:hover,
	&:focus {
		opacity: 1;
	}
	.#{$vt-namespace}__toast:not(:hover) &.show-on-hover {
		opacity: 0;
	}
	.#{$vt-namespace}__toast--rtl & {
		padding-left: unset;
		padding-right: 10px;
	}
}


@keyframes scale-x-frames {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}


.#{$vt-namespace}__progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	z-index: ($vt-z-index + 1);
	background-color: rgba(255, 255, 255, 0.7);
	transform-origin: left;
	animation: scale-x-frames linear 1 forwards;
	.#{$vt-namespace}__toast--rtl & {
		right: 0;
		left: unset;
		transform-origin: right;
	}
}


.#{$vt-namespace}__icon {
	margin: auto 18px auto 0px;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	transition: 0.3s ease;
	align-items: center;
	width: 20px;
	height: 100%;
	.#{$vt-namespace}__toast--rtl & {
		margin: auto 0px auto 18px;
	}
}


// Bounce Animation taken from https://github.com/fkhadra/react-toastify
$trans-cubic-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
@mixin timing-function {
	animation-timing-function: $trans-cubic-bezier;
}

@keyframes bounceInRight {
	from,
	60%,
	75%,
	90%,
	to {
		@include timing-function;
	}
	from {
		opacity: 0;
		transform: translate3d(3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0);
	}
	75% {
		transform: translate3d(10px, 0, 0);
	}
	90% {
		transform: translate3d(-5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@keyframes bounceOutRight {
	40% {
		opacity: 1;
		transform: translate3d(-20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(1000px, 0, 0);
	}
}

@keyframes bounceInLeft {
	from,
	60%,
	75%,
	90%,
	to {
		@include timing-function;
	}
	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}
	75% {
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		transform: translate3d(5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@keyframes bounceOutLeft {
	20% {
		opacity: 1;
		transform: translate3d(20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

@keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		@include timing-function;
	}
	from {
		opacity: 0;
		transform: translate3d(0, 3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	75% {
		transform: translate3d(0, 10px, 0);
	}
	90% {
		transform: translate3d(0, -5px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}
	40%,
	45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

@keyframes bounceInDown {
	from,
	60%,
	75%,
	90%,
	to {
		@include timing-function;
	}
	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}
	75% {
		transform: translate3d(0, -10px, 0);
	}
	90% {
		transform: translate3d(0, 5px, 0);
	}
	to {
		transform: none;
	}
}

@keyframes bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}
	40%,
	45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

@keyframes bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}
	40%,
	45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

.#{$vt-namespace}__bounce-enter-active {
	&.top-left,
	&.bottom-left {
		animation-name: bounceInLeft;
	}
	&.top-right,
	&.bottom-right {
		animation-name: bounceInRight;
	}
	&.top-center {
		animation-name: bounceInDown;
	}
	&.bottom-center {
		animation-name: bounceInUp;
	}
}

.#{$vt-namespace}__bounce-leave-active {
	&.top-left,
	&.bottom-left {
		animation-name: bounceOutLeft;
	}
	&.top-right,
	&.bottom-right {
		animation-name: bounceOutRight;
	}
	&.top-center {
		animation-name: bounceOutUp;
	}
	&.bottom-center {
		animation-name: bounceOutDown;
	}
}

.#{$vt-namespace}__bounce-move {
	transition-timing-function: ease-in-out;
	transition-property: all;
	transition-duration: 400ms;
}


$trans-cubic-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
@mixin timing-function {
	animation-timing-function: $trans-cubic-bezier;
}

/* ----------------------------------------------
 * Modified version from Animista
 * Animista is Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes fadeOutTop {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-50px);
		opacity: 0;
	}
}

@keyframes fadeOutLeft {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(-50px);
		opacity: 0;
	}
}

@keyframes fadeOutBottom {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(50px);
		opacity: 0;
	}
}

@keyframes fadeOutRight {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(50px);
		opacity: 0;
	}
}

@keyframes fadeInLeft {
	0% {
		transform: translateX(-50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fadeInRight {
	0% {
		transform: translateX(50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fadeInTop {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fadeInBottom {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.#{$vt-namespace}__fade-enter-active {
	&.top-left,
	&.bottom-left {
		animation-name: fadeInLeft;
	}
	&.top-right,
	&.bottom-right {
		animation-name: fadeInRight;
	}
	&.top-center {
		animation-name: fadeInTop;
	}
	&.bottom-center {
		animation-name: fadeInBottom;
	}
}

.#{$vt-namespace}__fade-leave-active {
	&.top-left,
	&.bottom-left {
		animation-name: fadeOutLeft;
	}
	&.top-right,
	&.bottom-right {
		animation-name: fadeOutRight;
	}
	&.top-center {
		animation-name: fadeOutTop;
	}
	&.bottom-center {
		animation-name: fadeOutBottom;
	}
}

.#{$vt-namespace}__fade-move {
	transition-timing-function: ease-in-out;
	transition-property: all;
	transition-duration: 400ms;
}


$trans-cubic-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
@mixin timing-function {
	animation-timing-function: $trans-cubic-bezier;
}

/* ----------------------------------------------
 * Modified version from Animista
 * Animista is Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes slideInBlurredLeft {
	0% {
		transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
		transform-origin: 100% 50%;
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		transform: translateX(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slideInBlurredTop {
	0% {
		transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
		transform-origin: 50% 0%;
		filter: blur(240px);
		opacity: 0;
	}
	100% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slideInBlurredRight {
	0% {
		transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		transform-origin: 0% 50%;
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		transform: translateX(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slideInBlurredBottom {
	0% {
		transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
		transform-origin: 50% 100%;
		filter: blur(240px);
		opacity: 0;
	}
	100% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slideOutBlurredTop {
	0% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 0%;
		filter: blur(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-1000px) scaleY(2) scaleX(0.2);
		transform-origin: 50% 0%;
		filter: blur(240px);
		opacity: 0;
	}
}

@keyframes slideOutBlurredBottom {
	0% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
	100% {
		transform: translateY(1000px) scaleY(2) scaleX(0.2);
		transform-origin: 50% 100%;
		filter: blur(240px);
		opacity: 0;
	}
}

@keyframes slideOutBlurredLeft {
	0% {
		transform: translateX(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
	100% {
		transform: translateX(-1000px) scaleX(2) scaleY(0.2);
		transform-origin: 100% 50%;
		filter: blur(40px);
		opacity: 0;
	}
}

@keyframes slideOutBlurredRight {
	0% {
		transform: translateX(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
	100% {
		transform: translateX(1000px) scaleX(2) scaleY(0.2);
		transform-origin: 0% 50%;
		filter: blur(40px);
		opacity: 0;
	}
}


.#{$vt-namespace}__slideBlurred-enter-active {
	&.top-left,
	&.bottom-left {
		animation-name: slideInBlurredLeft;
	}
	&.top-right,
	&.bottom-right {
		animation-name: slideInBlurredRight;
	}
	&.top-center {
		animation-name: slideInBlurredTop;
	}
	&.bottom-center {
		animation-name: slideInBlurredBottom;
	}
}

.#{$vt-namespace}__slideBlurred-leave-active {
	&.top-left,
	&.bottom-left {
		animation-name: slideOutBlurredLeft;
	}
	&.top-right,
	&.bottom-right {
		animation-name: slideOutBlurredRight;
	}
	&.top-center {
		animation-name: slideOutBlurredTop;
	}
	&.bottom-center {
		animation-name: slideOutBlurredBottom;
	}
}

.#{$vt-namespace}__slideBlurred-move {
	transition-timing-function: ease-in-out;
	transition-property: all;
	transition-duration: 400ms;
}
