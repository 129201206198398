<template>
  <div class="modal">
    <div class="modal__main">
      <div class="modal__box">
        <div
          class="modal-box-1"
          style="width: 310px"
        >
          <div class="modal-box-1__head">
            <div class="modal-box-1__title">
              Отмена записи
            </div>
            <div
              class="modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="modal-box-1__body">
            <div
              class="loader"
              :class="[loader ? '--show' : '']"
            >
              <div class="loader__spinner" />
            </div>
            <form
              class="form"
              @submit.prevent="send"
            >
              <div class="row">
                <div class="col-12">
                  <label style="width: 100%">
                    <span>
                      Причина:
                    </span>
                    <select
                      v-model="value"
                      class="form-control"
                    >
                      <option
                        :value="null"
                        disabled
                      >
                        Не выбрано
                      </option>
                      <option
                        v-for="item in list"
                        :key="item.id"
                        :value="item.id"
                      >{{ item.name }}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <label class="form-label">Комментарий:</label>
                  <TextareaResize
                    :id="'comment'"
                    v-model="comment"
                    class="form-control"
                  />
                  <div class="max-size">
                    {{ comment.length }}/100
                  </div>
                </div>
              </div>
              <div class="form__hr" />

              <div class="txt-center">
                <button
                  :disabled="!value || comment.length > 100"
                  type="submit"
                  class="btn"
                >
                  Отменить запись
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TextareaResize from '@/components/common/TextareaResize';

export default {
	name: 'AppDragEventModalComponent',
	computed: {
		...mapState({
			manager: state => state.manager,
			scheduleId: state => state.schedule.scheduleId,
			schedules: state => state.schedule.list,
			deal: state => state.modal.rejectEvent.data.deal,
		}),
		currentSchedule() {
			return this.schedules.find(item => item.id === this.scheduleId);
		},
		list() {
			return this.currentSchedule.rejectList;
		},

	},
	components: {
		TextareaResize,
	},
	data() {
		return {
			value: null,
			loader: false,
			errors: {
				visibility: false,
				available: true,
			},
			comment: '',
		};
	},
	methods: {
		...mapActions({
			closeModal: 'modal/closeModal',
			removeEvent: 'schedule/deleteEvent',
			setModalData: 'modal/setModalData',
		}),
		close() {
			this.setModalData({
				modal: 'rejectEvent',
				data: {},
			});
			this.closeModal('rejectEvent');
		},
		send() {
			const deal = {
				...this.deal,
				reject_id: this.value,
				comment: this.comment,
			};
			this.removeEvent(deal);
			this.setModalData({
				modal: 'rejectEvent',
				data: {},
			});
			this.closeModal('rejectEvent');
		},
	},
};
</script>
<style scoped>
    .max-size {
        text-align: right;
        color: lightgray;
    }
</style>