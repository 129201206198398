
.update-message {
    font-weight: 500;
}

.update-button {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 10px 20px;
    background-color: #ba439e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}
