$xxs: 4px;
$xs: 8px;
$s: 12px;
$m: 16px;
$l: 20px;
$xl: 24px;

$text-primary: #17171C;
$surface-default-primary: #17171C;
$surface-default-secondary: #505162;
$surface-default-tertriany: #9D9EAF;

$fill-inactive-static: #C7C7D1;
$fill-active-static: #83838a;
$fill-active-dynamic: #B9439D;


$accent-500: #B9439D;
$accent-800: #792D67;
