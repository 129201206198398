.history {

	&__wrapper {
		display: flex;
		justify-content: left;
	}

	&__whatsApp {
		flex: 0 0 auto;
		width: 450px;
	}

	&__calls {
		flex: 0 0 auto;
		width: 450px;
	}

	&__transfers {
		flex: 0 0 auto;
		width: 450px;
	}

	&__bpm {
		flex: 0 0 auto;
		width: 450px;
	}

}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.history__calls {
		width: 100%;
	}
}