<template>
  <div class="modal">
    <div class="modal__main">
      <div class="modal__box">
        <div class="modal-box-1">
          <div class="modal-box-1__head">
            <div class="modal-box-1__title">
              Меню
            </div>
            <div
              class="modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="modal-box-1__body">
            <ul class="menu-1">
              <template v-if="menu.funnels.access == true">
                <li
                  v-for="item in availableMenuItems"
                  :key="item.id"
                  class="menu-1__li"
                >
                  <router-link
                    :to="'/funnel/' + item.id"
                    class="menu-1__a"
                    @click.native="clickMenuItem"
                  >
                    <i
                      v-if="getNotificationCount(item) > 0"
                      class="menu-1__a-notification-badge circle-badge circle-badge--modal"
                    >{{ getNotificationCount(item) }}</i>
                    <div class="funnel-wrapper">
                      {{ item.name }}
                      <div
                        v-if="item.notifications.incomingDealsCount"
                        class="deals-count"
                      >
                        <img
                          src="../../assets/img/user-add.svg"
                          alt="incoming"
                        >
                        {{ item.notifications.incomingDealsCount }}
                      </div>
                    </div>
                  </router-link>
                </li>
              </template>

              <li
                v-if="menu.closed.access == true"
                class="menu-1__li"
              >
                <router-link
                  :to="'/closed'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  Закрытые
                </router-link>
              </li>

              <li
                v-if="menu.history.access == true"
                class="menu-1__li"
              >
                <router-link
                  :to="'/history'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  История
                </router-link>
              </li>

              <li
                v-if="menu.schedule.access == true && !$device.mobile"
                class="menu-1__li"
              >
                <router-link
                  :to="'/calendar'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  Расписание
                </router-link>
              </li>

              <li
                v-if="menu.calendarTasks.access == true && !$device.mobile"
                class="menu-1__li"
              >
                <router-link
                  :to="'/calendar-tasks'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  Календарь задач
                </router-link>
              </li>

              <template v-if="menu.statistics.access == true">
                <li class="menu-1__li">
                  <router-link
                    :to="'/stat/communication'"
                    class="menu-1__a"
                    @click.native="clickMenuItem('Коммуникация')"
                  >
                    Коммуникация
                  </router-link>
                </li>
                <li class="menu-1__li">
                  <router-link
                    :to="'/stat/leadgen'"
                    class="menu-1__a"
                    @click.native="clickMenuItem('Лидогенерация')"
                  >
                    Лидогенерация
                  </router-link>
                </li>
              </template>

              <li
                v-if="menu.salesPlan.access == true"
                class="menu-1__li"
              >
                <router-link
                  :to="'/sales_plan'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  План продаж
                </router-link>
              </li>
              <li
                v-if="menu.salesPlan.access == true"
                class="menu-1__li"
              >
                <router-link
                  :to="'/romi'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  ROMI
                </router-link>
              </li>
              <!-- TEMPLATES -->
              <li
                v-if="manager.roleId === 2 && menu.catalog.access"
                class="menu-1__li"
              >
                <router-link
                  :to="'/stat/income-trend'"
                  class="menu-1__a"
                  @click.native="clickMenuItem"
                >
                  Динамика дохода
                </router-link>
              </li>
              <li
                class="menu-1__li"
              >
                <HeaderDropdown
                  v-if="hasAccess"
                  :selected-option="selectedOption"
                  :options="options"
                  @update:selectedOption="updateSelectedOption"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import config from '@/config';
import { host } from '@/api';
import HeaderDropdown from '@/components/Dropdown/HeaderDropdown.vue';
import AppHeaderMenuItemComponent from '@/components/AppHeaderMenuItemComponent.vue';

export default {
	name: 'AppMenuModalComponent',
	components: { AppHeaderMenuItemComponent, HeaderDropdown },
	data() {
		return {
			test: '',
			config: {
				debug: config.debug,
			},
			availableMenuItems: [],
			selectedOption: { name: 'Сервисы', to: '#' },
		};
	},
	computed: {
		...mapState({
			menu: state => state.menu,
			manager: state => state.manager,
			funnels: state => state.funnel.funnels,
		}),
		options() {
			return [
				{ name: 'BPM Tracker', to: '/bpm-tracker', access: this.menu.bpmTracker?.access },
				{ name: 'Шаблоны', to: '/whatsappTemplates', access: this.menu.templates?.access },
				{ name: 'Админ панель', to: '/schedule', access: this.menu.scheduleAdmin?.access },
				{ name: 'Справочники', to: '/catalog', access: this.menu.catalog?.access },
			];
		},
		hasAccess() {
			return this.options.some(option => option.access);
		},
	},
	watch: {
		$route() {
			this.close();
		},
	},
	mounted() {
		this.getMenuItems();
	},
	methods: {
		...mapActions({
			closeModal: 'modal/closeModal',
		}),
		...mapMutations({
			setFunnelNotifications: 'menu/setFunnelNotifications',
		}),
		updateSelectedOption(option) {
			this.selectedOption = option;
		},
		getNotificationCount(funnel) {
			const foundFunnel = this.funnels.find(el => el.id === funnel.id);
			let count = foundFunnel.notifications.calls + foundFunnel.notifications.messages;
			return count;
		},
		close() {
			this.closeModal('menu');
		},
		clickMenuItem() {
			this.$log.info('clickMenuItem');
			this.closeModal('menu');
		},
		async getMenuItems() {
			if (this.menu.funnels.access) {
				const promises = this.menu.funnels.data.map((funnel) => {
					const params = {
						funnelId: funnel.id,
						managerId: this.manager.id,
					};

					return host.get('/api/v1/auth/funnel/get-funnel-notifications', { params });
				});

				const notifications = await Promise.all(promises).then((responses) => {
					return responses.map((response) => response.data);
				});

				this.setFunnelNotifications(notifications);

				this.availableMenuItems = this.menu.funnels.data;
				return;
			}
			this.availableMenuItems = [];
			return;
		},
	},
};
</script>

<style scoped>
.modal__box {
    margin: 0 auto;
    width: 100%;
}
.menu-1__a {
    display: block;
    font-weight: 500;
    color: #333;
    padding: 14px 0 15px;
    text-decoration: none;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    .funnel-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        .deals-count {
            display: flex;
            align-items: center;
            gap: 2px;
            color: #1C1C1C;
            padding: 2px 8px;
            border-radius: 12px;
            background-color: #FCD34D;
            font-size: 12px;
        }
    }
}
.menu-1__li {
    position: relative;
    width: max-content;
    float: none;
    list-style: none;
}
</style>
