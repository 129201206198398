
.overflow {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100dvh;
    overflow-y: auto;
    animation-name: fadeInOverlay;
    animation-duration: 0.3s;
    backdrop-filter: blur(10px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.document-modal {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__save-status {
        display: flex;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 1rem;

        &-success {
            color: #10B981;
        }

        &-error {
            color: #EF4444;
        }
    }
    
    display: flex;
    flex-direction: column;
    margin: 10% auto;
    border: 1px solid #9D9EAF;
    width: 100%;
    max-width: fit-content;
    min-width: 24rem;
    min-height: 6.25rem;
    z-index: 102;
    background-color: #FFFFFF;
    border-radius: 1.5rem;
    padding: 1.75rem 1.5rem;
    user-select: none;
    animation-name: fadeInDialog;
    animation-duration: 0.3s;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        &-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: #1F2937;
        }

        &-close-icon {
            cursor: pointer;
        }
    }

    &__content {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__button {
            color: #3B82F6;
            cursor: pointer;
            transition: all 0.3s ease;
            margin-right: 0.5rem;

            &:hover {
                text-decoration: underline;
            }
        }

        &__file-name {
            cursor: pointer;
        }

        &__input {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
        }

        &__image {
            width: 100%;
            height: 9.375rem;
            border-radius: 0.5rem;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }
    }

    &__buttons {
        &-container {
            display: flex;
            align-self: flex-end;
            gap: 1rem;
        }

        padding: 0.6rem;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;

        &:hover:not(&-save) {
            background-color: #faf8ff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &-save {
            background-color: #b9439d;
            color: #fff;
            height: 40px;
            cursor: pointer;
            transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;

            &:hover {
                opacity: 0.8;
                box-shadow: 0 4px 12px rgba(185, 67, 157, 0.4);
            }

            &:disabled {
                background-color: #d3d3d3;
                color: #aaa;
                cursor: not-allowed;
                box-shadow: none;
                transform: none;
            }
        }

        &-cancel {
            border: none;
            background: none;
            transition: color 0.3s ease, transform 0.2s ease;

            &:hover {
                color: #555;
            }
        }
    }
}

@keyframes fadeInDialog {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeOutDialog {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes fadeInOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
