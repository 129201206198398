
.slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}

