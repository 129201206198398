<template>
  <div
    v-click-outside="() => open = false"
    class="select-wrap"
  >
    <div
      class="custom-select"
      :tabindex="tabindex"
    >
      <div
        class="selected"
        :class="{ open: open }"
        @click="handleDropdown"
      >
        <div v-if="selectedValue">
          <img
            :src="options.find(option => option.id === selectedValue).iconPath"
            class="selected-icon"
          >
          <img
            v-if="options.length > 1"
            :src="DropDownArrow"
            class="dropdown-arrow"
            :class="{ rotate: open }"
          >
        </div>
      </div>
      <div
        class="items"
        :class="{ selectHide: !open }"
      >
        <div
          v-for="(option) of options"
          :key="option.id"
          ref="content"
          style="padding: 0 12px;"
          class="item"
          :class='{"active": option.id === selectedValue}'
          @click="selectOption(option)"
        >
          <div class="option-item">
            <img
              v-if="option.iconPath"
              :src="option.iconPath"
            >
            {{ option.name }}
          </div>
        </div>
      </div>
      <img
        v-if="open"
        :src="DropDownBottom"
        class="dropdown-bottom"
      >
    </div>
  </div>
</template>


<script>
import DropDownArrow from '../../../assets/v2/dropdown-arrow-black.svg';
import DropDownBottom from '../../../assets/v2/dropdown-bottom-arrow.svg';

export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
		defaultValue: {
			type: Object,
			required: false,
			default: null,
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0,
		},
		placeholder: {
			type: String,
			required: false,
			default: '',
		}
	},
	data () {
		return {
			open: false,
			selectedValue: 0,
			DropDownArrow,
			DropDownBottom,
		};
	},
	mounted () {
		this.$emit('input', this.selected);
		this.defaultValue && this.selectOption(this.defaultValue);
	},
	methods: {
		selectOption (option) {
			this.open = false;
			this.selectedValue = option.id;
			this.$emit('input', option);
		},
		cleanSearchInput () {
			this.search = '';
			this.open = false;
			this.$emit('clear-input', null);
		},
		handleDropdown () {
			if (this.options.length > 1) {
				this.open = !this.open;
			}
		},
	},
};
</script>
<style langs="scss" scoped>
.select-wrap {
    position: relative;
}

.dropdown-arrow {
    margin-left: 4px;
    margin-bottom: 2px;
    rotate: 180deg;
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease; 
}

.dropdown-bottom {
    position: absolute;
    bottom: 0;
    left: 34px;
    width: 16px;
    height: 18px;
    top: -13px;
}

.rotate {
    transform: rotate(180deg);
}

.selected-icon {
    width: 22px;
    height: 22px;
    margin-top: 8px;
    margin-left: 6px;
}

.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 40px;
}

.selected {
    position: relative;
    width: 100%;

}

.selected:after {
    content: "";
    position: absolute;
    cursor: pointer;
    top: 17px;
    right: 15px;
    width: 0;
    height: 0;
    display: block;
    transform: rotate(0deg);
}

.items {
    color: #1C1C1C;
    border-radius: 6px;
    overflow-y: auto;
    border: 1px solid #A6A6A6;
    position: absolute;
    background-color: #FFFFFF;
    overflow: hidden;
    width: 135px;
    left: -22px;
    top: -90px;
    z-index: 999;
}

.item {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.item:hover {
    background-color: #A6A6A6;
}

.selectHide {
    display: none;
}

.option-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    font-weight: 400;
}
</style>
  
