
.wrap {
    margin: 0;
    cursor: pointer;
}

.checkbox:checked + .square {
    background: #A23A99;
    position: relative;
    border: none;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        transform: translate(-50%, -50%);
        background: url(../../assets/checkbox.svg) no-repeat center;
        background-size: cover;
    }
}

.checkbox {
    display: none;
}

.square {
    width: 16px;
    height: 16px;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
}
