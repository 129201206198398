<template>
  <div class="transfer-modal">
    <div class="transfer-modal__main">
      <div class="transfer-modal__box">
        <div
          class="transfer-modal-box-1"
          style="width: 540px"
        >
          <div class="transfer-modal-box-1__head">
            <div class="transfer-modal-box-1__title">
              {{ title }}
            </div>
            <div
              class="transfer-modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="transfer-modal-box-1__body">
            <div
              class="loader"
              :class="[loader ? '--show' : '']"
            >
              <div class="loader__spinner" />
            </div>
            <form
              class="form"
              @submit.prevent="massiveActionDeal"
            >
              <div
                class="form-group"
              >
                <DropdownComponent
                  :options="funnels"
                  :placeholder="'Выберите воронку'"
                  class="select"
                  @input="setFunnelId($event)"
                  @clear-input="clearFunnelId"
                />
                <div
                  v-if="!selectedFunnelId"
                  class="disabled-dropdown"
                >
                  Выберите этап
                </div>
                <DropdownComponent
                  v-else
                  :options="selectedStages"
                  :placeholder="'Выберите этап'"
                  class="select chose-step"
                  @input="setFunnelStepId($event)"
                />
                <div
                  v-if="error.length"
                  class="invalid-feedback"
                >
                  {{ error }}
                </div>
              </div>
              <div class="transfer-modal-box-1__buttons">
                <div @click="close">
                  Отмена
                </div>
                <button
                  type="submit"
                  class="transferBtn"
                  :disabled="disable"
                >
                  Подтвердить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { host } from '@/api';
import DropdownComponent from '@/components/Dropdown/CustomDropDownComponent.vue';
    
export default {
	components: {
		DropdownComponent,
	},
	data() {
		return {
			title: '',
			dealsToMove: [],
			funnels: [],
			funnelStages: [],
			disable: false,
			loader: false,
			selectedFunnelId: null,
			selectedFunnelStepId: null,
			error: '',
		};
	},
	computed: {
		...mapState({
			manager: state => state.manager,
			data: state => state.modal.massiveMoveDeal.data,
			funnelInfo: state => state.funnelRefactored.funnelInfo,
		}),
		message() {
			const message = [];
			this.dealsToMove.forEach((deal) => {
				message.push({
					dealId: deal.dealId,
					funnelId: this.funnelInfo.funnelId,
					fnnlID: this.funnelInfo.funnelId,
					funnelStepId: this.selectedFunnelStepId,
					managerId: this.manager.id,
					id: deal.dealId,
					notifications: deal.notifications,
					name: deal.clientName,
					clientId: deal.clientId,
					contacts: deal.contacts,
					phns: deal.clientPhones,
					sum: deal.sum,
					status: deal.dealStatus,
					customField: deal.customField,
					transferId: deal.transferId,
					transferStatus: deal.transferInfo.transferStatus,
					transferToGroupsStatus: deal.groupTransferStatus,
					trnsID: deal.transferInfo.transferId,
					trnsSt: deal.transferInfo.transferStatus
				});
			});
			return message;
		},
		selectedStages() {
			if (this.selectedFunnelId) {
				return this.funnelStages.filter((stage) => stage.funnelId === this.selectedFunnelId)[0].stages.sort((a, b) => a.order - b.order);
			} else {
				return [];
			}
		},
		choosenFunnel() {
			const funnel = this.funnels.filter((funnel) => funnel.id === this.selectedFunnelId);
			const funnelStep = this.selectedStages.filter((step) => step.id === this.selectedFunnelStepId);

			return {
				funnelName: funnel[0].name,
				stepName: funnelStep[0].name,
			};
		}
	},
	created() {
		this.initData();
	},
	methods: {
		...mapActions({
			setModalData: 'modal/setModalData',
			openModal: 'modal/openModal',
			closeModal: 'modal/closeModal',
		}),
		...mapMutations({
			cleanMassiveDealsClosing: 'massiveDealsClosing/cleanDealsArray',
			changeCloseDealsMode: 'massiveDealsClosing/changeCloseDealsMode',
			cleanMassiveDealsMoving: 'massiveDealsMoving/cleanDealsArray',
			changeMoveDealsMode: 'massiveDealsMoving/changeMoveDealsMode',
		}),
		setFunnelId(value) {
			if (value && value.id) {
				this.selectedFunnelId = value.id; 
			}
		},
		clearFunnelId() {
			this.selectedFunnelId = null;
		},
		setFunnelStepId(value) {
			if (value && value.id) {
				this.selectedFunnelStepId = value.id;
			}
		},
		initData() {
			this.title = this.data.title;
			this.dealsToMove = this.data.dealsToMove;
			this.fetchFunnelTransferRules();
		},
		async fetchFunnelTransferRules() {
			const body = {
				managerId: this.manager.id,
				deal: this.dealsToMove[0],
				funnelId: this.funnelInfo.funnelId,
			};

			const { data } = await host.post('/api/v1/auth/get-funnel-transfer-rules', body);

			this.funnels = data.funnels;
			this.funnelStages = data.funnelStages;
		},
		editField() {
			if(this.selectedFunnelId == null) {
				this.error = 'Выберите воронку';
			} else if (this.selectedFunnelStepId == null) {
				this.error = 'Выберите этап';
			} else {
				this.error = '';
			}
		},
		close() {
			this.closeModal('massiveMoveDeal');
		},
		massiveActionDeal() {
			if (this.selectedFunnelId !== null && this.selectedFunnelStepId !== null) {
				this.$socket.emit('massiveTransferToFunnel', 
					{ 
						deals: this.message,
						managerId: this.manager.id,
						funnelId: this.selectedFunnelId,
					},
					(response) => {
						if (response.result === 'succes') {
							this.closeModal('massiveMoveDeal');
							this.setModalData({
								modal: 'massiveTransferResult',
			            	data: {
									text: `${response.transfered} сделки(-ок) перенесены в воронку ${this.choosenFunnel.funnelName} на этап ${this.choosenFunnel.stepName}`,
			            		result: 'success'
			            	},
			            });
			            this.openModal('massiveTransferResult');
							this.cleanMassiveDealsMoving();
							this.changeMoveDealsMode(false);
						} else {
							this.closeModal('massiveMoveDeal');
							this.setModalData({
								modal: 'massiveTransferResult',
			            	data: {
									text: `${response.transfered} сделки(-ок) перенесены в воронку ${this.choosenFunnel.funnelName} на этап ${this.choosenFunnel.stepName}. ${response.failed} сделки(-ок) невозможно перенести`,
			            		result: 'fail'
			            	},
			            });
			            this.openModal('massiveTransferResult');
							this.cleanMassiveDealsMoving();
							this.changeMoveDealsMode(false);
						}
					}
 				);
			}
		}
	}
};
</script>
  
  <style scoped>
  .disabled-dropdown {
      margin-top: 20px;
      width: 100%;
      height: 40px;
      background-color: #C7C7C7;
	  color: #A6A6A6;
      border-radius: 6px;
      padding: 10px 15px;
  }

  .chose-step {
	margin-top: 20px;
  }
  </style>