@import "./../variables";

.deals {
	//flex: 1 0 auto;
	//height: 100%;
	flex: 1 0 auto;

	& > span {
		min-height: 100%;
		display: block;
	}

	&__empty {
		position: absolute;
		pointer-events: none;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		height: 150px;
		background: url('assets/img/logo-cube.svg') no-repeat center;
		background-size: 60%;
	}

	&__scroll {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	& .deal:nth-last-child(1) {
		margin: 0;
	}
}

.deal {
	background: #fff;
	border-radius: 3px;
	margin: 0 0 10px;
	//cursor: pointer;
	border: 1px solid #d6d8db;
	padding: 8px 10px 5px 10px;
	position: relative;

	label {
		display: flex;
		flex-direction: column;
	}

	&:hover {
		//TODO: hover
		border: 1px solid #c1c1c1;
		transition: border .2s ease;

		& .deal-head__favorite {
			//opacity: 1;
			display: block;
		}
	}

	&--outgoing {
		background: #FFEECC;
		border: 1px solid #FFCC90;
	}

	&--transfer-error {
		background: #FEE2E2;
		border: 1px solid #EF4444;
	}

	&--incoming {
		background: rgba(184, 132, 255, 0.2);
		border: 1px solid rgba(103, 58, 183, 0.4);

		&:hover {
			border: 1px solid rgba(103, 58, 183, 1);
		}
	}

	&--disabled {
		background-color: #C7C7C7;
	}

	&.--union {
		background: #efc2eb;
		border: 1px solid #cc8ac6;
	}

	&.show {
		display: block;
	}

	&.hide {
		display: none;
	}

	&__sum {
		//padding: 0 0 0 20px;
		margin: 0 0 3px;
		color: #777;

		& span {
			font-weight: 500;
		}
	}

	&__custom {
		//padding: 0 0 0 20px;
		margin: 0 0 3px;
		color: #777;
		word-break: break-all;
		& span {
			font-weight: 500;
		}
	}

	&__error-description {
		position: relative;
		padding: 2px 0px 0px 0px;
		color: #EF4444;
		font-weight: normal;
		font-weight: 500;
		margin: 0 0 3px;
		font-size: 12px;
		word-break: break-all;
		line-height: 1.3;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 3px;
			left: 0px;
			width: 14px;
			height: 14px;
			//background: url('assets/img/o-clock.svg') no-repeat center;
		}
	}

	&__description {
		position: relative;
		padding: 2px 0px 0px 0px;
		color: #E8A04B;
		font-weight: normal;
		font-weight: 500;
		margin: 0 0 3px;
		font-size: 12px;
		word-break: break-all;
		line-height: 1.3;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 3px;
			left: 0px;
			width: 14px;
			height: 14px;
			//background: url('assets/img/o-clock.svg') no-repeat center;
		}
	}

	&__open {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0;
		right: 30px;
		bottom: 0;
	}

	&__filter {
		position: relative;
		padding: 2px 0px 0px 0px;
		//color: #676767;
		//color: #ff5151;
		font-weight: normal;
		font-weight: 500;
		margin: 0 0 3px;
		font-size: 12px;
		line-height: 1.3;

		&.--red {
			color: #ff5151;
		}

		&.--green {
			color: #3a983e;
		}
	}

	&__found-number {
		position: relative;
		padding: 2px 0px 0px 0px;
		//color: #676767;
		color: $color-main-purple;
		font-weight: normal;
		margin: 0 0 3px;
		font-size: 12px;
		line-height: 1.3;
	}
}
.checkbox__container {
	display: block;
	position: relative;
	padding-left: 27px;
	margin-bottom: 22px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.checkbox__input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #eee;
		border-radius: 7px;
	}

	.checkbox__input:checked ~ .checkmark {
		background-color: #ccc;
	}

	.checkbox__input:checked~ .checkmark {
		background-color: #10B981;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	.checkbox__input:checked + .checkmark:after {
		display: block;
	}

	.checkbox__input:checked + .checkmark:after {
		left: 8px;
		top: 4px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
.deal-head {
	display: flex;
	align-items: center;
	margin: 0 0 3px;

	&__call {
		flex: 0 0 14px;
		margin: 0 6px 0 0;
		//margin: 0 10px 0 0;
		position: relative;
		cursor: pointer;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/phone-1.svg');
			background-size: cover;
			width: 14px;
			height: 14px;
			top: 1px;
			left: 0;
		}

		&:hover {
			&:before {
				background-position: 28px 0;
			}
		}
	}

	&__name {
		flex: 1 1 auto;
		font-weight: 500;
		line-height: 1.2;
		overflow-wrap: break-word;
		// word-wrap: break-word;
		//word-break: break-all;
		line-break: strict;
	}

	&__icons {
		flex: 0 0 14px;
		display: flex;
	}

	&__new-client {
		flex: 0 0 8px;
		position: relative;
		width: 8px;
		height: 16px;
		margin: 0 0 0 5px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/new-client.svg');
			background-size: cover;
			width: 8px;
			height: 8px;
			top: 4px;
			right: 0;
		}
	}

	&__new-message {
		flex: 0 0 14px;
		position: relative;
		width: 14px;
		height: 16px;
		margin: 0 0 0 5px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/new-message.svg');
			background-size: cover;
			width: 14px;
			height: 11px;
			top: 3px;
			right: 0;
		}
	}

	&__favorite {
		flex: 0 0 14px;
		position: relative;
		width: 14px;
		height: 16px;
		margin: 0 0 0 5px;
		cursor: pointer;

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 14px;
			height: 14px;
			top: 0px;
			right: 0;
		}

		&.--no {
			//opacity: 0;
			//transition: opacity .2s ease;
			display: none;

			&:before {
				background: url('assets/img/star.svg');
				background-size: cover;
			}

			&:hover:before {
				background-position: -14px 0;
			}
		}

		&.--yes {

			&:before {
				background: url('assets/img/star.svg');
				background-size: cover;
				background-position: -28px 0;
			}

			&:hover:before {
				background-position: -42px 0;
			}
		}
	}

	&__new-messages {
		background: #ff5151;
		font-size: 10px;
		width: 16px;
		height: 16px;
		text-align: center;
		line-height: 16px;
		border-radius: 50%;
		color: #fff;
	}

	&__missed-call {
		flex: 0 0 16px;
		position: relative;
		width: 16px;
		height: 16px;
		margin: 0 0 0 5px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/missed-call.svg');
			background-size: cover;
			width: 14px;
			height: 14px;
			top: 1px;
			right: 0;
			left: 0;
		}
	}
}

.qwe {
	z-index: 999;
	height: 100%;
}