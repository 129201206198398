
@import '@/styles/_variables.scss';

.modal-box-1 {
    padding: 20px;
}

.modal-box-1__body {
    padding: 0px;
    margin-top: 20px;
}

.btn-add-number {
    padding: 10px;
    cursor: pointer;
    background: #fff;
    border: none;
    color: $color-main-purple;
    margin-bottom: 20px;
}

.modal-box-1__footer {
    display: flex;
}

.btn-primary {
    margin-left: auto;
    cursor: pointer;
    padding: 12px 15px;
    background-color: $color-main-purple;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        color: #8a8a8a;
    }

    &:hover {
        background-color: darken($color-main-purple, 5%);
    }
}
