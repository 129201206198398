
.modal__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #212121;
}

.title {
    display: flex;
    column-gap: 10px;
}

.modal-box-1__head {
    border: none !important;
}

.modal-box-1 {

    max-width: 1200px;
    overflow: auto;
    padding: 25px 31px 30px 25px;
}

input.max-days {
    width: 120px !important;
}

input.confirmed-days {
    width: 180px !important;
}

.row {
    display: flex;
    column-gap: 30px;
}

.column {
    font-size: 12px;
    line-height: 14px;
    color: #3E3E3E;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}

.type-worker-select {
    width: 123px;
}

.period-select {
    width: 100px;
}

.range-row {
    column-gap: 4px;
    display: flex;
    align-items: center;
}

.name-schedule-input {
    width: 175px !important;
}

.date-input {
    width: 51px !important;
    height: 29px !important;
}

.row-btn {
    display: flex;
    justify-content: flex-end;
}
