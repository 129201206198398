
.wrap {
    position: relative;
    color: black;

    li {
        list-style: none;
        cursor: pointer;

    }

    ul {
        padding: 10px;
    }
}

.expanded {
    color: darkgray;
}

.input-container {
    position: relative;

    input {
        font-size: 11px;
    }
}

.input-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 19px;
}

.container-group-select {
    position: absolute;
    min-width: 100%;
    max-width: 239px;
    top: 30px;
    background: white;
    border-radius: 5px;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.nested-list {
    li {
        //word-break: break-word;
        overflow-wrap: break-word;
        &:hover {
            color: #F55CA6;
            transition: 0.3s;
        }
    }
}

.active {
    color: #F55CA6;
}
