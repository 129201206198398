
.instagram-icon {
	width: 25px;
	height: 25px;
}

.no-message-info {
	padding: 15px;
}

.msg-form {
	&__file-wrap {
		align-self: start;
		margin-top: 2px;
	}
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 42px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
