<template>
  <div
    class="lds-ring"
    :style="getStyle"
  >
    <div :style="getStyle" />
    <div :style="getStyle" />
    <div :style="getStyle" />
    <div :style="getStyle" />
  </div>
</template>
<script>
export default {
	props: [
		'width',
		'height'
	],
	computed: {
		getStyle() {
			if (this.width && this.height) {
				return {
					'width': this.width + 'px',
					'height': this.height + 'px',
				};
			}
		},
	},
};
</script>
<style scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: 26px;
    height: 26px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1px solid #4caf50;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #4caf50 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>