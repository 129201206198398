@import './../variables';

.deal-pagination {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__prev {
        // transform: rotate(180deg);

        &-button {
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    &__current {
        padding: 0 15px;
        font-weight: 500;
        color: #505162;
    }

    &__next {
        &-button {
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    &__button {
        cursor: pointer;
        padding: 10px;
        margin: 0;
        border: none;
        outline: none;
        background: transparent;

        &:hover {
            opacity: 0.8;
        }
    }
}
