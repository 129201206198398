@import "./../variables";

.transfer-modal {
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: -1px;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;

  &.--scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__main {
    min-height: 100%;
    display: flex;
  }

  &__box {
    margin: auto;
    background: #fff;
    border-radius: 24px;
    position: relative;
    max-width: 1200px;
  }
}

.transfer-modal-box-1 {
    padding: 24px;

  &__head {
    color: #1C1C1C;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 18px;
    line-height: 50px;
    padding: 0 30px 0 0;
    font-weight: bold;
  }

  &__close {
    width: 25px;
    height: 25px;
    position: relative;
    cursor: pointer;
    margin: 0 0 0 15px;
    border: 2px solid #1C1C1C;
    border-radius: 6px;

    &:before {
      position: absolute;
      top: 1px;
      left: 5px;
      display: block;
      content: '';
      width: 12px;
      height: 12px;
      content: '✖';
    }
  }

  &__body {
    padding: 10px 20px;
  }
}

.transfer-modal-dialog {
  background: #fff;
  border-radius: 5px;

  &__header {
    display: flex;
  }

  &__body {
    display: flex;
  }
}

.transfer-modal-transition-1 {

  &-enter {
    //Начало анимации появления

    &.transfer-modal {
      opacity: 0;
    }

    & .modal__box {
      opacity: 0;
      transform: scale(.1, .1)
    }
  }

  &-enter-active {
    //Анимация появления идет

    &.transfer-modal {
      transition: .3s ease;
    }

    & .modal__box {
      transition: .3s ease;
    }
  }

  &-enter-to {
    //Анимация появления закончилась
  }

  &-leave {
    //Начало анимации исчезновения
  }

  &-leave-active {
    //Анимация исчезнованеия идет
  }

  &-leave-to {
    //Анимация исчзноваения закончилась
  }

}

.transfer-modal-deal {

  .modal__main {
    height: 100%;
  }

  .modal__box {
    max-width: 820px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__col-1 {
    flex: 0 0 270px;
    border-right: 1px solid #e5e5e5;
  }

  &__col-2 {
    flex: 1 0;
  }

  &__col-1, &__col-2 {
    display: flex;
    flex-direction: column;
  }

  &__head {
    height: 60px;
    flex: 0 0 60px;
    padding: 0px 20px;
    display: flex;
  }

  &__body {
    flex: 1 0 auto;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__name {
    font-size: 18px;
    line-height: 60px;
    color: #5b5b5b;
    pointer-events: auto;
    flex: 1 0 auto;

    & span:nth-child(1) {
      font-weight: 400;
    }

    & span:nth-child(2) {
      font-weight: 300;
    }
  }

  &__tab-item {
    background: #e5e5e5;
    border-radius: 50%;
    margin: 15px 0 0 10px;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    transition: .2s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 16px;
      height: 16px;
    }

    &:hover, &.--active {
      background: $color-main-purple;
    }
  }

  &__communication {
    //margin: 15px 0 0 15px;

    &:before {
      background: url('assets/img/communication.svg') no-repeat 50%;
    }
  }
  &__profile:before {
    background: url('assets/img/profile.svg') no-repeat 50%;
  }

  &__tasks:before {
    background: url('assets/img/tasks.svg') no-repeat 50%;
  }

  &__close {
    width: 16px;
    height: 60px;
    position: relative;
    cursor: pointer;
    margin: 0 0 0 15px;

    &:before {
      content: '';
      dipslay: block;
      position: absolute;
      top: 22px;
      width: 16px;
      height: 16px;
      background: url('assets/img/close.svg');
      background-size: cover;
    }

    &:hover:before {
      background-position: 16px 0;
    }
  }
}

.transfer-modal-client-contact {

  &__delete-phone {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 0 0 0 15px;
    width: 20px;
    height: 20px;

    &:before {
      position: absolute;
      top: 5px;
      right: 5px;
      display: block;
      content: '';
      width: 10px;
      height: 10px;
      background: url('assets/close-grey.svg') no-repeat center;
    }
  }

  &__btn-add-phone {
    width: 100% !important;
    background: #e8e8e8 !important;
    text-align: center;
    color: #787a7d !important;
    border: 1px solid #e8e8e8 !important;

    &:hover {
      background: #fff !important;
      color: #787a7d !important;
    }
  }
}

.transfer-modal-box-1__buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;

  div {
    cursor: pointer;
    font-weight: bold;
  }

  .transferBtn {
    border: none;
    background-color: #B9439D;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
}
}

@media (max-width: 820px) {
  .transfer-modal-deal__col-1 {
    display: none;
  }
  .transfer-modal-deal__tab-item {
    margin: 15px 0 0 5px;
  }
  .transfer-modal-deal__close {
    margin: 0 0 0 10px;
  }
  .transfer-modal {
    padding: 10px;
  }
}

@media screen and (max-width: 524px) {
  .transfer-modal-deal__tab-name{
    display: none !important;
  }
  
}