.custom-radio-1 {

	& input[type="radio"]:not(:checked),
	& input[type="radio"]:checked {
		position: absolute;
		left: -9999px;
	}
	& input[type="radio"]:not(:checked) + label,
	& input[type="radio"]:checked + label {
		position: relative;
		padding-left: 1.5em;
		cursor: pointer;
		margin: 0;
		line-height: 1.2;
	}

	/* radio aspect */
	& input[type="radio"]:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0; top: 50%;
		transform: translateY(-50%);
		width: 16px;
		height: 16px;
		border: 1px solid #dbdbdb;
		background: #fff;
		border-radius: 50%;

		//box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
	}

	& input[type="radio"]:checked + label:before {
		content: '';
		position: absolute;
		left: 0; top: 50%;
		transform: translateY(-50%);
		width: 16px;
		height: 16px;
		background: #fff;
		border-radius: 50%;
		border: 1px solid $color-main-purple;
	}

	/* checked mark aspect */
	& input[type="radio"]:not(:checked) + label:after,
	& input[type="radio"]:checked + label:after {
		content: '';
		position: absolute;
		top: calc(50% - 5px);
		transform-origin: center;
		left: 3px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: $color-main-purple;
		transition: .2s;
	}
	/* checked mark aspect changes */
	& input[type="radio"]:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
	& input[type="radio"]:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}
	/* disabled radio */
	& input[type="radio"]:disabled:not(:checked) + label:before,
	& input[type="radio"]:disabled:checked + label:before {
		box-shadow: none;
		border: 1px solid #dbdbdb;
		background: #f1f1f1;
		cursor: not-allowed;
	}
	& input[type="radio"]:disabled:checked + label:after {
		background: #c79cc4;
	}
	& input[type="radio"]:disabled + label {
		//color: #aaa;
	}
	/* accessibility */
	& input[type="radio"]:checked:focus + label:before,
	& input[type="radio"]:not(:checked):focus + label:before {
		border: 1px solid $color-main-purple;
	}

	/* hover style just for information */
	label:hover:before {
		border: 1px solid $color-main-purple;
	}
}