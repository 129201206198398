<template>
  <div
    class="switch"
    :class='{ "switch-on": value}'
    @click="toggleSwitch"
  >
    <div
      class="switch-button"
      :class="{ 'switch-on': value }"
    />
  </div>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		toggleSwitch () {
			this.$emit('change', !this.value);
		},
	},
};
</script>

<style scoped>
.switch {
    width: 34px;
    height: 16px;
    background-color: #ccc;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &.switch-on {
        background: #10B981;
    }
}

.switch-button {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;

    &.switch-on {
        transform: translateX(14px);
    }
}


</style>
