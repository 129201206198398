
.time-picker-container {
    width: 100%;

    span {
        display: block;
    }

    position: relative;

    .wrapper {
        display: flex;
        max-width: 260px;
        border: 1px solid #A6A6A6;
        border-radius: 8px;
        background: white;

        &.disabled {
            background: #C7C7C7;

            .input-wrapper {
                border: none;
            }
        }
    }

    .input-wrapper {
        position: relative;
        max-width: 130px;
        flex: 1;

        &:first-child {
            border-right: 1px solid #A6A6A6;
        }

        &:first-child {
            &:before {
                content: 'Начало';
                position: absolute;
                top: 0;
                left: 12px;
                font-size: 10px;
                color: #A6A6A6;
            }
        }

        &:last-child {
            &:before {
                content: 'Окончание';
                position: absolute;
                top: 0;
                left: 12px;
                font-size: 10px;
                color: #A6A6A6;
            }
        }
    }

    .time-picker-input {
        background: none;
        border: none;
        padding: 18px 12px 2px;
        font-size: 14px;
        line-height: 20px;
    }

}

