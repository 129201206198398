
.time-picker-container {
    span {
        display: block;
    }

    position: relative;

    .time-picker-input {
        width: 100%;
        padding: 4px 22px 4px 8px;
        border: 1px solid #A6A6A6;
        border-radius: 4px;
        &:disabled{
            background: #C7C7C7;
            border-color: #C7C7C7;
            cursor: not-allowed;
            color: #A6A6A6;
        }
    }

    .icon {
        position: absolute;
        top: 45%;
        right: 8px;
        cursor: pointer;
        width: 0;
        height: 0;
        display: block;
        border-left: 6px solid transparent;
        border-top: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #A6A6A6;
        transform: rotate(180deg);
    }
}

