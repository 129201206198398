@import "./../variables";

.plyr__control--overlaid {
	background: $color-main-purple !important;
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
	background: $color-main-purple !important;
}

.plyr--full-ui input[type=range] {
	color: $color-main-purple !important;
}