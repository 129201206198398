
.day {
    display: flex;
    padding: 10px 0px;
    align-items: center;


}

.day-name {
    align-self: center;
    flex-basis: 194px;
    max-width: 194px;
    font-weight: 400;
    font-size: 12px;
    margin-right: 20px;
}

.field {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 10px;
}

.date {
    align-items: center;
    display: flex;
    column-gap: 6px;
}


.isWork {
    margin: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    max-width: 78px;
    column-gap: 10px;
    cursor: pointer;
}

.isWork + .date {
    margin-left: 100px;
}

.date {
    margin-left: 198px;
}

.title {
    display: flex;
}

.date-schedule {
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
    margin-right: 20px;
}

.cross {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 170px;
    cursor: pointer;

    &::after,
    &::before {
        content: '';
        width: 10px;
        height: 2px;
        position: absolute;
        background: gray;
        top: 50%;
        left: 50%;;
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg)
    }

;

    &::before {
        transform: translate(-50%, -50%) rotate(-45deg)
    }
}


.row {
    display: flex;
    align-items: center;
    column-gap: 20px;
    position: relative;
    margin-right: 10px;
    & + & {
        margin-top: 10px;
    }
}

.input {
    width: 51px;
    height: 29px;
    padding: 10px;
    font-size: 12px;
    color: #3E3E3E;
}

.disable{
    color: gray;
}

.input:disabled {
    color: gray;
}
