.tasks {
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #fff;
	overflow-y: auto;

	&__list {
		flex: 1 0;
		overflow: hidden;
		background: #F0F0F0;
	}

	&__add {
		flex: 0 0 auto;
		border-top: 1px solid #e5e5e5;
		padding: 15px 20px;
		background: #fff;
	}

	&__wrapper {
		//width: calc(100% + 17px) !important;
		padding: 0 !important;
		overflow-x: hidden;
		overflow-y: hidden;
		// width: 100%;
		& .task:nth-child(1) {
			margin: 15px 20px 0;
		}
	}
}

.task {
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	margin: 0 20px 20px;
	padding: 7px 15px;
	position: relative;

	&.--red {
		background-color: #f8d7da;
		border-color: #ea919a;
	}

	&.--orange {
		background-color: #fff3cd;
		border-color: #f7c734;
	}

	&.--gray {
		background-color: #f5f5f5;
		border-color: #d6d8db;
	}

	&.--red:before {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: -1px;
		bottom: -1px;
		width: 3px;
		border-radius: 10px 0 0 10px;
		background-color: #ea919a;
		//background: #FF5151;
	}

	&.--orange:before {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: -1px;
		bottom: -1px;
		width: 3px;
		border-radius: 10px 0 0 10px;
		//background: #E8A04B;
		background-color: #f7c734;
	}

	&.--gray:before {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: -1px;
		bottom: -1px;
		width: 3px;
		border-radius: 10px 0 0 10px;
		//background: #57BF47;
		background: #d6d8db;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		row-gap: 5px;
		padding: 2px 0;
		display: flex;
		align-items: center;
	}

	&__name {
		flex: 1 0 auto;
		font-weight: 500;
		color: #5B5B5B;

		&:before {
			content: '';
			display: block;
			background: url('./assets/task-brown.svg') no-repeat center;
			width: 14px;
			height: 14px;
			margin: 3px 5px 0 0;
			float: left;
		}

		&.--orange {
			color: #E8A04B;
		}

		&.--green {
			color: #57BF47;
		}

		&.--red {
			color: #FF5151;
		}
	}

	&__date {
		color: #8F8F8F;
		font-weight: normal;
	}

	&__actions {
		width: 14px;
		height: 14px;
		flex: 14px 0;
		position: relative;
		cursor: pointer;
		margin: 0 0 0 10px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			width: 14px;
			height: 14px;
			background: url('./assets/more-grey.svg') no-repeat center;
		}
	}

	&__text {
		color: #5B5B5B;
		font-weight: normal;
		word-break: break-all;
	}

	&__deadline {
		flex: 1 1 auto;
		font-weight: 500;
		color: #5B5B5B;

		&:before {
			content: '';
			display: block;
			background: url('./assets/deadline-brown.svg') no-repeat center;
			width: 14px;
			height: 14px;
			margin: 3px 5px 0 0;
			float: left;
		}
	}

	&__button-1 {
		padding: 6px 15px !important;
	}

	&__button-2 {
		margin: 0 0 0 10px;
		display: inline-block;
		color: #fff;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		border-radius: 2px;
		padding: 6px 15px;
		font-size: 14px;
		line-height: 1;
		cursor: pointer;
		transition: .2s;
		background-color: transparent;
		border: 1px solid #d0d0d0;
		color: transparent;
		position: relative;

		&:before {
			content: '';
			display: block;
			background: url('./assets/pencil-grey.svg') no-repeat center;
			width: 12px;
			height: 12px;
			top: 7px;
			left: 10px;
			position: absolute;
		}

		&:hover:before {
			background: url('./assets/pencil-purple.svg') no-repeat center;
		}

		&:hover {
			border: 1px solid #A23A99;
		}
	}

	&__items {
		height: calc(100% - 52px);
	}
}
