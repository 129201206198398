<template>
  <div
    v-click-outside="() =>open = false"
    class="select-wrap"
  >
    <div
      v-if="label"
      class="select-label"
    >
      {{ label }}
    </div>
    <div
      v-if="groupnames.length === 0"
      class="custom-select"
      :tabindex="tabindex"
    >
      <div
        class="selected"
        :class="{ open: open, 'selected-bottom-position': position === 'bottom' && open}"
        @click="open = !open"
      >
        <input
          v-model="search"
          class="selected-input"
          :placeholder="placeholder"
          @input="isSearchableMode = true"
        >
        <div
          v-if="search.length > 0 && showCancelButton"
          class="cancel-search-btn"
          @click.stop="cleanSearchInput"
        >
          Отменить
        </div>
      </div>
      <scrolly
        class="items"
        :style='position === "bottom" ? "bottom: 40px;" : "top: 40px;" + `height: ${maxHeight}px`'
        :class="{ selectHide: !open || filteredOptions.length === 0}"
      >
        <scrolly-viewport ref="viewport">
          <div
            v-for="(option) of filteredOptions"
            :key="option.id"
            ref="content"
            style="padding: 0 12px;"
            :style='option.description ? "padding-top: 12px; padding-bottom: 12px": ""'
            class="item"
            :class='{"active": option.id === selectedValue}'
            @click="selectOption(option)"
          >
            <div
              style="word-wrap: break-word; display: flex; align-items: center; column-gap: 8px"
              :style='option.description ? "line-height: 16px": ""'
            >
              <img
                v-if="option.iconPath"
                :src="option.iconPath"
              >
              {{ option.name }}
            </div>
            <div
              v-if="option.description"
              class="description"
            >
              {{ option.description }}
            </div>
          </div>
        </scrolly-viewport>
        <scrolly-bar
          axis="y"
        />
      </scrolly>
    </div>

    <div
      v-else
      class="custom-select"
      :tabindex="tabindex"
      @blur="open = false"
    >
      <div
        class="selected"
        :class="{ open: open }"
        @click="open = !open"
      >
        <input
          v-model="search"
          class="selected-input"
          :placeholder="placeholder"
        >
        <div
          v-if="search.length > 0"
          class="cancel-search-btn"
          @click.stop="cleanSearchInput"
        >
          Отменить
        </div>
      </div>
      <scrolly
        class="items"
        :class="{ selectHide: !open}"
        :style="{ height: `${maxHeight}px` }"
      >
        <scrolly-viewport ref="viewport">
          <div
            v-for="(groupOptions, groupName) of filteredGroupOptions"
            :key="groupName"
            ref="content"
            style="padding: 0 12px;"
          >
            <div class="group-header">
              {{ groupName }}
            </div>
            <div
              v-for="(option) of groupOptions"
              :key="option.id"
              :class="[
                'item',
                option.status === 'disabled' ? 'item-disabled' : ''
              ]"
              @click="option.status === 'active' && selectOption(option)"
            >
              {{ option.name }}
            </div>
          </div>
        </scrolly-viewport>
        <scrolly-bar
          axis="y"
        />
      </scrolly>
    </div>
  </div>
</template>


<script>
import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly';

export default {
	components: {
		'scrolly': Scrolly,
		'scrolly-viewport': ScrollyViewport,
		'scrolly-bar': ScrollyBar,
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		defaultValue: {
			type: Object,
			required: false,
			default: null,
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0,
		},
		placeholder: {
			type: String,
			required: false,
		},
		groupnames: {
			type: Array,
			required: false,
			default: () => [],
		},
		showCancelButton: {
			type: Boolean,
			required: false,
			default: true,
		},
		label: {
			type: String,
			required: false,
			default: '',
		},
		position: {
			type: String,
			required: false,
			default: 'top',
		},
	},
	data () {
		return {
			open: false,
			search: '',
			maxHeight: 0,
			selectedValue: 0,
			isSearchableMode: false,
		};
	},
	computed: {
		filteredOptions () {
			const searchTerm = this.search.toLowerCase();
			return this.options.filter(option => {
				if (this.isSearchableMode) {
					return option.name.toLowerCase().includes(searchTerm);
				}
				return true;
			});
		},
		filteredGroupOptions () {
			const searchTerm = this.search.toLowerCase();
			const groups = {};

			groups[this.groupnames[0]] = this.options.filter(
				(option) => option.name.toLowerCase().includes(searchTerm) && option.status === 'active',
			);
			groups[this.groupnames[1]] = this.options.filter(
				(option) => option.name.toLowerCase().includes(searchTerm) && option.status === 'disabled',
			);

			return groups;
		},
	},
	watch: {
		open (newVal) {
			if (newVal) {
				this.calculateMaxHeight();
			} else {
				this.maxHeight = 0;
				this.isSearchableMode = false;
				if (this.selectedValue) {
					this.search = this.options.find(option => option.id === this.selectedValue).name || '';
				}
			}
		},
		search () {
			this.calculateMaxHeight();
		},
		defaultValue: {
			handler (newVal, oldValue) {
				if(!oldValue && newVal) {
					this.selectOption(newVal);
				}
			},
		},
	},
	mounted () {
		console.log('mounted');
		this.$emit('input', this.selected);
		this.defaultValue && this.selectOption(this.defaultValue);
	},
	methods: {
		selectOption (option) {
			this.open = false;
			this.selectedValue = option.id;
			this.search = option.name;
			this.$emit('input', option);
		},
		cleanSearchInput () {
			this.search = '';
			this.open = false;
			this.$emit('clear-input', null);
		},
		calculateMaxHeight () {
			if (this.$refs.content) {
				this.$nextTick(() => {
					let clientHeight = 0;
					this.$refs.content.forEach((div) => {
						clientHeight += div.clientHeight;
					});

					this.maxHeight = clientHeight > 400 ? 400 : clientHeight + 10;
				});
			}
		},
	},
};
</script>
<style scoped>
.select-wrap {
    position: relative;
    margin-bottom: 10px;
}

.select-label {
    position: absolute;
    top: 0;
    left: 14px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    z-index: 1;
    color: #A6A6A6;
}

.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 40px;
}

.selected {
    position: relative;
    display: inline-block;
    width: 100%;
}

.selected:after {
    content: "";
    position: absolute;
    cursor: pointer;
    top: 17px;
    right: 15px;
    width: 0;
    height: 0;
    display: block;
    border-left: 6px solid transparent;
    border-top: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #A6A6A6;
    transform: rotate(180deg);
}

.selected-input {
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #A6A6A6;
    color: #1C1C1C;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 40px;

    position: relative;
}

.selected-input::placeholder {
    color: #1C1C1C;
}

.selected-input.open {
    border: 1px solid #A6A6A6;
    border-radius: 6px;
}

.cancel-search-btn {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 80px;
    color: #B9439D;
    font-weight: 500;
    z-index: 100;
}

.items {
    color: #1C1C1C;
    border-radius: 6px;
    overflow-y: auto;
    border-right: 1px solid #A6A6A6;
    border-left: 1px solid #A6A6A6;
    border-bottom: 1px solid #A6A6A6;
    position: absolute;
    background-color: #FFFFFF;
    overflow: hidden;
    left: 0;
    right: 0;
    z-index: 100;
}

.group-header {
    color: #A6A6A6;
    font-size: 12px;
    font-weight: 500;
}

.item {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.item:hover {
    background-color: #A6A6A6;
}

.active {
    background: #868383;
}

.item-disabled {
    color: #A6A6A6;
    cursor: not-allowed;
}

.item-disabled:hover {
    background-color: transparent;
}

.selectHide {
    display: none;
}

.description {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #9D9EAF;
}
</style>
  
