
a {
    text-decoration: none;
    color: inherit;
}

.header-dropdown {
    position: relative;
    user-select: none;
    width: 100%;

    &__trigger {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        color: #fff;
        cursor: pointer;
        border-radius: 3px;
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: -10px;
        width: 197px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        border: 1px solid #A6A6A6;
        z-index: 100;
        margin-top: 14px;
        padding: 16px 12px 8px 12px;

        &::before {
            content: '';
            position: absolute;
            top: -15px;
            right: 50%;
            transform: translateX(50%);
            width: 18px;
            height: 15px;
            background: url('../../assets/v2/dropdown-top-arrow.svg') no-repeat center;
        }
    }

    &__option {
        cursor: pointer;
        margin-bottom: 4px;

        &:hover {
            opacity: 0.8;
        }
    }

    .arrow {
        position: relative;
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../assets/v2/dropdown-arrow.svg') no-repeat center;
            background-size: contain;
        }
    }

    .arrow--open {
        transform: rotate(180deg);
    }
}

@media (max-width: 1024px) {
    .header-dropdown {
        &__trigger {
            color: #1C1C1C;
            padding-left: 0;
            padding-right: 0;
        }

        &__dropdown {
            &::before {
                right: 55%;
                transform: translateX(50%);
            }
        }

        .arrow {
            &::before {
                background: url('../../assets/v2/dropdown-arrow-black.svg') no-repeat center;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
