.emoji {
	width: 28px;
	height: 28px;
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: top;
	zoom: 1;
	*display: inline;
	text-align: center;
	margin: 3px;
	overflow: hidden;
	text-indent: -999em;
	cursor: pointer;
	position: relative;
	border-radius: 2px;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		top: 4px;
		left: 4px;
		background: url('assets/img/smiles.png') top left no-repeat;
	}

	&:hover {
		background-color: #e4e4e4;
	}
}

.emoji2600:before {
	background-position: -500px -120px
}

.emoji2601:before {
	background-position: -500px -140px
}

.emoji2614:before {
	background-position: -500px -200px
}

.emoji26c4:before {
	background-position: -520px -200px
}

.emoji26a1:before {
	background-position: -520px -100px
}

.emoji1f300:before {
	background-position: -20px -500px
}

.emoji1f301:before {
	background-position: -20px -520px
}

.emoji1f302:before {
	background-position: -20px -540px
}

.emoji1f303:before {
	background-position: -20px -560px
}

.emoji1f304:before {
	background-position: -20px -580px
}

.emoji1f305:before {
	background-position: -40px -0
}

.emoji1f306:before {
	background-position: -40px -20px
}

.emoji1f307:before {
	background-position: -40px -40px
}

.emoji1f308:before {
	background-position: -40px -60px
}

.emoji2744:before {
	background-position: -540px -80px
}

.emoji26c5:before {
	background-position: -520px -220px
}

.emoji1f309:before {
	background-position: -40px -80px
}

.emoji1f30a:before {
	background-position: -40px -100px
}

.emoji1f30b:before {
	background-position: -40px -120px
}

.emoji1f30c:before {
	background-position: -40px -140px
}

.emoji1f30f:before {
	background-position: -40px -200px
}

.emoji1f311:before {
	background-position: -40px -240px
}

.emoji1f314:before {
	background-position: -40px -300px
}

.emoji1f313:before {
	background-position: -40px -280px
}

.emoji1f319:before {
	background-position: -40px -400px
}

.emoji1f315:before {
	background-position: -40px -320px
}

.emoji1f31b:before {
	background-position: -40px -440px
}

.emoji1f31f:before {
	background-position: -40px -520px
}

.emoji1f320:before {
	background-position: -540px -180px
}

.emoji1f550:before {
	background-position: -360px -260px
}

.emoji1f551:before {
	background-position: -360px -280px
}

.emoji1f552:before {
	background-position: -360px -300px
}

.emoji1f553:before {
	background-position: -360px -320px
}

.emoji1f554:before {
	background-position: -360px -340px
}

.emoji1f555:before {
	background-position: -360px -360px
}

.emoji1f556:before {
	background-position: -360px -380px
}

.emoji1f557:before {
	background-position: -360px -400px
}

.emoji1f558:before {
	background-position: -360px -420px
}

.emoji1f559:before {
	background-position: -360px -440px
}

.emoji1f55a:before {
	background-position: -360px -460px
}

.emoji1f55b:before {
	background-position: -360px -480px
}

.emoji231a:before {
	background-position: -480px -380px
}

.emoji231b:before {
	background-position: -480px -400px
}

.emoji23f0:before {
	background-position: -480px -500px
}

.emoji23f3:before {
	background-position: -480px -520px
}

.emoji2648:before {
	background-position: -500px -280px
}

.emoji2649:before {
	background-position: -500px -300px
}

.emoji264a:before {
	background-position: -500px -320px
}

.emoji264b:before {
	background-position: -500px -340px
}

.emoji264c:before {
	background-position: -500px -360px
}

.emoji264d:before {
	background-position: -500px -380px
}

.emoji264e:before {
	background-position: -500px -400px
}

.emoji264f:before {
	background-position: -500px -420px
}

.emoji2650:before {
	background-position: -500px -440px
}

.emoji2651:before {
	background-position: -500px -460px
}

.emoji2652:before {
	background-position: -500px -480px
}

.emoji2653:before {
	background-position: -500px -500px
}

.emoji26ce:before {
	background-position: -520px -240px
}

.emoji1f340:before {
	background-position: -60px -240px
}

.emoji1f337:before {
	background-position: -60px -60px
}

.emoji1f331:before {
	background-position: -40px -560px
}

.emoji1f341:before {
	background-position: -60px -260px
}

.emoji1f338:before {
	background-position: -60px -80px
}

.emoji1f339:before {
	background-position: -60px -100px
}

.emoji1f342:before {
	background-position: -60px -280px
}

.emoji1f343:before {
	background-position: -60px -300px
}

.emoji1f33a:before {
	background-position: -60px -120px
}

.emoji1f33b:before {
	background-position: -60px -140px
}

.emoji1f334:before {
	background-position: -60px -20px
}

.emoji1f335:before {
	background-position: -60px -40px
}

.emoji1f33e:before {
	background-position: -60px -200px
}

.emoji1f33d:before {
	background-position: -60px -180px
}

.emoji1f344:before {
	background-position: -60px -320px
}

.emoji1f330:before {
	background-position: -40px -540px
}

.emoji1f33c:before {
	background-position: -60px -160px
}

.emoji1f33f:before {
	background-position: -60px -220px
}

.emoji1f352:before {
	background-position: -80px -0
}

.emoji1f34c:before {
	background-position: -60px -480px
}

.emoji1f34e:before {
	background-position: -60px -520px
}

.emoji1f34a:before {
	background-position: -60px -440px
}

.emoji1f353:before {
	background-position: -80px -20px
}

.emoji1f349:before {
	background-position: -60px -420px
}

.emoji1f345:before {
	background-position: -60px -340px
}

.emoji1f346:before {
	background-position: -60px -360px
}

.emoji1f348:before {
	background-position: -60px -400px
}

.emoji1f34d:before {
	background-position: -60px -500px
}

.emoji1f347:before {
	background-position: -60px -380px
}

.emoji1f351:before {
	background-position: -60px -580px
}

.emoji1f34f:before {
	background-position: -60px -540px
}

.emoji1f440:before {
	background-position: -200px -100px
}

.emoji1f442:before {
	background-position: -200px -120px
}

.emoji1f443:before {
	background-position: -200px -140px
}

.emoji1f444:before {
	background-position: -200px -160px
}

.emoji1f445:before {
	background-position: -200px -180px
}

.emoji1f484:before {
	background-position: -240px -240px
}

.emoji1f485:before {
	background-position: -240px -260px
}

.emoji1f486:before {
	background-position: -240px -280px
}

.emoji1f487:before {
	background-position: -240px -300px
}

.emoji1f488:before {
	background-position: -240px -320px
}

.emoji1f464:before {
	background-position: -220px -200px
}

.emoji1f466:before {
	background-position: -220px -240px
}

.emoji1f467:before {
	background-position: -220px -260px
}

.emoji1f468:before {
	background-position: -220px -280px
}

.emoji1f469:before {
	background-position: -220px -300px
}

.emoji1f46a:before {
	background-position: -220px -320px
}

.emoji1f46b:before {
	background-position: -220px -340px
}

.emoji1f46e:before {
	background-position: -220px -400px
}

.emoji1f46f:before {
	background-position: -220px -420px
}

.emoji1f470:before {
	background-position: -220px -440px
}

.emoji1f471:before {
	background-position: -220px -460px
}

.emoji1f472:before {
	background-position: -220px -480px
}

.emoji1f473:before {
	background-position: -220px -500px
}

.emoji1f474:before {
	background-position: -220px -520px
}

.emoji1f475:before {
	background-position: -220px -540px
}

.emoji1f476:before {
	background-position: -220px -560px
}

.emoji1f477:before {
	background-position: -220px -580px
}

.emoji1f478:before {
	background-position: -240px -0
}

.emoji1f479:before {
	background-position: -240px -20px
}

.emoji1f47a:before {
	background-position: -240px -40px
}

.emoji1f47b:before {
	background-position: -240px -60px
}

.emoji1f47c:before {
	background-position: -240px -80px
}

.emoji1f47d:before {
	background-position: -240px -100px
}

.emoji1f47e:before {
	background-position: -240px -120px
}

.emoji1f47f:before {
	background-position: -240px -140px
}

.emoji1f480:before {
	background-position: -240px -160px
}

.emoji1f481:before {
	background-position: -240px -180px
}

.emoji1f482:before {
	background-position: -240px -200px
}

.emoji1f483:before {
	background-position: -240px -220px
}

.emoji1f40c:before {
	background-position: -160px -280px
}

.emoji1f40d:before {
	background-position: -160px -300px
}

.emoji1f40e:before {
	background-position: -160px -320px
}

.emoji1f414:before {
	background-position: -160px -440px
}

.emoji1f417:before {
	background-position: -160px -500px
}

.emoji1f42b:before {
	background-position: -180px -300px
}

.emoji1f418:before {
	background-position: -160px -520px
}

.emoji1f428:before {
	background-position: -180px -240px
}

.emoji1f412:before {
	background-position: -160px -400px
}

.emoji1f411:before {
	background-position: -160px -380px
}

.emoji1f419:before {
	background-position: -160px -540px
}

.emoji1f41a:before {
	background-position: -160px -560px
}

.emoji1f41b:before {
	background-position: -160px -580px
}

.emoji1f41c:before {
	background-position: -180px -0
}

.emoji1f41d:before {
	background-position: -180px -20px
}

.emoji1f41e:before {
	background-position: -180px -40px
}

.emoji1f420:before {
	background-position: -180px -80px
}

.emoji1f421:before {
	background-position: -180px -100px
}

.emoji1f422:before {
	background-position: -180px -120px
}

.emoji1f424:before {
	background-position: -180px -160px
}

.emoji1f425:before {
	background-position: -180px -180px
}

.emoji1f426:before {
	background-position: -180px -200px
}

.emoji1f423:before {
	background-position: -180px -140px
}

.emoji1f427:before {
	background-position: -180px -220px
}

.emoji1f429:before {
	background-position: -180px -260px
}

.emoji1f41f:before {
	background-position: -180px -60px
}

.emoji1f42c:before {
	background-position: -180px -320px
}

.emoji1f42d:before {
	background-position: -180px -340px
}

.emoji1f42f:before {
	background-position: -180px -380px
}

.emoji1f431:before {
	background-position: -180px -420px
}

.emoji1f433:before {
	background-position: -180px -460px
}

.emoji1f434:before {
	background-position: -180px -480px
}

.emoji1f435:before {
	background-position: -180px -500px
}

.emoji1f436:before {
	background-position: -180px -520px
}

.emoji1f437:before {
	background-position: -180px -540px
}

.emoji1f43b:before {
	background-position: -200px -20px
}

.emoji1f439:before {
	background-position: -180px -580px
}

.emoji1f43a:before {
	background-position: -200px -0
}

.emoji1f42e:before {
	background-position: -180px -360px
}

.emoji1f430:before {
	background-position: -180px -400px
}

.emoji1f438:before {
	background-position: -180px -560px
}

.emoji1f43e:before {
	background-position: -200px -80px
}

.emoji1f432:before {
	background-position: -180px -440px
}

.emoji1f43c:before {
	background-position: -200px -40px
}

.emoji1f43d:before {
	background-position: -200px -60px
}

.emoji1f620:before {
	background-position: -400px -280px
}

.emoji1f629:before {
	background-position: -400px -460px
}

.emoji1f632:before {
	background-position: -420px -40px
}

.emoji1f61e:before {
	background-position: -400px -240px
}

.emoji1f635:before {
	background-position: -420px -100px
}

.emoji1f630:before {
	background-position: -420px -0
}

.emoji1f612:before {
	background-position: -400px -0
}

.emoji1f60d:before {
	background-position: -380px -500px
}

.emoji1f624:before {
	background-position: -400px -360px
}

.emoji1f61c:before {
	background-position: -400px -200px
}

.emoji1f61d:before {
	background-position: -400px -220px
}

.emoji1f60b:before {
	background-position: -380px -460px
}

.emoji1f618:before {
	background-position: -400px -120px
}

.emoji1f61a:before {
	background-position: -400px -160px
}

.emoji1f637:before {
	background-position: -420px -140px
}

.emoji1f633:before {
	background-position: -420px -60px
}

.emoji1f603:before {
	background-position: -380px -300px
}

.emoji1f605:before {
	background-position: -380px -340px
}

.emoji1f606:before {
	background-position: -380px -360px
}

.emoji1f601:before {
	background-position: -380px -260px
}

.emoji1f602:before {
	background-position: -380px -280px
}

.emoji1f60a:before {
	background-position: -380px -440px
}

.emoji263a:before {
	background-position: -500px -260px
}

.emoji1f604:before {
	background-position: -380px -320px
}

.emoji1f622:before {
	background-position: -400px -320px
}

.emoji1f62d:before {
	background-position: -400px -540px
}

.emoji1f628:before {
	background-position: -400px -440px
}

.emoji1f623:before {
	background-position: -400px -340px
}

.emoji1f621:before {
	background-position: -400px -300px
}

.emoji1f60c:before {
	background-position: -380px -480px
}

.emoji1f616:before {
	background-position: -400px -80px
}

.emoji1f614:before {
	background-position: -400px -40px
}

.emoji1f631:before {
	background-position: -420px -20px
}

.emoji1f62a:before {
	background-position: -400px -480px
}

.emoji1f60f:before {
	background-position: -380px -540px
}

.emoji1f613:before {
	background-position: -400px -20px
}

.emoji1f625:before {
	background-position: -400px -380px
}

.emoji1f62b:before {
	background-position: -400px -500px
}

.emoji1f609:before {
	background-position: -380px -420px
}

.emoji1f63a:before {
	background-position: -420px -200px
}

.emoji1f638:before {
	background-position: -420px -160px
}

.emoji1f639:before {
	background-position: -420px -180px
}

.emoji1f63d:before {
	background-position: -420px -260px
}

.emoji1f63b:before {
	background-position: -420px -220px
}

.emoji1f63f:before {
	background-position: -420px -300px
}

.emoji1f63e:before {
	background-position: -420px -280px
}

.emoji1f63c:before {
	background-position: -420px -240px
}

.emoji1f640:before {
	background-position: -420px -320px
}

.emoji1f645:before {
	background-position: -420px -340px
}

.emoji1f646:before {
	background-position: -420px -360px
}

.emoji1f647:before {
	background-position: -420px -380px
}

.emoji1f648:before {
	background-position: -420px -400px
}

.emoji1f64a:before {
	background-position: -420px -440px
}

.emoji1f649:before {
	background-position: -420px -420px
}

.emoji1f64b:before {
	background-position: -420px -460px
}

.emoji1f64c:before {
	background-position: -420px -480px
}

.emoji1f64d:before {
	background-position: -420px -500px
}

.emoji1f64e:before {
	background-position: -420px -520px
}

.emoji1f64f:before {
	background-position: -420px -540px
}

.emoji1f3e0:before {
	background-position: -140px -300px
}

.emoji1f3e1:before {
	background-position: -140px -320px
}

.emoji1f3e2:before {
	background-position: -140px -340px
}

.emoji1f3e3:before {
	background-position: -140px -360px
}

.emoji1f3e5:before {
	background-position: -140px -400px
}

.emoji1f3e6:before {
	background-position: -140px -420px
}

.emoji1f3e7:before {
	background-position: -140px -440px
}

.emoji1f3e8:before {
	background-position: -140px -460px
}

.emoji1f3e9:before {
	background-position: -140px -480px
}

.emoji1f3ea:before {
	background-position: -140px -500px
}

.emoji1f3eb:before {
	background-position: -140px -520px
}

.emoji26ea:before {
	background-position: -520px -280px
}

.emoji26f2:before {
	background-position: -520px -300px
}

.emoji1f3ec:before {
	background-position: -140px -540px
}

.emoji1f3ef:before {
	background-position: -160px -0
}

.emoji1f3f0:before {
	background-position: -160px -20px
}

.emoji1f3ed:before {
	background-position: -140px -560px
}

.emoji2693:before {
	background-position: -520px -60px
}

.emoji1f3ee:before {
	background-position: -140px -580px
}

.emoji1f5fb:before {
	background-position: -380px -140px
}

.emoji1f5fc:before {
	background-position: -380px -160px
}

.emoji1f5fd:before {
	background-position: -380px -180px
}

.emoji1f5fe:before {
	background-position: -380px -200px
}

.emoji1f5ff:before {
	background-position: -380px -220px
}

.emoji1f45e:before {
	background-position: -220px -80px
}

.emoji1f45f:before {
	background-position: -220px -100px
}

.emoji1f460:before {
	background-position: -220px -120px
}

.emoji1f461:before {
	background-position: -220px -140px
}

.emoji1f462:before {
	background-position: -220px -160px
}

.emoji1f463:before {
	background-position: -220px -180px
}

.emoji1f453:before {
	background-position: -200px -460px
}

.emoji1f455:before {
	background-position: -200px -500px
}

.emoji1f456:before {
	background-position: -200px -520px
}

.emoji1f451:before {
	background-position: -200px -420px
}

.emoji1f454:before {
	background-position: -200px -480px
}

.emoji1f452:before {
	background-position: -200px -440px
}

.emoji1f457:before {
	background-position: -200px -540px
}

.emoji1f458:before {
	background-position: -200px -560px
}

.emoji1f459:before {
	background-position: -200px -580px
}

.emoji1f45a:before {
	background-position: -220px -0
}

.emoji1f45b:before {
	background-position: -220px -20px
}

.emoji1f45c:before {
	background-position: -220px -40px
}

.emoji1f45d:before {
	background-position: -220px -60px
}

.emoji1f4b0:before {
	background-position: -260px -520px
}

.emoji1f4b1:before {
	background-position: -260px -540px
}

.emoji1f4b9:before {
	background-position: -280px -100px
}

.emoji1f4b2:before {
	background-position: -260px -560px
}

.emoji1f4b3:before {
	background-position: -260px -580px
}

.emoji1f4b4:before {
	background-position: -280px -0
}

.emoji1f4b5:before {
	background-position: -280px -20px
}

.emoji1f4b8:before {
	background-position: -280px -80px
}

.emoji1f1e81f1f3:before {
	background-position: -20px -0
}

.emoji1f1e91f1ea:before {
	background-position: -20px -20px
}

.emoji1f1ea1f1f8:before {
	background-position: -20px -40px
}

.emoji1f1eb1f1f7:before {
	background-position: -20px -60px
}

.emoji1f1ec1f1e7:before {
	background-position: -20px -80px
}

.emoji1f1ee1f1f9:before {
	background-position: -20px -100px
}

.emoji1f1ef1f1f5:before {
	background-position: -20px -120px
}

.emoji1f1f01f1f7:before {
	background-position: -20px -140px
}

.emoji1f1f71f1fa:before {
	background-position: -20px -160px
}

.emoji1f1fa1f1f8:before {
	background-position: -20px -180px
}

.emoji1f525:before {
	background-position: -340px -360px
}

.emoji1f526:before {
	background-position: -340px -380px
}

.emoji1f527:before {
	background-position: -340px -400px
}

.emoji1f528:before {
	background-position: -340px -420px
}

.emoji1f529:before {
	background-position: -340px -440px
}

.emoji1f52a:before {
	background-position: -340px -460px
}

.emoji1f52b:before {
	background-position: -340px -480px
}

.emoji1f52e:before {
	background-position: -340px -540px
}

.emoji1f52f:before {
	background-position: -340px -560px
}

.emoji1f530:before {
	background-position: -340px -580px
}

.emoji1f531:before {
	background-position: -360px -0
}

.emoji1f489:before {
	background-position: -240px -340px
}

.emoji1f48a:before {
	background-position: -240px -360px
}

.emoji1f170:before {
	background-position: -0 -300px
}

.emoji1f171:before {
	background-position: -0 -320px
}

.emoji1f18e:before {
	background-position: -0 -380px
}

.emoji1f17e:before {
	background-position: -0 -340px
}

.emoji1f380:before {
	background-position: -100px -260px
}

.emoji1f381:before {
	background-position: -100px -280px
}

.emoji1f382:before {
	background-position: -100px -300px
}

.emoji1f384:before {
	background-position: -100px -340px
}

.emoji1f385:before {
	background-position: -100px -360px
}

.emoji1f38c:before {
	background-position: -100px -500px
}

.emoji1f386:before {
	background-position: -100px -380px
}

.emoji1f388:before {
	background-position: -100px -420px
}

.emoji1f389:before {
	background-position: -100px -440px
}

.emoji1f38d:before {
	background-position: -100px -520px
}

.emoji1f38e:before {
	background-position: -100px -540px
}

.emoji1f393:before {
	background-position: -120px -40px
}

.emoji1f392:before {
	background-position: -120px -20px
}

.emoji1f38f:before {
	background-position: -100px -560px
}

.emoji1f387:before {
	background-position: -100px -400px
}

.emoji1f390:before {
	background-position: -100px -580px
}

.emoji1f383:before {
	background-position: -100px -320px
}

.emoji1f38a:before {
	background-position: -100px -460px
}

.emoji1f38b:before {
	background-position: -100px -480px
}

.emoji1f391:before {
	background-position: -120px -0
}

.emoji1f4df:before {
	background-position: -300px -260px
}

.emoji260e:before {
	background-position: -500px -160px
}

.emoji1f4de:before {
	background-position: -300px -240px
}

.emoji1f4f1:before {
	background-position: -320px -20px
}

.emoji1f4f2:before {
	background-position: -320px -40px
}

.emoji1f4dd:before {
	background-position: -300px -220px
}

.emoji1f4e0:before {
	background-position: -300px -280px
}

.emoji2709:before {
	background-position: -520px -460px
}

.emoji1f4e8:before {
	background-position: -300px -440px
}

.emoji1f4e9:before {
	background-position: -300px -460px
}

.emoji1f4ea:before {
	background-position: -300px -480px
}

.emoji1f4eb:before {
	background-position: -300px -500px
}

.emoji1f4ee:before {
	background-position: -300px -560px
}

.emoji1f4f0:before {
	background-position: -320px -0
}

.emoji1f4e2:before {
	background-position: -300px -320px
}

.emoji1f4e3:before {
	background-position: -300px -340px
}

.emoji1f4e1:before {
	background-position: -300px -300px
}

.emoji1f4e4:before {
	background-position: -300px -360px
}

.emoji1f4e5:before {
	background-position: -300px -380px
}

.emoji1f4e6:before {
	background-position: -300px -400px
}

.emoji1f4e7:before {
	background-position: -300px -420px
}

.emoji1f520:before {
	background-position: -340px -260px
}

.emoji1f521:before {
	background-position: -340px -280px
}

.emoji1f522:before {
	background-position: -340px -300px
}

.emoji1f523:before {
	background-position: -340px -320px
}

.emoji1f524:before {
	background-position: -340px -340px
}

.emoji2712:before {
	background-position: -520px -560px
}

.emoji1f4ba:before {
	background-position: -280px -120px
}

.emoji1f4bb:before {
	background-position: -280px -140px
}

.emoji270f:before {
	background-position: -520px -540px
}

.emoji1f4ce:before {
	background-position: -280px -520px
}

.emoji1f4bc:before {
	background-position: -280px -160px
}

.emoji1f4bd:before {
	background-position: -280px -180px
}

.emoji1f4be:before {
	background-position: -280px -200px
}

.emoji1f4bf:before {
	background-position: -280px -220px
}

.emoji1f4c0:before {
	background-position: -280px -240px
}

.emoji2702:before {
	background-position: -520px -400px
}

.emoji1f4cd:before {
	background-position: -280px -500px
}

.emoji1f4c3:before {
	background-position: -280px -300px
}

.emoji1f4c4:before {
	background-position: -280px -320px
}

.emoji1f4c5:before {
	background-position: -280px -340px
}

.emoji1f4c1:before {
	background-position: -280px -260px
}

.emoji1f4c2:before {
	background-position: -280px -280px
}

.emoji1f4d3:before {
	background-position: -300px -20px
}

.emoji1f4d6:before {
	background-position: -300px -80px
}

.emoji1f4d4:before {
	background-position: -300px -40px
}

.emoji1f4d5:before {
	background-position: -300px -60px
}

.emoji1f4d7:before {
	background-position: -300px -100px
}

.emoji1f4d8:before {
	background-position: -300px -120px
}

.emoji1f4d9:before {
	background-position: -300px -140px
}

.emoji1f4da:before {
	background-position: -300px -160px
}

.emoji1f4db:before {
	background-position: -300px -180px
}

.emoji1f4dc:before {
	background-position: -300px -200px
}

.emoji1f4cb:before {
	background-position: -280px -460px
}

.emoji1f4c6:before {
	background-position: -280px -360px
}

.emoji1f4ca:before {
	background-position: -280px -440px
}

.emoji1f4c8:before {
	background-position: -280px -400px
}

.emoji1f4c9:before {
	background-position: -280px -420px
}

.emoji1f4c7:before {
	background-position: -280px -380px
}

.emoji1f4cc:before {
	background-position: -280px -480px
}

.emoji1f4d2:before {
	background-position: -300px -0
}

.emoji1f4cf:before {
	background-position: -280px -540px
}

.emoji1f4d0:before {
	background-position: -280px -560px
}

.emoji1f4d1:before {
	background-position: -280px -580px
}

.emoji1f3bd:before {
	background-position: -140px -40px
}

.emoji26be:before {
	background-position: -520px -180px
}

.emoji26f3:before {
	background-position: -520px -320px
}

.emoji1f3be:before {
	background-position: -140px -60px
}

.emoji26bd:before {
	background-position: -520px -160px
}

.emoji1f3bf:before {
	background-position: -140px -80px
}

.emoji1f3c0:before {
	background-position: -140px -100px
}

.emoji1f3c1:before {
	background-position: -140px -120px
}

.emoji1f3c2:before {
	background-position: -140px -140px
}

.emoji1f3c3:before {
	background-position: -140px -160px
}

.emoji1f3c4:before {
	background-position: -140px -180px
}

.emoji1f3c6:before {
	background-position: -140px -200px
}

.emoji1f3c8:before {
	background-position: -140px -240px
}

.emoji1f3ca:before {
	background-position: -140px -280px
}

.emoji1f683:before {
	background-position: -440px -20px
}

.emoji1f687:before {
	background-position: -440px -100px
}

.emoji24c2:before {
	background-position: -480px -540px
}

.emoji1f684:before {
	background-position: -440px -40px
}

.emoji1f685:before {
	background-position: -440px -60px
}

.emoji1f697:before {
	background-position: -440px -400px
}

.emoji1f699:before {
	background-position: -440px -440px
}

.emoji1f68c:before {
	background-position: -440px -180px
}

.emoji1f68f:before {
	background-position: -440px -240px
}

.emoji1f6a2:before {
	background-position: -460px -20px
}

.emoji2708:before {
	background-position: -520px -440px
}

.emoji26f5:before {
	background-position: -520px -340px
}

.emoji1f689:before {
	background-position: -440px -140px
}

.emoji1f680:before {
	background-position: -420px -560px
}

.emoji1f6a4:before {
	background-position: -460px -60px
}

.emoji1f695:before {
	background-position: -440px -360px
}

.emoji1f69a:before {
	background-position: -440px -460px
}

.emoji1f692:before {
	background-position: -440px -300px
}

.emoji1f691:before {
	background-position: -440px -280px
}

.emoji1f693:before {
	background-position: -440px -320px
}

.emoji26fd:before {
	background-position: -520px -380px
}

.emoji1f17f:before {
	background-position: -0 -360px
}

.emoji1f6a5:before {
	background-position: -460px -80px
}

.emoji1f6a7:before {
	background-position: -460px -120px
}

.emoji1f6a8:before {
	background-position: -460px -140px
}

.emoji2668:before {
	background-position: -520px -0
}

.emoji26fa:before {
	background-position: -520px -360px
}

.emoji1f3a0:before {
	background-position: -120px -60px
}

.emoji1f3a1:before {
	background-position: -120px -80px
}

.emoji1f3a2:before {
	background-position: -120px -100px
}

.emoji1f3a3:before {
	background-position: -120px -120px
}

.emoji1f3a4:before {
	background-position: -120px -140px
}

.emoji1f3a5:before {
	background-position: -120px -160px
}

.emoji1f3a6:before {
	background-position: -120px -180px
}

.emoji1f3a7:before {
	background-position: -120px -200px
}

.emoji1f3a8:before {
	background-position: -120px -220px
}

.emoji1f3a9:before {
	background-position: -120px -240px
}

.emoji1f3aa:before {
	background-position: -120px -260px
}

.emoji1f3ab:before {
	background-position: -120px -280px
}

.emoji1f3ac:before {
	background-position: -120px -300px
}

.emoji1f3ad:before {
	background-position: -120px -320px
}

.emoji1f3ae:before {
	background-position: -120px -340px
}

.emoji1f004:before {
	background-position: -0 -260px
}

.emoji1f3af:before {
	background-position: -120px -360px
}

.emoji1f3b0:before {
	background-position: -120px -380px
}

.emoji1f3b1:before {
	background-position: -120px -400px
}

.emoji1f3b2:before {
	background-position: -120px -420px
}

.emoji1f3b3:before {
	background-position: -120px -440px
}

.emoji1f3b4:before {
	background-position: -120px -460px
}

.emoji1f0cf:before {
	background-position: -0 -280px
}

.emoji1f3b5:before {
	background-position: -120px -480px
}

.emoji1f3b6:before {
	background-position: -120px -500px
}

.emoji1f3b7:before {
	background-position: -120px -520px
}

.emoji1f3b8:before {
	background-position: -120px -540px
}

.emoji1f3b9:before {
	background-position: -120px -560px
}

.emoji1f3ba:before {
	background-position: -120px -580px
}

.emoji1f3bb:before {
	background-position: -140px -0
}

.emoji1f3bc:before {
	background-position: -140px -20px
}

.emoji303d:before {
	background-position: -540px -580px
}

.emoji1f4f7:before {
	background-position: -320px -140px
}

.emoji1f4f9:before {
	background-position: -320px -160px
}

.emoji1f4fa:before {
	background-position: -320px -180px
}

.emoji1f4fb:before {
	background-position: -320px -200px
}

.emoji1f4fc:before {
	background-position: -320px -220px
}

.emoji1f48b:before {
	background-position: -240px -380px
}

.emoji1f48c:before {
	background-position: -240px -400px
}

.emoji1f48d:before {
	background-position: -240px -420px
}

.emoji1f48e:before {
	background-position: -240px -440px
}

.emoji1f48f:before {
	background-position: -240px -460px
}

.emoji1f490:before {
	background-position: -240px -480px
}

.emoji1f491:before {
	background-position: -240px -500px
}

.emoji1f492:before {
	background-position: -240px -520px
}

.emoji1f51e:before {
	background-position: -340px -220px
}

.emojia9:before {
	background-position: -0 -220px
}

.emojiae:before {
	background-position: -0 -240px
}

.emoji2122:before {
	background-position: -480px -180px
}

.emoji2139:before {
	background-position: -480px -200px
}

.emoji2320e3:before {
	background-position: -0 -0
}

.emoji3120e3:before {
	background-position: -0 -40px
}

.emoji3220e3:before {
	background-position: -0 -60px
}

.emoji3320e3:before {
	background-position: -0 -80px
}

.emoji3420e3:before {
	background-position: -0 -100px
}

.emoji3520e3:before {
	background-position: -0 -120px
}

.emoji3620e3:before {
	background-position: -0 -140px
}

.emoji3720e3:before {
	background-position: -0 -160px
}

.emoji3820e3:before {
	background-position: -0 -180px
}

.emoji3920e3:before {
	background-position: -0 -200px
}

.emoji3020e3:before {
	background-position: -0 -20px
}

.emoji1f51f:before {
	background-position: -340px -240px
}

.emoji1f4f6:before {
	background-position: -320px -120px
}

.emoji1f4f3:before {
	background-position: -320px -60px
}

.emoji1f4f4:before {
	background-position: -320px -80px
}

.emoji1f354:before {
	background-position: -80px -40px
}

.emoji1f359:before {
	background-position: -80px -140px
}

.emoji1f370:before {
	background-position: -100px -0
}

.emoji1f35c:before {
	background-position: -80px -200px
}

.emoji1f35e:before {
	background-position: -80px -240px
}

.emoji1f373:before {
	background-position: -100px -60px
}

.emoji1f366:before {
	background-position: -80px -400px
}

.emoji1f35f:before {
	background-position: -80px -260px
}

.emoji1f361:before {
	background-position: -80px -300px
}

.emoji1f358:before {
	background-position: -80px -120px
}

.emoji1f35a:before {
	background-position: -80px -160px
}

.emoji1f35d:before {
	background-position: -80px -220px
}

.emoji1f35b:before {
	background-position: -80px -180px
}

.emoji1f362:before {
	background-position: -80px -320px
}

.emoji1f363:before {
	background-position: -80px -340px
}

.emoji1f371:before {
	background-position: -100px -20px
}

.emoji1f372:before {
	background-position: -100px -40px
}

.emoji1f367:before {
	background-position: -80px -420px
}

.emoji1f356:before {
	background-position: -80px -80px
}

.emoji1f365:before {
	background-position: -80px -380px
}

.emoji1f360:before {
	background-position: -80px -280px
}

.emoji1f355:before {
	background-position: -80px -60px
}

.emoji1f357:before {
	background-position: -80px -100px
}

.emoji1f368:before {
	background-position: -80px -440px
}

.emoji1f369:before {
	background-position: -80px -460px
}

.emoji1f36a:before {
	background-position: -80px -480px
}

.emoji1f36b:before {
	background-position: -80px -500px
}

.emoji1f36c:before {
	background-position: -80px -520px
}

.emoji1f36d:before {
	background-position: -80px -540px
}

.emoji1f36e:before {
	background-position: -80px -560px
}

.emoji1f36f:before {
	background-position: -80px -580px
}

.emoji1f364:before {
	background-position: -80px -360px
}

.emoji1f374:before {
	background-position: -100px -80px
}

.emoji2615:before {
	background-position: -500px -220px
}

.emoji1f378:before {
	background-position: -100px -160px
}

.emoji1f37a:before {
	background-position: -100px -200px
}

.emoji1f375:before {
	background-position: -100px -100px
}

.emoji1f376:before {
	background-position: -100px -120px
}

.emoji1f377:before {
	background-position: -100px -140px
}

.emoji1f37b:before {
	background-position: -100px -220px
}

.emoji1f379:before {
	background-position: -100px -180px
}

.emoji2197:before {
	background-position: -480px -280px
}

.emoji2198:before {
	background-position: -480px -300px
}

.emoji2196:before {
	background-position: -480px -260px
}

.emoji2199:before {
	background-position: -480px -320px
}

.emoji2934:before {
	background-position: -540px -380px
}

.emoji2935:before {
	background-position: -540px -400px
}

.emoji2194:before {
	background-position: -480px -220px
}

.emoji2195:before {
	background-position: -480px -240px
}

.emoji2b06:before {
	background-position: -540px -440px
}

.emoji2b07:before {
	background-position: -540px -460px
}

.emoji27a1:before {
	background-position: -540px -320px
}

.emoji2b05:before {
	background-position: -540px -420px
}

.emoji25b6:before {
	background-position: -500px -0
}

.emoji25c0:before {
	background-position: -500px -20px
}

.emoji23e9:before {
	background-position: -480px -420px
}

.emoji23ea:before {
	background-position: -480px -440px
}

.emoji23eb:before {
	background-position: -480px -460px
}

.emoji23ec:before {
	background-position: -480px -480px
}

.emoji1f53a:before {
	background-position: -360px -180px
}

.emoji1f53b:before {
	background-position: -360px -200px
}

.emoji1f53c:before {
	background-position: -360px -220px
}

.emoji1f53d:before {
	background-position: -360px -240px
}

.emoji2b55:before {
	background-position: -540px -540px
}

.emoji274c:before {
	background-position: -540px -120px
}

.emoji274e:before {
	background-position: -540px -140px
}

.emoji2757:before {
	background-position: -540px -220px
}

.emoji2049:before {
	background-position: -480px -160px
}

.emoji203c:before {
	background-position: -480px -140px
}

.emoji2753:before {
	background-position: -540px -160px
}

.emoji2754:before {
	background-position: -540px -180px
}

.emoji2755:before {
	background-position: -540px -200px
}

.emoji3030:before {
	background-position: -540px -560px
}

.emoji27b0:before {
	background-position: -540px -340px
}

.emoji27bf:before {
	background-position: -540px -360px
}

.emoji2764:before {
	background-position: -540px -240px
}

.emoji1f493:before {
	background-position: -240px -540px
}

.emoji1f494:before {
	background-position: -240px -560px
}

.emoji1f495:before {
	background-position: -240px -580px
}

.emoji1f496:before {
	background-position: -260px -0
}

.emoji1f497:before {
	background-position: -260px -20px
}

.emoji1f498:before {
	background-position: -260px -40px
}

.emoji1f499:before {
	background-position: -260px -60px
}

.emoji1f49a:before {
	background-position: -260px -80px
}

.emoji1f49b:before {
	background-position: -260px -100px
}

.emoji1f49c:before {
	background-position: -260px -120px
}

.emoji1f49d:before {
	background-position: -260px -140px
}

.emoji1f49e:before {
	background-position: -260px -160px
}

.emoji1f49f:before {
	background-position: -260px -180px
}

.emoji2665:before {
	background-position: -500px -560px
}

.emoji2660:before {
	background-position: -500px -520px
}

.emoji2666:before {
	background-position: -500px -580px
}

.emoji2663:before {
	background-position: -500px -540px
}

.emoji1f6ac:before {
	background-position: -460px -220px
}

.emoji1f6ad:before {
	background-position: -460px -240px
}

.emoji267f:before {
	background-position: -520px -40px
}

.emoji1f6a9:before {
	background-position: -460px -160px
}

.emoji26a0:before {
	background-position: -520px -80px
}

.emoji26d4:before {
	background-position: -520px -260px
}

.emoji267b:before {
	background-position: -520px -20px
}

.emoji1f6b2:before {
	background-position: -460px -340px
}

.emoji1f6b6:before {
	background-position: -460px -420px
}

.emoji1f6b9:before {
	background-position: -460px -480px
}

.emoji1f6ba:before {
	background-position: -460px -500px
}

.emoji1f6c0:before {
	background-position: -480px -20px
}

.emoji1f6bb:before {
	background-position: -460px -520px
}

.emoji1f6bd:before {
	background-position: -460px -560px
}

.emoji1f6be:before {
	background-position: -460px -580px
}

.emoji1f6bc:before {
	background-position: -460px -540px
}

.emoji1f6aa:before {
	background-position: -460px -180px
}

.emoji1f6ab:before {
	background-position: -460px -200px
}

.emoji2714:before {
	background-position: -520px -580px
}

.emoji1f191:before {
	background-position: -0 -400px
}

.emoji1f192:before {
	background-position: -0 -420px
}

.emoji1f193:before {
	background-position: -0 -440px
}

.emoji1f194:before {
	background-position: -0 -460px
}

.emoji1f195:before {
	background-position: -0 -480px
}

.emoji1f196:before {
	background-position: -0 -500px
}

.emoji1f197:before {
	background-position: -0 -520px
}

.emoji1f198:before {
	background-position: -0 -540px
}

.emoji1f199:before {
	background-position: -0 -560px
}

.emoji1f19a:before {
	background-position: -0 -580px
}

.emoji1f201:before {
	background-position: -20px -200px
}

.emoji1f202:before {
	background-position: -20px -220px
}

.emoji1f232:before {
	background-position: -20px -280px
}

.emoji1f233:before {
	background-position: -20px -300px
}

.emoji1f234:before {
	background-position: -20px -320px
}

.emoji1f235:before {
	background-position: -20px -340px
}

.emoji1f236:before {
	background-position: -20px -360px
}

.emoji1f21a:before {
	background-position: -20px -240px
}

.emoji1f237:before {
	background-position: -20px -380px
}

.emoji1f238:before {
	background-position: -20px -400px
}

.emoji1f239:before {
	background-position: -20px -420px
}

.emoji1f22f:before {
	background-position: -20px -260px
}

.emoji1f23a:before {
	background-position: -20px -440px
}

.emoji3299:before {
	background-position: -560px -20px
}

.emoji3297:before {
	background-position: -560px -0
}

.emoji1f250:before {
	background-position: -20px -460px
}

.emoji1f251:before {
	background-position: -20px -480px
}

.emoji2795:before {
	background-position: -540px -260px
}

.emoji2796:before {
	background-position: -540px -280px
}

.emoji2716:before {
	background-position: -540px -0
}

.emoji2797:before {
	background-position: -540px -300px
}

.emoji1f4a0:before {
	background-position: -260px -200px
}

.emoji1f4a1:before {
	background-position: -260px -220px
}

.emoji1f4a2:before {
	background-position: -260px -240px
}

.emoji1f4a3:before {
	background-position: -260px -260px
}

.emoji1f4a4:before {
	background-position: -260px -280px
}

.emoji1f4a5:before {
	background-position: -260px -300px
}

.emoji1f4a6:before {
	background-position: -260px -320px
}

.emoji1f4a7:before {
	background-position: -260px -340px
}

.emoji1f4a8:before {
	background-position: -260px -360px
}

.emoji1f4a9:before {
	background-position: -260px -380px
}

.emoji1f4aa:before {
	background-position: -260px -400px
}

.emoji1f4ab:before {
	background-position: -260px -420px
}

.emoji1f4ac:before {
	background-position: -260px -440px
}

.emoji2728:before {
	background-position: -540px -20px
}

.emoji2734:before {
	background-position: -540px -60px
}

.emoji2733:before {
	background-position: -540px -40px
}

.emoji26aa:before {
	background-position: -520px -120px
}

.emoji26ab:before {
	background-position: -520px -140px
}

.emoji1f534:before {
	background-position: -360px -60px
}

.emoji1f535:before {
	background-position: -360px -80px
}

.emoji1f532:before {
	background-position: -360px -20px
}

.emoji1f533:before {
	background-position: -360px -40px
}

.emoji2b50:before {
	background-position: -540px -520px
}

.emoji2b1c:before {
	background-position: -540px -500px
}

.emoji2b1b:before {
	background-position: -540px -480px
}

.emoji25ab:before {
	background-position: -480px -580px
}

.emoji25aa:before {
	background-position: -480px -560px
}

.emoji25fd:before {
	background-position: -500px -80px
}

.emoji25fe:before {
	background-position: -500px -100px
}

.emoji25fb:before {
	background-position: -500px -40px
}

.emoji25fc:before {
	background-position: -500px -60px
}

.emoji1f536:before {
	background-position: -360px -100px
}

.emoji1f537:before {
	background-position: -360px -120px
}

.emoji1f538:before {
	background-position: -360px -140px
}

.emoji1f539:before {
	background-position: -360px -160px
}

.emoji2747:before {
	background-position: -540px -100px
}

.emoji1f4ae:before {
	background-position: -260px -480px
}

.emoji1f4af:before {
	background-position: -260px -500px
}

.emoji21a9:before {
	background-position: -480px -340px
}

.emoji21aa:before {
	background-position: -480px -360px
}

.emoji1f503:before {
	background-position: -320px -300px
}

.emoji1f50a:before {
	background-position: -320px -420px
}

.emoji1f50b:before {
	background-position: -320px -440px
}

.emoji1f50c:before {
	background-position: -320px -460px
}

.emoji1f50d:before {
	background-position: -320px -480px
}

.emoji1f50e:before {
	background-position: -320px -500px
}

.emoji1f512:before {
	background-position: -320px -580px
}

.emoji1f513:before {
	background-position: -340px -0
}

.emoji1f50f:before {
	background-position: -320px -520px
}

.emoji1f510:before {
	background-position: -320px -540px
}

.emoji1f511:before {
	background-position: -320px -560px
}

.emoji1f514:before {
	background-position: -340px -20px
}

.emoji2611:before {
	background-position: -500px -180px
}

.emoji1f518:before {
	background-position: -340px -100px
}

.emoji1f516:before {
	background-position: -340px -60px
}

.emoji1f517:before {
	background-position: -340px -80px
}

.emoji1f519:before {
	background-position: -340px -120px
}

.emoji1f51a:before {
	background-position: -340px -140px
}

.emoji1f51b:before {
	background-position: -340px -160px
}

.emoji1f51c:before {
	background-position: -340px -180px
}

.emoji1f51d:before {
	background-position: -340px -200px
}

.emoji2705:before {
	background-position: -520px -420px
}

.emoji270a:before {
	background-position: -520px -480px
}

.emoji270b:before {
	background-position: -520px -500px
}

.emoji270c:before {
	background-position: -520px -520px
}

.emoji1f44a:before {
	background-position: -200px -280px
}

.emoji1f44d:before {
	background-position: -200px -340px
}

.emoji261d:before {
	background-position: -500px -240px
}

.emoji1f446:before {
	background-position: -200px -200px
}

.emoji1f447:before {
	background-position: -200px -220px
}

.emoji1f448:before {
	background-position: -200px -240px
}

.emoji1f449:before {
	background-position: -200px -260px
}

.emoji1f44b:before {
	background-position: -200px -300px
}

.emoji1f44f:before {
	background-position: -200px -380px
}

.emoji1f44c:before {
	background-position: -200px -320px
}

.emoji1f44e:before {
	background-position: -200px -360px
}

.emoji1f450:before {
	background-position: -200px -400px
}

.emoji1f46c:before {
	background-position: -220px -360px
}

.emoji1f46d:before {
	background-position: -220px -380px
}

.emoji1f332:before {
	background-position: -40px -580px
}

.emoji1f333:before {
	background-position: -60px 0
}

.emoji1f34b:before {
	background-position: -60px -460px
}

.emoji1f60e:before {
	background-position: -380px -520px
}