@import "./../variables";

.cols {
	height: calc(100% - 112px);
	display: flex;
	// gap: 10px;
	margin: 0 20px 10px;

	&__wrapper {
		height: 100%;
		display: flex;
		width: 100%;
	}

	&.--director .cols__filters .cols__main {
		position: relative;
	}

	&.--director .cols__filters .cols__item {
		margin: 0 5px 0 0;
		//border-right: 1px solid #e8e8e8;
		padding: 0 5px 0 0;
	}

	&.--director .cols__filters .cols__box {
		background: #fff;
		padding: 15px 15px;
		border-color: #ddd;
	}

	&.--director .cols__filters .cols__name {
		font-weight: 500;
	}

	&__close-deals-mode-btns {
		display: grid;
	}

	&__choose-btn {
		color: #B9439D;
		font-weight: 500;
		cursor: pointer;
		margin-bottom: 10px;
		justify-self: flex-end;
	}

	&__choose-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 10px;

		div {
			color: #B9439D;
		    font-weight: 500;
		    cursor: pointer;
		}
	}

	&__chose-btn-disabled {
		cursor: not-allowed !important;
		opacity: 0.7;
	}

	&__filters {
		flex: 0 0 300px;
	}

	&__main {
		display: flex;
		justify-content: left;
		gap: 10px;
	}

	&__item {
		height: 100%;
		display: flex;
		flex: 0 0 300px;
		flex-direction: column;

		&:nth-last-child(1) {
			margin: 0;
		}
	}

	&__name {
		font-weight: 300;
		font-size: 18px;
		margin: 0 0 10px;
		text-align: center;
		text-transform: uppercase;
	}

	&__name:first-letter {
		text-transform: uppercase;
	}

	&__box {
		border: 1px solid #e8e8e8;
		background: #F2F2F2;
		border-radius: 3px;
		height: calc(100% - 36px);
		padding: 10px;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__deals {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__more {
		//background: #e8e8e8;
		border-radius: 2px;
		text-align: center;
		padding: 3px 0 4px;
		font-weight: 500;
		color: #787a7d;
		cursor: pointer;
		margin: 10px 0 0;
		transition: .1s ease;
		flex: 0 0 auto;

		&:hover {
			background: #e8e8e8;
			//color: #fff;
		}
	}

	&__add-client {
		background: #e8e8e8;
		border: none;
		width: 100%;
		border-radius: 2px;
		text-align: center;
		padding: 6px 0 6px;
		font-weight: 500;
		color: #787a7d;
		cursor: pointer;
		margin: 10px 0 0;
		transition: .1s ease;
		flex: 0 0 auto;

		&:hover {
			background: $color-main-purple;
			color: #fff;
		}
	}
}

.cols-total {
	border-radius: 5px;
	margin: 0 0 10px;
	display: flex;
	//padding: 0px 10px;

	&__amount, &__deals {
		flex: 1 0 auto;
		white-space: nowrap;

		& span {
			font-weight: 500;
		}
	}

	&__amount {
		text-align: left;
		padding: 0 10px 0 0;
	}

	&__deals {
		text-align: right;
		padding: 0 0 0 10px;
	}
}

//TODO: найти классам  нормальные места
//.deal {
//  //transition: all 5s;
//  transition: all 2s;
//  //transition: opacity 1s;
//  //display: inline-block;
//  //margin-right: 10px;
//}
//
//.flip-list-leave-to {
//  //opacity: 0;
//  transform: none;
//  //transform: translateY(30px);
//}
//.flip-list-enter-active, .flip-list-leave-active {
//  position: absolute;
//  left: 0;
//  right: 0;
//}

.no-move {
	//transition: transform 0s;
	transition: all 2s;
}

.ghost {
	opacity: 0.5;
	//background: #c8ebfb;
	//background: rgba($color-main-purple, .2) !important;
}

.flip-list-move {
	//transition: transform 2s;
}

.flip-list2-move {
	//transition: all 0s;
}

.flip-list-leave, .flip-list-leave-active, .flip-list-leave-to, .flip-list2-leave, .flip-list2-leave-active, .flip-list2-leave-to {
	//display: none;
}

//
//
//.flip-list-enter {
//  background: #106eed !important;
//  transition: all 2s;
//  transform: scale(0)
//}
//
//.flip-list-enter-active {
//  background: #106eed !important;
//  transition: all 2s;
//  transform: scale(0)
//}
//
//.flip-list-enter-to {
//  background: #106eed !important;
//  transition: transform 2s;
//  transform: scale(1)
//}
//
//.flip-list-move {
//  background: #00ed20 !important;
//  transition: all 2s;
//}
//
//.flip-list-leave {
//  background: #f00 !important;
//  transition: all 2s;
//}
//
//.flip-list-leave-active {
//  background: #f00 !important;
//  transition: all 2s;
//  transform: scale(0)
//}
//
//.flip-list-leave-to {
//  background: #f00 !important;
//  transition: all 2s;
//}


.cols-filters {

	&__group {
		//padding: 0 0 10px 0;
		padding: 0 0 5px 0;
		border-bottom: 1px solid #dddddd;
		margin: 0 0 15px 0;
	}

	&__group-title {
		font-size: 14px;
		line-height: 1.2;
		font-weight: 600;
		margin: 0 0 10px 0;
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		& .filter__item {
			margin: 0 10px 10px 0;
		}

		& .filter-item__label {
			padding: 2px 8px 3px;
		}
	}

	&__scroll {
		height: 100%;
		display: flex;
		width: 100%;
	}
}

.cols-filters .filter-item__input:checked + .filter-item__label {
	padding: 3px 12px 4px;
}

.cols-filters .scrolly-bar.axis-y {
	width: 8px !important;
	border: 1px solid transparent !important;
	right: -14px !important;
}

.cols-filters .filter__item {
	display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
	.cols {
		margin: 0 10px 10px;
		height: calc(100% - 57px);
	}
	.cols__box {
		height: calc(100% - 31px);
	}
}