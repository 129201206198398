<template>
  <div>
    <slot />
    <button @click="openQuickMessages">
      <svg
        v-bind="{
          width: size,
          height: size,
          cursor: cursor && 'pointer',
          position: 'relative',
        }"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.00002 8.00008C2.00002 4.68637 4.68631 2.00008 8.00002 2.00008C11.3137 2.00008 14 4.68637 14 8.00008C14 11.3138 11.3137 14.0001 8.00002 14.0001C6.94497 14.0001 5.95539 13.7283 5.09539 13.2515C4.76708 13.0695 4.35936 13.0144 3.97315 13.1509L2.29142 13.7451C2.18574 13.7825 2.08394 13.6807 2.12128 13.575L2.74014 11.8237C2.87134 11.4523 2.82541 11.0606 2.66027 10.7394C2.23842 9.91894 2.00002 8.98832 2.00002 8.00008ZM8.00002 0.666748C3.94993 0.666748 0.666687 3.94999 0.666687 8.00008C0.666687 9.20529 0.957942 10.3444 1.47449 11.3491C1.48026 11.3603 1.48228 11.3696 1.48276 11.3752C1.48298 11.3778 1.48286 11.3794 1.48278 11.38L0.864126 13.1308C0.453349 14.2933 1.57315 15.4131 2.73565 15.0023L4.41681 14.4082C4.41712 14.4082 4.41762 14.4082 4.41834 14.4082C4.41885 14.4082 4.41945 14.4082 4.42017 14.4082L4.42176 14.4083C4.42767 14.4089 4.43739 14.4113 4.44888 14.4176C5.50164 15.0013 6.71305 15.3334 8.00002 15.3334C12.0501 15.3334 15.3334 12.0502 15.3334 8.00008C15.3334 3.94999 12.0501 0.666748 8.00002 0.666748ZM4.70001 8.86662C5.16025 8.86662 5.53335 8.49352 5.53335 8.03328C5.53335 7.57305 5.16025 7.19995 4.70001 7.19995C4.23977 7.19995 3.86668 7.57305 3.86668 8.03328C3.86668 8.49352 4.23977 8.86662 4.70001 8.86662ZM8.86668 8.03328C8.86668 8.49352 8.49358 8.86662 8.03335 8.86662C7.57311 8.86662 7.20001 8.49352 7.20001 8.03328C7.20001 7.57305 7.57311 7.19995 8.03335 7.19995C8.49358 7.19995 8.86668 7.57305 8.86668 8.03328ZM11.3667 8.86662C11.8269 8.86662 12.2 8.49352 12.2 8.03328C12.2 7.57305 11.8269 7.19995 11.3667 7.19995C10.9064 7.19995 10.5333 7.57305 10.5333 8.03328C10.5333 8.49352 10.9064 8.86662 11.3667 8.86662Z"
          fill="#17171C"
        />
        <path
          d="M8.78268 11.3539L12.4164 8.6472C12.8597 8.31701 13.4971 8.72346 13.3168 9.2213L12.9441 10.2501C12.8567 10.4912 12.9696 10.7553 13.2122 10.877L15.132 11.8406C15.5017 12.0262 15.5331 12.5036 15.1905 12.7288L11.1668 15.3734C10.7392 15.6544 10.161 15.2992 10.2791 14.8281L10.6758 13.2464C10.7435 12.9767 10.5719 12.705 10.2838 12.6254L8.97773 12.2649C8.53855 12.1437 8.42629 11.6194 8.78268 11.3539Z"
          fill="#FFD600"
        />
        <path
          d="M8.78268 11.3539L12.4164 8.6472C12.8597 8.31701 13.4971 8.72346 13.3168 9.2213L12.9441 10.2501C12.8567 10.4912 12.9696 10.7553 13.2122 10.877L15.132 11.8406C15.5017 12.0262 15.5331 12.5036 15.1905 12.7288L11.1668 15.3734C10.7392 15.6544 10.161 15.2992 10.2791 14.8281L10.6758 13.2464C10.7435 12.9767 10.5719 12.705 10.2838 12.6254L8.97773 12.2649C8.53855 12.1437 8.42629 11.6194 8.78268 11.3539Z"
          fill="#FFD600"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0574 8.23922C12.9439 7.57884 14.2187 8.39174 13.858 9.38743L13.4854 10.4162L15.4052 11.3798C16.1445 11.7509 16.2074 12.7057 15.5221 13.1561L11.4984 15.8007C10.6432 16.3628 9.4868 15.6524 9.72312 14.7101L10.1198 13.1284L8.81375 12.7679L8.9777 12.265L8.81375 12.7679C7.93537 12.5254 7.71086 11.4769 8.42363 10.946L8.78265 11.354L8.42363 10.946L12.0574 8.23922ZM12.7754 9.05537L9.14166 11.7621L10.4477 12.1226C11.024 12.2817 11.367 12.8251 11.2318 13.3645L10.8351 14.9462L14.8588 12.3016L12.9389 11.338C12.4539 11.0946 12.228 10.5664 12.4027 10.0841L12.7754 9.05537Z"
          fill="black"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
	props: {
		size: {
			type: String,
			default: '20',
		},
		cursor: {
			type: Boolean,
			default: true,
		}
	},

	methods: {
		openQuickMessages() {
			this.$emit('open-quick-messages', true);
		}
	}
};
</script>

<style scoped>

div {
    display: flex;
    position: relative;
}

button {
    display: inline-flex;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
}
</style>