
.media-files-component {
    display: flex;
    gap: 15px;
}

.file-type {
    padding: 4px 8px;
    border: 1px solid #575757;
    border-radius: 8px;
    cursor: pointer;

    &--active {
        background: #575757;
        color: #FFFFFF;
    }
}

