
.assembled-products {
    margin-top: 1rem;
    &__total-info {
        &__container {
            display: flex;
            justify-content: space-between;
            padding: 0 28px;
        }

        padding-bottom: 1.3rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #9D9EAF;

        &__name {
            width: 60%;
        }

        &__value {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__select-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__price-info {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    &__input-wrapper {
        position: relative;
        display: inline-block;
    }

    &__input-wrapper-relative {
        position: relative;
    }

    &__additional-info {
        font-size: 10px;
        color: #575757;
        position: absolute;
        left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }
}

.selected-product-item {
    margin-bottom: 2rem;
    position: relative;
}

.deactivate-button {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    color: #ff0000;
    cursor: pointer;
}

.flex {
    display: flex;
}

.error-message {
    color: #ff0000;
    font-size: 0.8em;
}
