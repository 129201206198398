
.debug {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 111111;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0 20px rgba(0, 0, 0, .1);

	&__head {
		border-bottom: 1px solid #E5E5E5;
		color: #5b5b5b;
		margin: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		font-size: 18px;
		line-height: 50px;
		padding: 0 30px 0 0;
	}

	&__close {
		width: 16px;
		height: 50px;
		position: relative;
		cursor: pointer;
		margin: 0 0 0 15px;

		&:before {
			position: absolute;
			top: 19px;
			display: block;
			content: '';
			width: 14px;
			height: 14px;
			background: url('./../assets/close-grey.svg') no-repeat center;
		}
	}

	&__body {
		padding: 10px 20px;
	}
}
