
@import '@/styles/_variables.scss';

.modal__box__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    padding-top: 10px;
    border-bottom: none;
}

.modal__box {
    overflow: visible !important;
}

.modal-box-1__title {
    font-size: 20px;
    font-weight: 600;
    color: #1c1c1c;
}

.modal__close {
    cursor: pointer;
}

.btn-primary {
    cursor: pointer;
    background-color: $color-main-purple;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;

    &:hover {
        background-color: darken($color-main-purple, 10%);
    }
}
