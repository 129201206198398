
.mb-10 {
    margin-bottom: 10px;
}

.ml-10{
    margin-left: 10px;
}
.flex-center{
    display: flex;
    justify-content: center;
}
