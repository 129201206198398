@import './../variables';

.profile {
    font-family: 'Roboto Flex';
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #f1f1f4;
    overflow-y: auto;
    //TODO: что то сделать с фиксированной шириной
    // width: 550px;
    //padding: 15px 0 0 !important;

    &__wrapper {
        //width: calc(100% + 17px) !important;
        padding: 0 !important;
        overflow-x: hidden;
        overflow-y: hidden;

        & .profile__box:nth-child(1) {
            margin: 15px 20px;
        }
    }

    &__box {
        margin: 0 20px 15px;
        // background: #fff;
        // border: 1px solid #e5e5e5;
        border-radius: 3px;
    }

    &__head {
        // border-bottom: 1px solid #e5e5e5;
        display: flex;
        margin: 0 15px -1px 15px;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        &.--noBorder {
            border-bottom: 1px solid transparent;
        }
    }

    &__title {
        font-weight: 500;
    }

    &__settings {
        position: relative;
        @media (max-width: 425px){
           position: absolute;
            top: 11px;
            right: 50px;
        }
        // & > button {
        //     width: 16px;
        //     height: 20px;
        //     border: none;
        //     background: none;
        //     position: absolute;
        //     cursor: pointer;

        //     &.--show:before {
        //         background: url('assets/img/setting.svg') no-repeat -48px 0;
        //         background-size: cover;
        //     }

        // &.--show:hover:before {
        //     background: url('assets/img/setting.svg') no-repeat -48px 0;
        //     background-size: cover;
        // }

        // &:before {
        //     content: '';
        //     display: block;
        //     background: url('assets/img/setting.svg') no-repeat -16px 0;
        //     background-size: cover;
        //     width: 16px;
        //     height: 16px;
        //     position: absolute;
        //     top: 2px;
        //     right: 0px;
        // }

        // &:hover:before {
        //     background: url('assets/img/setting.svg') no-repeat -32px 0;
        //     background-size: cover;
        // }
        // }
    }

    &__body {
        padding: 11px 15px 10px 15px;

        &.--noPadding {
            padding: 0;
        }
    }

    &__main-box {
        margin: 0 auto;
        max-width: 380px;
        &.mb-15 {
            margin-bottom: 15px;
        }
    }

    &__hr {
        height: 1px;
        background: #e5e5e5;
        margin: 10px 0;
    }

    &__add-deal {
        & button {
            //background: #e8e8e8;
            background: transparent;
            border-radius: 2px;
            padding: 3px 0 4px;
            color: #787a7d;
            margin: 0;
            border: none;
            cursor: pointer;
            transition: 0.1s ease;

            &:hover {
                //background: #d6d8db;
                color: $color-main-purple;
            }
        }
    }

    &__accordion-arrow {
        & button {
            background: transparent;
            border: none;
            margin: 0 -4px 0 0;
            padding: 0;
            cursor: pointer;
            width: 20px;
            height: 20px;
            display: block;
            position: relative;
        }

        &.--open button:before {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            background: url('assets/img/arrow-1-sprite.svg') no-repeat -12px 0px;
            background-size: cover;
        }

        &.--close button:before {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            background: url('assets/img/arrow-1-sprite.svg') no-repeat 0px 0px;
            background-size: cover;
        }
    }

    &__dropdown {
        position: absolute;
        max-width: 180px;
        min-width: 180px;
        top: 30px;
        right: -5px;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #9d9eaf;
        transition: 0.2s;
        cursor: auto;
        z-index: 9999;

        & > button {
            display: block;
            width: 100%;
            padding: 12px;
            background: none;
            border: none;
            text-align: left;
            cursor: pointer;
            color: #17171c;

            &:nth-child(1) {
                border-radius: 8px;
            }

            &:nth-last-child(1) {
                border-radius: 8px;
            }

            &:hover {
                background: #f6f6f6;
            }
        }

        &.--show {
            top: 30px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        &:after,
        &:before {
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            bottom: 100%;
            right: 17px;
        }

        &:before {
            border-width: 6px;
            border-bottom-color: #9d9eaf;
        }

        &:after {
            border-width: 5px;
            border-bottom-color: #fff;
            margin: 0 1px 0 0;
        }
    }

    .select-title {
        padding-bottom: 5px;
        border: none;
    }
}

.profile-tabs {
    &__item {
        height: 40px;
        padding: 10px 0;
        margin: 0 15px 0 0;
        cursor: pointer;

        &.--active {
            color: $color-main-purple;
            border-bottom: 1px solid $color-main-purple;
        }
    }
}

.profile-form {
    & .input-group {
        position: relative;
    }

    &__save {
        position: absolute;
        top: 1px;
        right: 1px;
        font-size: 12px;
        background: #fff;
        padding: 3px 7px 2px;
        font-weight: 500;
        color: $color-main-purple;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 0.5s ease;

        &.--save {
            visibility: visible;
            pointer-events: auto;
            animation: save 2s infinite ease-in-out;
        }
    }

    &__row {
        display: flex;
    }

    &__title {
        font-size: 12px;
        color: #9d9eaf;
        margin: 0;
    }

    .form-group {
        background: #fff;
        padding: 1px 12px;
        border-radius: 8px;
        border: 1px solid $color-border;
        &--child {
            position: relative;
            margin-left: 20px;
        }

        &--parent {
            position: relative;
        }

        &:focus-within {
            border-color: $color-main-purple;

            .profile-form__title {
                color: $color-main-purple;
            }
        }
    }

    .form-control {
        border: none;
        height: auto;
        padding: 0;
        font-size: 14px;
        color: #17171c;
    }

    .contacts {
        border: none;
        background-color: transparent;
        padding: 0;

        .input-group {
            margin-bottom: 15px;
        }
    }

    &__checkboxes {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.profile-phones {
    //border-top: 1px solid #e5e5e5;
    //border-bottom: 1px solid #e5e5e5;
    //padding: 5px 0 0;
    //margin: -1px 0 0;

    // border: 1px solid #e5e5e5;
    padding: 12px;
    border-radius: 5px;
    margin: 0 0 10px;
    background: #fff;
    display: flex;
    gap: 16px;
    // box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.075);

    &:hover .profile-phones__contact-edit,
    &:hover .profile-phones__contact-delete {
        opacity: 1;
    }

    &__item {
        position: relative;
        display: flex;
        gap: 5px;
        border-radius: 10px;
        // padding: 11px;
        //margin: 10px 0 15px;
        transition: all 0.2s ease;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &:hover {
            cursor: grab;
        }

        &:hover .move-phone {
            opacity: 1;
        }

        &:active {
            // border-radius: 10px;
            padding: 11px;
            background-color: #e3e3e8;
        }
    }

    &__icon {
        width: 30px;
        height: 30px;
    }

    &__call {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid $color-main-purple;
        position: relative;
        cursor: pointer;
        transition: background 0.2s ease;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background: url('assets/img/phone-1.svg');
            background-size: cover;
            background-position: 42px 0;
            width: 14px;
            height: 14px;
            top: 6px;
            left: 6px;
        }

        &:hover {
            background: $color-main-purple;

            &:before {
                background-position: -28px 0;
            }
        }

        &:disabled {
            opacity: 0.5;
            cursor: auto;

            &:hover {
                background: #fff;

                &:before {
                    background-position: 42px 0;
                }
            }
        }
    }

    &__info {
        display: flex;
        align-items: center;
        margin-left: 15px;

        &__content {
            flex: 1 0 auto;
            padding: 0 0 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
        }
    }

    &__name {
        font-size: 12px;
        line-height: 1;
        font-weight: normal;
        color: #505162;
        margin-bottom: 5px;
        //padding: 2px 0 0
    }

    &__details {
        display: flex;
        justify-content: space-between;
    }

    &__phone {
        font-weight: 500;
        font-size: 14px;
        color: #17171c;
        padding: 1px 0 0;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            color: $color-main-purple;
        }
    }

    &__add {
        float: right;
        font-size: 14px;
        color: $color-main-purple;
        font-weight: 500;
        cursor: pointer;
        border: none;
        border-radius: 8px;
        padding: 5px 10px;
        margin: 0;
        background-color: #fff;
        display: inline-block;
        line-height: 21px;
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }

    &__head {
        width: 70px;
    }

    &__actions {
        display: flex;
        position: absolute;
        right: 11px;
        top: 11px;
        flex-direction: column;
        gap: 10px;
    }

    &__contact-name {
        font-size: 12px;
        color: #17171c;
        font-weight: 500;
        margin: 0;
    }

    &__contact-edit {
        // flex: 0 0 auto;
        opacity: 0;
        transition: opacity 0.2s ease;

        & button {
            background: none;
            border: none;
            // color: #54b744;
            font-size: 12px;
            line-height: 1;
            cursor: pointer;
            display: block;
            padding: 0;
        }
    }

    &__contact-delete {
        flex: 0 0 auto;
        opacity: 0;
        transition: opacity 0.2s ease;

        & button {
            background: none;
            border: none;
            color: #ff5151;
            font-size: 12px;
            line-height: 1;
            cursor: pointer;
            display: block;
            padding: 0;
        }
    }

    &__plus {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid $color-main-purple;
        position: relative;
        cursor: pointer;
        transition: background 0.2s ease;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background: url('assets/img/plus.svg');
            background-size: cover;
            background-position: 28px 0;
            width: 14px;
            height: 14px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            background: $color-main-purple;
            border: 2px solid $color-main-purple;

            &:before {
                background-position: -28px 0;
            }
        }

        &:disabled {
            opacity: 0.5;
            cursor: auto;

            &:hover {
                background: #fff;

                &:before {
                    background-position: 42px 0;
                }
            }
        }
    }

    &__add-text {
        font-size: 12px;
        cursor: pointer;
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        color: #17171c;
    }
}

.profile-deals-tabs {
    display: flex;
    align-items: center;

    &__item {
        cursor: pointer;
        display: inline-block;
        // padding: 1px 10px 3px 10px;
        background: transparent;
        border-radius: 2px;
        color: #585858;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px 0 0;
        text-align: center;
        width: auto !important;
        transition: border 0.2s ease, background 0.2s ease, color 0.2s ease;

        &.--active {
            border-bottom: 2px solid $color-main-purple;
            color: $color-main-purple;
        }

        &:not(.--active):hover {
            border-bottom: 2px solid $color-main-purple;
            color: $color-main-purple;
        }
    }
}

.profile-deals-buttons {
    display: flex;
    justify-content: space-between;
    row-gap: 10px;
    flex-wrap: wrap;
    //padding: 5px 0;

    & .btn {
        margin: 0 10px;
    }
}

.deal-action-block {
    &__wrapper {
        //width: calc(100% + 17px) !important;
        padding: 0 !important;
        overflow-x: hidden;
        overflow-y: hidden;

        & .profile__box:nth-child(1) {
            margin: 15px 20px;
        }
    }
}

.move-phone-replace {
    width: 20px;
    height: 20px;
}

.move-phone {
    background: url('assets/img/drag.svg') no-repeat;
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
}

.select__child {
    position: relative;
    z-index: 0;
}

.select__child-arrow {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    top: -50px;
    left: 5px;
    width: 100px;
    height: 80px;
    border-left: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    border-bottom-left-radius: 5px;
}

@keyframes save {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .profile__main-box {
        padding: 0;
    }
    .profile {
        width: 95vw;
    }
}
