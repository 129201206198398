
@import '@/styles/_variables.scss';

.deal-tabs {
    margin: 10px 20px;

    button {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 10px;
        border-bottom: 2px solid #17171c;
        font-weight: 400;

        &:hover {
            opacity: 0.8;
        }

        &.--active {
            font-weight: 500;
            border-bottom: 2px solid $color-main-purple;
        }
    }
}

.tasks-wrapper {
    margin-top: 80px;
    height: 78%;
}

.task {
    width: 320px;
    min-height: 95px;
}

.tasks-container {
    width: 370px;
    margin: 0 auto;

    span {
        font-size: 13px;
        font-weight: 400;
        color: #575757;
        margin-left: 7px;
    }
}

.to-calendar-tasks {
    margin-top: 15px;
    position: absolute;
    right: 100px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #b9439d;
}

.expired-task-separator {
    display: block;
    margin-top: 10px;
}
