<template>
  <div class="modal">
    <div class="modal__main">
      <div class="modal__box">
        <div class="modal-box-1">
          <div class="modal-box-1__head">
            <div class="modal-box-1__title">
              Выбор сделки
            </div>
            <div
              class="modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="modal-box-1__body">
            <div class="container">
              <span>Выберите сделку которую хотите записать: </span>
              <div
                v-for="d in deals"
                :key="d.dealId"
                class="item"
                @click="selectDeal(d.dealId)"
              >
                <b>{{ d.title }}</b> -<span class="deal-id">{{ d.dealId }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store';
import { computed } from 'vue';

export default {
	setup() {
		const deals = computed(() => store.state.modal.selectDealForEvent.data.deals);


		return {
			deals,
		};

	},
	methods: {
		selectDeal(id) {
			this.$bus.$emit('selectDealForEvent', id);
		},
		close() {
			store.dispatch('modal/closeModal', 'selectDealForEvent');
			store.dispatch('modal/setModalData', {
				modal: 'selectDealForEvent',
				data: {},
			},
			);
			this.$bus.$emit('resetDraggableElement');
		},
	},
};
</script>

<style scoped>

.item {
    margin-top: 10px;
}

.deal-id {
    text-decoration: underline;
    color: purple;
    cursor: pointer;
}

</style>