
.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input-field {
    width: 100%;
    border: 1px solid #A6A6A6;
    border-radius: 10px;
    font-size: 14px;
    padding-right: 30px;

    &:focus {
        outline: none;
        border-color: rgb(194, 62, 255);
        box-shadow: 0 0 5px rgba(231, 113, 255, 0.7);
    }

    &::placeholder {
        color: #575757;
    }
}

.input-field__error {
    border-color: red;
    box-shadow: 0 0 5px rgba(239, 37, 37, 0.7);
}

.input-label {
    position: absolute;
    top: 8px;
    left: 12px;
    font-size: 12px;
    color: grey;
    transition: all 0.3s ease;
    pointer-events: none;
}

.input-field:not(:placeholder-shown) + .input-label,
.input-field:focus + .input-label {
    top: -1px;
    left: 12px;
    font-size: 10px;
    color: rgb(194, 62, 255);
}

.input-suffix {
    position: absolute;
    right: 12px;
    font-size: 14px;
    color: #575757;
    pointer-events: none;
}

.input-field[type="number"] {
    -moz-appearance: textfield;
}

.input-field[type="number"]::-webkit-outer-spin-button,
.input-field[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
