.modeMenu {
    padding-left:20px;
    padding-bottom: 20px;

    .modeButtons {
        display: flex;
        align-items: center;
        gap: 10px;
        overflow: auto;
        white-space: nowrap;
    
        .modeButtonsInfo {
            color: #575757;
            font-weight: 500;
        }
        
        .modeBtns {
            &__transferMode {
                display: flex;
                align-items: center;
                gap: 15px;
    
                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
        
                button {
                    gap: 5px;
                }
        
                strong {
                    margin-top: 1px;
                    font-size: 22px;
                }
        
                .--disabled {
                    opacity: 0.5;
                    cursor: not-allowed !important;
                }
            }
        }
    
        .cancelMassiveTransfer {
            color: #EF4444;
            font-weight: 500;
            cursor: pointer;
        }
    }
    
    .chooseDealsToTransferNtfc {
        margin-top: 20px;
        width: 1200px;
        font-size: 14px;
        font-weight: 500;
        padding: 16px;
        border: 1px solid #F59E0B;
        border-radius: 8px;
        background-color: #FEF3C7;
        display: flex;
        align-items: center; 
        justify-content: space-between; 
    
        p {
            padding: 0;
            margin: 0;
            flex: 1; 
            text-align: center; 
        }
    
        div {
            cursor: pointer;
            margin-left: auto;
            font-size: 25px;
            font-weight: 300;
        }
    
    }
    
    .acceptDealWindow {
        margin-top: 20px;
        width: 1200px;
        font-size: 14px;
        font-weight: 500;
        padding: 16px;
        border: 1px solid #3B82F6;
        border-radius: 8px;
        // background-color: #DBEAFE;
        display: flex;
        align-items: center; 
        justify-content: space-between; 
    }

    .acceptDealWindow__buttons {
        display: flex;
        gap: 10px;
    }
}

.closeDealsNotification {
    background-color: #F59E0B;
    border: 1px solid #996712;
    border-radius: 8px;
    height: 24px;
    padding: 0 15px;
    cursor: pointer;
}

.switchTransferModeBtn {
    background-color: #FFFFFF;
    border: none;
    border-radius: 8px;
    color: #B9439D;
    height: 24px;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;
}

.transferBtn {
    border: none;
    background-color: #B9439D;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
}