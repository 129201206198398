@import "variables";

.vdatetime-popup__header {
	background: $color-main-purple !important;
};

.vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
	background: $color-main-purple !important;
}

.vdatetime-popup__actions__button {
	color: $color-main-purple !important;
}

.vdatetime-popup__actions__button--confirm {
	background: $color-main-purple !important;
	color: #fff !important;
	border-radius: 5px;
}

.vdatetime-month-picker__item--selected {
	color: $color-main-purple !important;
}

.tippy-content {
	font-size: 12px !important;
}