
.voice-section {
    display: flex;
    gap: 15px;
}

.language {
    border: 1px solid #A6A6A6;
    border-radius: 8px;
    padding: 6px 12px;
    height: 40px;
}

.dropdown-section{
    display: flex;
    gap: 15px;
}

.modal-description {
    border: 1px solid #A6A6A6;
    width: 100%;
    border-radius: 8px;
    padding: 8px 12px;
    resize: none;
    min-height: 428px;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.error {
    color: red;
}
