<template>
  <div class="modal">
    <div class="modal__main">
      <div class="modal__box">
        <div class="modal-box-1">
          <div class="modal-box-1__head">
            <div class="modal-box-1__title">
              Шаблоны
            </div>
            <div
              class="modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="modal-box-1__body">
            <span>Данные шаблоны выходят за пределы рабочего времени: </span>
            <div
              v-for="t in templates"
              :key="t"
              class="item"
            >
              <b>{{ getTemplateName(t) }}</b>
            </div>
            <a
              class="btn button"
              href="/schedule"
              target="_blank"
            >Открыть новую вкладку</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../../store';
import { computed } from 'vue';
import moment from 'moment';
export default {
	setup(props, {r}) {
		const templates = computed(() => store.state.modal.scheduleTemplateOverlay.data.templates);
		const close = () => {
			store.dispatch('modal/closeModal', 'scheduleTemplateOverlay');
		};

    
		const getTemplateName = computed(() => (id) => store.state.scheduleConstructor.templates.data.find(item => item.id === id)?.name);

		return {
			close,
			moment,
			templates,
			getTemplateName
		};

	}
};
</script>

<style scoped>
    .button{
        text-decoration: none;
        display: inline-block;
        margin-top: 10px;
    }
</style>