
.dropdown-select {
    position: relative;
    width: 100%;
    user-select: none;
    font-size: 14px;
}

.dropdown-select__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #A6A6A6;
    border-radius: 10px;
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dropdown-select__trigger:hover {
    border-color: rgb(194, 62, 255);
}

.arrow {
    width: 10px;
    height: 10px;
    background: url('../../assets/calendar/arrow.svg') no-repeat center center;
    background-size: contain;
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}

.open .arrow {
    transform: rotate(90deg);
}

.dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #A6A6A6;
    border-radius: 10px;
    overflow-y: auto;
    padding: 10px 0;
    max-height: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    animation: dropdown-animation 0.3s ease;
}

@keyframes dropdown-animation {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dropdown-option {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-option:hover {
    background-color: rgba(194, 62, 255, 0.1);
}

.dropdown-checkbox {
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: background 0.3s ease;
    outline: none;
}

.dropdown-checkbox:focus {
    outline: none;
}

.dropdown-checkbox:checked {
    background-color: #34D399;
    border: none;
    transition: background 0.3s ease;
    outline: none;
}

.dropdown-checkbox:checked::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    top: 2px;
    left: 5px;
    transform: rotate(45deg);
}

.additional-info {
    color: #575757;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
