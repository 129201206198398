
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.products-table {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__product-name {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__input-wrapper {
        position: relative;
        display: inline-block;
    }

    &__input-wrapper-relative {
        position: relative;
    }

    &__additional-info {
        font-size: 10px;
        color: #575757;

        position: absolute;
        left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    &__input {
        width: 100%;
        padding: 4px 20px 4px 8px;
        border: 1px solid #A6A6A6;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
            outline: none;
            border-color: rgb(194, 62, 255);
            box-shadow: 0 0 5px rgba(231, 113, 255, 0.7);
        }

        &::placeholder {
            color: #575757;
        }

        &-checkbox {
            width: 16px;
            height: 16px;
            cursor: pointer;
            position: relative;
            appearance: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: #fff;
            transition: background 0.3s ease;
            outline: none;
        }

        &-checkbox:focus {
            outline: none;
        }

        &-checkbox:checked {
            background-color: #34D399;
            border: none;
            transition: background 0.3s ease;
            outline: none;

        }

        &-checkbox:checked::before {
            content: '';
            position: absolute;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            top: 2px;
            left: 5px;
            transform: rotate(45deg);
        }
    }

    &__sign {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 14px;
        color: #575757;
    }
}

.products-list {
    display: flex;
    flex-direction: column;
    width: 70%;

    background-color: #ffffff;
    padding: 16px 20px;
    border-radius: 20px;

    &__table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            border-bottom: 1px solid #1c1c1c;
            padding: 8px 8px 16px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            max-width: 100px;
            white-space: nowrap;
        }

        th {
            color: #575757;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
        }

        td:last-child {
            text-align: end;
        }

        td .icon {
            margin: 0 8px;
            cursor: pointer;
        }
    }
}

.row-active {
    background-color: #e8f5e9;
}

.row-zero-quantity {
    background-color: #f5f5f5;
    color: #a9a9a9;
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}
