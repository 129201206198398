
.tooltip-button {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: 9px;

    .tooltip {
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-8%);
        padding: 8px;
        background-color: #FEF3C7;
        color: #1C1C1C;
        border-radius: 8px;
        border: 1px solid #F59E0B;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        visibility: hidden;
        width: max-content;
        opacity: 0;
        transition: opacity 0.4s, visibility 0.4s;
        z-index: 9999;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 10%;
            transform: translateX(-50%);
            width: 12px;
            height: 12px;
            background-image: url('./../../assets/icons/tooltip-snot.svg');
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    &:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 600px) {
    .tooltip-button .tooltip {
        transform: translateX(-92%);
        bottom: calc(100% + 5px);
        margin-right: 10px;

        &::after {
            left: auto;
            right: 10%;
            transform: translateX(50%) scaleX(-1);
        }
    }
}

