.custom-checkbox-1 {
    & input[type='checkbox']:not(:checked),
    & input[type='checkbox']:checked {
        position: absolute;
        left: -9999px;
    }
    & input[type='checkbox']:not(:checked) + label,
    & input[type='checkbox']:checked + label {
        position: relative;
        padding-left: 1.95em;
        cursor: pointer;
        margin: 0;
        line-height: 1.2;
    }

    /* checkbox aspect */
    & input[type='checkbox']:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border: 1px solid #e5e5e5;
        background: #fff;
        border-radius: 2px;

        //box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    }

    & input[type='checkbox']:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 2px;
        border: 1px solid $color-main-purple;
    }

    /* checked mark aspect */
    & input[type='checkbox']:not(:checked) + label:after,
    & input[type='checkbox']:checked + label:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        background: $color-main-purple;
        transition: all 0.2s;
    }
    /* checked mark aspect changes */
    & input[type='checkbox']:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    & input[type='checkbox']:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
    /* disabled checkbox */
    & input[type='checkbox']:disabled:not(:checked) + label:before,
    & input[type='checkbox']:disabled:checked + label:before {
        box-shadow: none;
        border: 1px solid #e5e5e5;
        background: #f1f1f1;
        cursor: not-allowed;
    }
    & input[type='checkbox']:disabled:checked + label:after {
        background: #c79cc4;
    }
    & input[type='checkbox']:disabled + label {
        color: #aaa;
    }
    /* accessibility */
    & input[type='checkbox']:checked:focus + label:before,
    & input[type='checkbox']:not(:checked):focus + label:before {
        border: 1px solid $color-main-purple;
    }

    /* hover style just for information */
    label:hover:before {
        border: 1px solid $color-main-purple;
    }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .custom-checkbox-2 {
        margin-bottom: 10px;
        input[type='checkbox'] {
            --active: #1be484;
            --active-inner: #fff;
            --focus: 2px rgba(39, 254, 93, 0.3);
            --border: #bbc1e1;
            --border-hover: #1baf68;
            --background: #fff;
            --disabled: #f6f8ff;
            --disabled-inner: rgb(194, 216, 206);
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 21px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--bc, var(--border));
            background: var(--b, var(--background));
            transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

            &:after {
                content: '';
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            }

            &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: 0.3s;
                --d-t: 0.6s;
                --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
            }

            &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: 0.9;

                &:checked {
                    --b: var(--disabled-inner);
                    --bc: var(--border);
                }
            }

            &:hover:not(:checked):not(:disabled) {
                --bc: var(--border-hover);
            }

            &:focus {
                box-shadow: 0 0 0 var(--focus);
            }

            &:not(.switch) {
                width: 21px;

                &:after {
                    opacity: var(--o, 0);
                }

                &:checked {
                    --o: 1;
                }
            }

            & + label {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                margin-left: 8px;
                font-weight: 400;
            }

            &:not(.switch) {
                border-radius: 7px;

                &:after {
                    width: 5px;
                    height: 9px;
                    border: 2px solid var(--active-inner);
                    border-top: 0;
                    border-left: 0;
                    left: 7px;
                    top: 4px;
                    transform: rotate(var(--r, 20deg));
                }

                &:checked {
                    --r: 43deg;
                }
            }
        }
    }
}

.custom-checkbox-2 * {
    box-sizing: inherit;
}

.custom-checkbox-2 *:before,
.custom-checkbox-2 *:after {
    box-sizing: inherit;
}
