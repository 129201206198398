
.post-message {
    padding: 2px;
    max-width: 400px;

    &__caption {
        display: flex;
        gap: 7px;
        align-items: center;
    }

    &__img {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }

    p {
        margin-left: 12px;
    }
}
