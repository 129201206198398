
.row{
    cursor: pointer;
    display: flex;
    column-gap: 13px;
    align-items: center;
    font-weight: bold;
}
.arrow-dropdown{
    width: 15px;
    height: 15px;
    background: url("../../assets/arrow-dropdown.svg") no-repeat center center;
    background-size: cover;
    transition: 0.3s;
    &--opened{
        transform: rotate(180deg);
    }
}
