.vb > .vb-dragger {
  z-index: 5;
  width: 5px;
  right: 0;
  cursor: pointer;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out,
  margin 100ms ease-out,
  height 100ms ease-out;
  transition: background-color 100ms ease-out,
  margin 100ms ease-out,
  height 100ms ease-out;
  background-color: rgba(162, 58, 153, 0.1);;
  //margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: 100%;
  display: block;
}

.vb:hover > .vb-dragger > .vb-dragger-styler {
  //background-color: rgba(162, 58, 153, 1);
  background: #ccc;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  //background-color: rgba(162, 58, 153, 1);
  background: #ccc;
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  //background-color: rgba(162, 58, 153, 1);
  background: #ccc;
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  //background-color: rgba(162, 58, 153, 1);
  background: #ccc;
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(162, 58, 153, 0.5);
}
.vb-dragger{
  min-height: 20px;

  &:hover,&:active{
    width: 12px;
  }
}