<template>
  <div class="social-media-icon">
    <MediaDropdown
      :options="options"
      :default-value="defultValue"
      @input="changeChatType"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import MediaDropdown from './MediaDropdown.vue';
import WhatsAppIcon from '../../../assets/img/whatsapp-ntf.svg';
import WhatsAppRedIcon from '../../../assets/img/red-message.svg';
import InstagramIcon from '../../../assets/img/instagram-icon.svg';

export default {
	name: 'SocialMediaIcon',
	components: {
		MediaDropdown,
	},
	props: {
		whatsappStatus: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapState({
			chats: state => state.manager.roles.chats,
			chatType: state => state.manager.chatType,
		}),
		options() {
			const options = [];
			
			// if (this.chats.whatsapp360) {
			// 	options.push({	
			// 		id: 1,
			// 		name: 'WhatsApp',
			// 		iconPath: this.whatsappStatus ? WhatsAppIcon : WhatsAppRedIcon,
			// 		key: 'whatsapp360',
			// 	});
			// }

			// if (this.chats.instagram) {
			// 	options.push({	
			// 		id: 2,
			// 		name: 'Instagram',
			// 		iconPath: InstagramIcon,
			// 		key: 'instagram',
			// 	});
			// }

			return options;
		},
		defultValue() {
			return this.options.find(option => option.key === this.chatType);
		}
	},
	methods: {
		...mapMutations({
			setChatType: 'manager/setChatType',
		}),
		changeChatType(value) {
			if (value) {
				this.setChatType(value.key);
			}
		},
	},
};
</script>

<style scoped>
.social-media-icon {
	width: 60px;
	cursor: pointer;
}
</style>