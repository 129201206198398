
@import '../../styles/_variables.scss';

.select-container {
    font-family: 'Roboto Flex', sans-serif;
    width: 100%;
    margin-bottom: 10px;
    &--disabled {
        pointer-events: none;

        .select {

            background-color: #ececec;

            label {
                color: #a8a9b3;
            }
        }
    }
}

.autocomplete {
    position: relative;
}

.select {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    z-index: 1;
    position: relative;
}

.select input {
    cursor: pointer;
    width: 100%;
    padding: 25px 12px 5px 12px;
    border: 1px solid $color-border;
    border-radius: 8px;
    outline: none;

    &:focus {
        border-color: $color-main-purple;
    }
}

.select input::placeholder {
    color: #757575;
    font-weight: 400;
    transform: translateY(-11px);
}

.select label {
    position: absolute;
    top: 3px;
    left: 12px;
    font-size: 12px;
    margin: 0;
    color: #792d67;
    font-weight: 500;
}

.arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #a6a6a6 transparent transparent transparent;
    transition: transform 0.3s;
}

.arrow-up {
    transform: translateY(-50%) rotate(180deg);
}

.options {
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0;
    list-style: none;
    animation: slideDown 0.3s ease forwards;
    z-index: 9999;
    max-height: 200px;
    overflow-y: auto;
}

.option {
    cursor: pointer;
    padding: 10px;

    &--disabled {
        opacity: 0.5;
    }

    &:hover {
        background: #f0f0f0;
    }
}

.option-default {
    opacity: 0.5;
}

.options.show {
    display: block;
}

.select--active {
    input {
        border-color: $color-main-purple;
    }

    label {
        color: $color-main-purple;
    }
}

.options::-webkit-scrollbar {
    width: 10px;
}

.options::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.options::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 5px;
}

.options::-webkit-scrollbar-thumb:hover {
    background: #919191;
}

.select-input {
    padding-top: 35px !important;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
