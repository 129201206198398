<template>
  <h1 :class="mergeClasses">
    <slot />
  </h1>
</template>

<script>
export default {
	props: {
		classes: {
			type: String,
			required: false,
			default: '',
		}
	},

	computed: {
		mergeClasses() {
			return 'title ' + this.classes;
		}
	}
};
</script>

<style scoped>

    .title {
        display: contents;
        font-weight: 600;
        font-size: 20px;
        color: #1C1C1C;
        line-height: 32px;
    }

</style>