

@import '../../styles/variables.scss';
.select-group {
    background: #fff;
    padding: 1px 12px;
    border-radius: 8px;
    border: 1px solid $color-border;
    &--child {
        position: relative;
        margin-left: 20px;
    }

    &--parent {
        position: relative;
    }

    &:focus-within {
        border-color: $color-main-purple;

        .profile-form__title {
            color: $color-main-purple;
        }
    }
}
.profile__title {
    font-weight: 600;
    font-size: 20px;
}

.profile__close {
    cursor: pointer;
}

.btn {
    padding: 10px 20px;
    border-radius: 5px;
}

.btn-secondary {
    background: transparent;
    border: none;
    color: #1c1c1c;

    &:hover {
        color: #1c1c1c;
        opacity: 0.8;
        background: transparent;
        border: none;
    }
}

.profile-deals-buttons {
    margin-top: 40px;
}

.total-sum {
    &__input-container {
        flex-grow: 1;
        position: relative;
    }

    &__input-label {
        position: absolute;
        top: 8px;
        left: 12px;
        font-size: 12px;
        color: grey;
        transition: all 0.3s ease;
        pointer-events: none;
    }

    &__input:not(:placeholder-shown) + &__input-label,
    &__input:focus + &__input-label {
        top: -1px;
        left: 12px;
        font-size: 10px;
        color: rgb(194, 62, 255);
    }

    &__input {
        width: 100%;
        padding: 16px 12px 3px 12px;
        border: 1px solid #A6A6A6;
        border-radius: 10px;
        font-size: 16px;

        &:focus {
            outline: none;
            border-color: rgb(194, 62, 255);
            box-shadow: 0 0 5px rgba(231, 113, 255, 0.7);
        }

        &::placeholder {
            color: #575757;
        }
    }
}
