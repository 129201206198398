@import "./../variables";

.deal-list {
  height: 100%;

  //&:hover .vb-dragger {
  //		width: 4px;
  //		right: 2px;
  //
  //		& .vb-dragger-styler {
  //			background-color: rgba(0, 0, 0, .1);
  //
  //			&:hover {
  //				background-color: rgba(0, 0, 0, .2);
  //			}
  //		}
  //	}
  //}
  //
  //& .vb-dragger {
  //	width: 4px;
  //	right: 2px;
  //
  //	& .vb-dragger-styler {
  //		background-color: rgba(0, 0, 0, .1);
  //
  //		&:hover {
  //			background-color: rgba(0, 0, 0, .2);
  //		}
  //	}
  //}

  &__item {
    //border-bottom: 1px solid #d6d8db;
    //border-top: 1px solid #eaeaea;
    //border-bottom: 1px solid #eaeaea;
    padding: 12px 15px 12px 15px;
    cursor: pointer;
    transition: .2s;
    position: relative;
    transition: .2s;
    margin: -1px 0 0;
    display: flex;
    justify-content: space-between;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 20px;
      right: 20px;
      height: 1px;
      background: #eaeaea;
      transition: .2s;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: 20px;
      right: 20px;
      height: 1px;
      background: #eaeaea;
      transition: .2s;
    }

    &:nth-last-child(1) {
      border: none;
    }

    &:hover {
      background: #f3f3f3;
    }

    &.--active {
      background: $color-main-purple !important;
      //border-color: $color-main-purple  !important;
      z-index: 3;

      &:before {
        background: $color-main-purple;
      }

      &:after {
        background: $color-main-purple;
      }

      & .deal-list-head__name {
        //color: $color-main-purple;
        color: #ffffff !important;
      }

      & .deal-list-head__call:before {
        background-position: -28px 0 !important;
      }

      & .deal-list__description {
        color: #ffffff !important;
      }

      & .deal-list__sum {
        color: #fff !important;
      }

      //&:before {
      //  content: '';
      //  display: block;
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  bottom: 0;
      //  width: 2px;
      //  background: $color-main-purple;
      //}
    }

    &.--transfer {
      background: #FFEECC;
      border-color: #FFCC90;
      z-index: 2;

      & .deal-list-head__name {
        //color: #ffffff;
        color: #af570c;
      }

      & .deal-list-head__call:before {
        //background-position: -28px 0;
      }

      & .deal-list__description {
        //color: #ffffff;
        color: #af570c;
      }

      & .deal-list__sum {
        //color: #fff;
        color: #af570c;
      }
    }
  }

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  &__sum {
    //padding: 0 0 0 20px;
    padding: 0;
    font-size: 12px;
    line-height: 1;
    color: #777;
    //margin: 0 0 3px;

    & span {
      font-weight: 500;
    }
  }

  &__description {
    position: relative;
    padding: 0px 0px 0px 20px;
    color: #E8A04B;
    font-weight: normal;
    //margin: 0 0 3px;
    font-size: 12px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 0px;
      width: 14px;
      height: 14px;
      background: url('assets/img/o-clock.svg') no-repeat center;
    }
  }
  &__ava-wrap {
    flex: 0 0 36px;
    margin: 0 10px 0 0;
  }

  &__ava {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: #fff;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    //box-shadow: 0px 0px 0px 1px rgba(255,255,255,.3);
  }

  &__info {
    flex: 1 1 auto;
  }
}

.deal-list-head {
  display: flex;
  //margin: 0 0 3px;

  &__call {
    flex: 0 0 20px;
    //margin: 0 10px 0 0;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: url('assets/img/phone-1.svg');
      background-size: cover;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 0;
    }

    &:hover {
      &:before {
        background-position: 28px 0;
      }
    }
  }

  &__name {
    flex: 1 1 auto;
    font-weight: 500;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    line-break: strict;
  }

  &__new-client {
    flex: 0 0 20px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: url('assets/img/new-client.svg');
      background-size: cover;
      width: 8px;
      height: 8px;
      top: 6px;
      right: 0;
    }
  }

  // &__new-message {
  //   flex: 0 0 20px;
  //   position: relative;

  //   &:before {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     background: url('assets/img/new-message.svg');
  //     background-size: cover;
  //     width: 14px;
  //     height: 11px;
  //     top: 5px;
  //     right: 0;
  //   }
  // }

  // &__new-messages {
  //   background: #ff5151;
  //   font-size: 10px;
  //   width: 16px;
  //   height: 16px;
  //   text-align: center;
  //   line-height: 16px;
  //   border-radius: 50%;
  //   color: #fff;
  // }

  &__missed-call {
    flex: 0 0 16px;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 0 0 5px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: url('assets/img/missed-call.svg');
      background-size: cover;
      width: 14px;
      height: 14px;
      top: 1px;
      right: 0;
      left: 0;
    }
  }
}

.deal-list-tags {
  padding: 0 0 0 20px;
  margin: 5px 0 0;

  &__item {
    background: #ccc;
    border-radius: 3px;
    margin: 0 5px 5px 0;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 4px 7px 5px;
  }
}