<template>
  <div class="transfer-modal">
    <div class="transfer-modal__main">
      <div class="transfer-modal__box">
        <div
          class="transfer-modal-box-1"
          style="width: 540px"
        >
          <div class="transfer-modal-box-1__head">
            <div class="transfer-modal-box-1__title">
              {{ title }}
            </div>
            <div
              class="transfer-modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="transfer-modal-box-1__body">
            <div
              class="loader"
              :class="[loader ? '--show' : '']"
            >
              <div class="loader__spinner" />
            </div>
            <form
              class="form"
              @submit.prevent="massiveActionDeal"
            >
              <div
                v-if="title !== 'Продажа'"
                class="form-group"
              >
                <DropdownComponent
                  :options="options"
                  :placeholder="'Выберите причину'"
                  class="select"
                  @input="setReasonId($event)"
                />
                <input
                  v-model="comment"
                  class="comment-input"
                  placeholder="Введите комментарий..."
                >
                <div
                  v-if="error.length"
                  class="invalid-feedback"
                >
                  {{ error }}
                </div>
              </div>
              <div
                v-else
                class="sale-message"
              >
                Массово закрывая сделки продажей, вы подтверждаете, что все данные по сделкам заполнены верно. Корректность статистики зависит от сохраненных данных.
              </div>
              <div class="transfer-modal-box-1__buttons">
                <div @click="close">
                  Отмена
                </div>
                <button
                  type="submit"
                  class="transferBtn"
                  :disabled="disable"
                >
                  Подтвердить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import DropdownComponent from '@/components/Dropdown/CustomDropDownComponent.vue';
  
export default {
	components: {
		DropdownComponent,
	},
	data() {
		return {
			title: '',
			comment: '',
			dealsToClose: [],
			options: [],
			disable: false,
			loader: false,
			selectedReasonId: 0,
			error: '',
		};
	},
	computed: {
		...mapState({
			manager: state => state.manager,
			data: state => state.modal.massiveCloseDeal.data,
			massiveCloseDealArray: state => state.massiveDealsClosing.dealsArray
		}),
		id: function () {
			return parseInt(this.$route.params.funnelId);
		},
		funnel: {
			get() {
				return this.getFunnelById(this.id);
			}
		},
		...mapGetters({
			getFunnelById: 'funnel/getFunnelById2',
		}),
		message() {
			const message = [];
	
			this.dealsToClose.forEach((deal) => {
				if (this.title === 'Нецелевой') {
					message.push({
						statusId: 3,
						roleId: this.manager.roleId,
						id: deal.dealId,
						comment: this.comment === '' ? null : this.comment,
						rejectId: this.selectedReasonId === 0 ? null : this.selectedReasonId,
						managerId: this.manager.id
					});
				} else if (this.title === 'Продажа') {
					message.push(
						{
							statusId: 5,
							id: deal.dealId,
							roleId: this.manager.roleId,
							comment: null,
							rejectId: null,
							managerId: this.manager.id,
					    }
					);
				} else {
					message.push({
						statusId: 4,
						id: deal.dealId,
						roleId: this.manager.roleId,
						comment: this.comment === '' ? null : this.comment,
						rejectId: this.selectedReasonId === 0 ? null : this.selectedReasonId,
						managerId: this.manager.id,
					});
				}
			});
	
			return message;
		},
		resultText() {
			return this.title === 'Продажа' ? 'была оформлена продажа' : 'был оформлен отказ';
		}
	},
	created() {
		this.initData();
	},
	methods: {
		...mapActions({
			setModalData: 'modal/setModalData',
			openModal: 'modal/openModal',
			closeModal: 'modal/closeModal',
		}),
		...mapMutations({
			cleanMassiveDealsClosing: 'massiveDealsClosing/cleanDealsArray',
			changeCloseDealsMode: 'massiveDealsClosing/changeCloseDealsMode',
			changeSaleDealsMode: 'massiveDealsClosing/changeSaleDealsMode',
		}),
		setReasonId(value) {
			if (value && value.id) {
				this.selectedReasonId = value.id; 
			}
		},
		initData() {
			this.title = this.data.title;
			this.dealsToClose = this.data.dealsToClose;
			this.options = this.data.options;
		},
		editField() {
			if(this.selectedReasonId == 0) {
				this.error = 'Выберите причину';
			} else {
				this.error = '';
			}
		},
		close() {
			this.closeModal('massiveCloseDeal');
		},
		massiveActionDeal() {
			if (this.selectedReasonId !== 0 || this.title === 'Продажа') {
				this.$socket.emit('massiveActionDeal', 
					{
					    deals: this.message,
						managerId: this.manager.manager_id,
						funnelId: this.funnel.id,
				    }, 
					(response) => {
						if (response.result === 'succes') {
							this.setModalData({
								modal: 'massiveTransferResult',
			            	data: {
									text: `По ${response.succesActions} сделке(-ам) ${this.resultText}`,
			            		result: 'success'
			            	},
			            });
			            this.openModal('massiveTransferResult');
							this.cleanMassiveDealsClosing();
							this.changeCloseDealsMode(false);
							this.changeSaleDealsMode(false);
						} else {
							this.closeModal('massiveCloseDeal');
							this.setModalData({
								modal: 'massiveTransferResult',
			            	data: {
									text: `По ${response.succesActions} сделке(-ам) ${this.resultText}, по ${response.failedActions} сделке(-ам) была ошибка`,
			            		result: 'fail'
			            	},
			            });
			            this.openModal('massiveTransferResult');
							this.cleanMassiveDealsClosing();
							this.changeCloseDealsMode(false);
							this.changeSaleDealsMode(false);
						}
					}
				);
			}
		}
	}
};
</script>

<style scoped>
.comment-input {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    border: 1px solid #A6A6A6;
    border-radius: 6px;
    padding: 0px 15px;
}

.sale-message {
	margin-bottom: 15px;
}

</style>