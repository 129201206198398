
.profile__main {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
}

.profile__main-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.profile-deals-buttons {
    padding: 90px 0;
    gap: 50px;
    .btn {
        padding: 8px 15px;
        border-radius: 5px;
    }

    .description {
        color: #505162;
        width: 250px;
        text-align: center;
    }
}

.profile-deals-btn-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert-warning {
    padding: 8px 15px;
    border: none;
    background: #f4dea4;
    color: #17171c;
}

.no-access {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 24px;
    color: #a8a8a8;
}
