<template>
  <div
    v-if="open"
    ref="dialog"
    class="overflow"
  >
    <dialog
      class="dialog"
      :style="dialogStyles"
    >
      <slot />
      <img
        class="close"
        src="../../../assets/close-dialog-icon.svg"
        alt=""
        loading="lazy"
        draggable="false"
        @click="closeDialog"
      >
    </dialog>
  </div>
</template>

<script>
export default {
	props: {
		open: {
			type: Boolean,
			required: false,
			default: false,
		},
		dialogStyles: {
			type: String,
			required: false,
			default: ''
		}
	},

	data() {
		return {
			resizeState: false,
		};
	},

	methods: {
		closeDialog() {
			this.$emit('close-dialog', false);
		},
	},
};
</script>

<style scoped>
    .overflow {
        background: rgba(0, 0, 0, .5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100dvh;
        overflow-y: auto;
        animation-name: fadeInOverlay;
        animation-duration: 0.3s;
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialog {
        position: relative;
        display: flex;
        justify-content: normal;
        gap: 1rem;
        margin: 10% auto;
        border: 1px solid #9D9EAF;
        min-height: 100px;
        z-index: 102;
        background-color: #FFFFFF;
        border-radius: 24px;
        padding: 28px 24px;
        user-select: none;
        animation-name: fadeInDialog;
        animation-duration: 0.3s;
    }

    .close {
        position: absolute;
        top: 30px;
        right: 16px;
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
    }

    @keyframes fadeInDialog {
        from {
            transform: scale(0);
            opacity: 0;
        }
        to {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes fadeOutDialog {
        from {
            transform: scale(1);
            opacity: 1;
        }
        to {
            transform: scale(0);
            opacity: 0;
        }
    }

    @keyframes fadeInOverlay {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
</style>
