.deal-union-block {
    background: #f1f1f4;
    padding: 15px 20px 15px;

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: #FFB74D;
        background: #efc2eb;
        padding: 15px;
        border-radius: 8px;
    }

    &__info {
        //color: #af570c;
        font-weight: 500;
    }

    &__buttons {
        flex: 0 0 auto;

        & button {
            &.--red-11 {
                margin: 0 0 0 10px;
                color: #dc3545;
                background-color: transparent;
                border: none;
                font-weight: 500;

                &:hover {
                    color: #dc3545;
                    background-color: transparent;
                    border: none;
                    opacity: 0.7;
                }
            }

            &.--green-11 {
                margin: 0 0 0 10px;
                color: #28a745;
                background: transparent;
                border: none;
                font-weight: 500;

                &:hover {
                    color: #28a745;
                    background-color: transparent;
                    border-color: none;
                    opacity: 0.7;
                }
            }
        }
    }
}

.deal-union-block + .profile {
    & > .profile__wrapper > .profile__box {
        margin: 0 20px 15px;
    }
}
