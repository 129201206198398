
.reject_box {
    padding-top: 20px;
}
.profile__title {
    font-weight: 600;
    font-size: 20px;
}

.profile__close {
    cursor: pointer;
}

.btn {
    padding: 10px 20px;
    border-radius: 5px;
}

.btn-secondary {
    background: transparent;
    border: none;
    color: #1c1c1c;

    &:hover {
        color: #1c1c1c;
        opacity: 0.8;
        background: transparent;
        border: none;
    }
}

.profile-deals-buttons {
    margin-top: 40px;
}
