.communication {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fcf9fc;
  //overflow-y: hidden;

  &__messages {
    flex: 1 0;
    overflow: hidden;
  }

  &__form {
    flex: 0 0 auto;
  }
}
