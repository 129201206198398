.header {
	height: 50px;
	background: #B9439D;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;

	&__main {
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 2px;
			left: 0;
			width: 100%;
			background: #B9439D;
			z-index: -1;
		}
	}

	&__utils {
		padding: 0 18px;
		display: flex;
		gap: 16px;
		align-items: center;
		border-right: 1px solid #4A1C3F;
		border-left: 1px solid #4A1C3F;
		margin-right: 16px;

		&-select {
			background: inherit;
			border: none;
			color: #FFFFFF;

			option {
				color: red;
				border: none;
				background: inherit;
			}
		}
	}

	&__logo {
		background: url('assets/logo-rectangle-white.svg') no-repeat center;
		width: 209px;
		height: 50px;
		flex: 0 0 auto;
	}

	&__menu {
		margin: 0 0 0 20px;
		flex: 1 0 auto;
	}
	&__title{
		display: none;
	}
}

.submenu {
	box-shadow: 0 1px 2px 0 rgba(28,24,25, .15);
	background: linear-gradient(-90deg, #F55CA6 0%, #b941af 100%);
	position: absolute;
	left: 0;
	top: 100%;
	display: none;
	min-width: 184.2px;
	list-style: none;
	margin: 0;
	padding: 0;

	&__a {
		display: block;
		font-weight: 500;
		color: #FFFFFF;
		padding: 14px 15px 15px;
		text-decoration: none;
		transition: .2s;
		position: relative;
		transition: .2s;

		&:hover, &.--active {
			background: rgba(255, 255, 255, .2);
		}
	}
}

.menu{
	margin: 0;
	padding: 0;
	width: fit-content;
	&__li {
		float: left;
		list-style: none;
		width: fit-content;
		&--relative {
			position: relative;
		}

		&:hover {
			.menu__submenu {
				display: block;
			}
		}
	}

	&__a {
		display: block;
		font-weight: 500;
		color: #FFFFFF;
		text-decoration: none;
		position: relative;
		transition: 0.2s;
		border-bottom: 2px solid transparent;

		padding: 14px 15px 13px;

		&:has(.deals-count) {
			padding: 14px 15px 9px;
		}

		.title-wrapper {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		& span:not(.deals-count) {
			position: absolute;
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			top: 6px;
			right: 4px;
			font-size: 10px;
			line-height: 14px;
			text-align: center;
		}

		.deals-count {
			display: flex;
			align-items: center;
			gap: 2px;

			color: #1C1C1C;
			padding: 2px 8px;
			border-radius: 12px;
			background-color: #FCD34D;
			font-size: 12px;
		}

		&:hover {
			border-bottom-color: #4A1C3F;
			background: rgba(255, 255, 255, .2);
		}

		&.--active {
			border-bottom: 2px solid #4A1C3F;
			background: rgba(255, 255, 255, .2);
		}
	}

	&__a-notification-badge {
		top: 2px;
		right: 15px;
	}

	&:has(.deals-count) .menu__a-notification-badge {
		top: 2px;
		right: 65px;
	}
}

// TODO: Move as a ux generic component
.circle-badge {
	box-sizing: content-box;
    min-width: 7px;
    font-size: 10px;
    font-style: normal;
    border-radius: 8px;
    font-weight: 900;
    line-height: 7px;
    padding: 3px;
	padding-right: 4px;
    letter-spacing: -.24px;
    position: absolute;
    text-align: center;
	background: #EF5350;
	&--modal{
		right: -25px;
	}
}

.header__item {
	position: relative;
	margin-right: 5px;

	&-name {
		color: #fff;
		padding: 15px 10px 12px 10px;
		position: relative;
		cursor: pointer;
		transition: .2s;
		border-bottom: 2px solid transparent;

		&:hover {
			border-bottom-color: #4A1C3F;
			opacity: 0.8;
			background: rgba(255, 255, 255, .2);
		}
	}

	&-notification {
		color: #fff;
		position: relative;
		cursor: pointer;
		transition: .2s;
		width: 30px;
		height: 50px;
		border-bottom: 2px solid transparent;

		&:before {
			content: '';
			display: block;
			background: url('./assets/v2/notification.svg') no-repeat left;
			background-size: cover;
			width: 16px;
			height: 18px;
			position: absolute;
			top: 16px;
			left: 7px;
			transition: 0.2s;
		}

		&:hover {
			border-bottom-color: #4A1C3F;
			opacity: 0.8;
			background: rgba(255, 255, 255, .2);
		}

		&-count {
			position: absolute;
			top: 9px;
			right: -3px;
			width: 16px;
			height: 16px;
			background-color: #E4AB1B;
			color: #17171C;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 10px;
			font-weight: bold;
			box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		}
	}

	&-exit {
		color: #fff;
		position: relative;
		cursor: pointer;
		transition: .2s;
		width: 30px;
		height: 50px;
		border-bottom: 2px solid transparent;

		&:before {
			content: '';
			display: block;
			background: url('./assets/v2/exit.svg') no-repeat left;
			background-size: cover;
			width: 20px;
			height: 20px;
			position: absolute;
			top: 16px;
			left: 5px;
			transition: 0.2s;
		}

		&:hover {
			border-bottom-color: #4A1C3F;
			opacity: 0.8;
			background: rgba(255, 255, 255, .2);
		}
	}
}

.mobile {
	.header{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		&__menu-icon {
			flex: 0 0 30px;
			height: 30px;
			margin: 10px 0 0;
			order: 1;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0px;
				width: 30px;
				height: 30px;
				background: url('assets/img/open-menu.svg') no-repeat center;
			}
		}

		&__profile-icon {
			flex: 0 0 30px;
			height: 30px;
			order: 4;
			
			margin: 10px 0 0;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 4px;
				left: 0px;
				width: 30px;
				height: 22px;
				background: url('assets/img/logout.svg') no-repeat center;
			}
		}

		&__title {
			order: 2;
			display: block;
			color: #fff;
			height: 50px;
			line-height: 50px;
			font-size: 16px;
			flex-grow: 1;
			text-align: center;
		}
		&__logo,
		&__menu,
		&__item{
			display: none !important;
		}

		.reciever-selector{
			order: 3;
			margin-right: 40px;
			
		}
		&__main{
			padding: 0 10px;

		}
	}
	
}
