
.managers-queue-description {
	margin-bottom: 7px;
	font-weight: 500;
}

.checkbox__container {
    display: block;
    position: relative;
	width: 70px;
    padding-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkbox__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

	.checkbox__label {
		font-size: 14px;
	}

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;
        border-radius: 3px;
    }

    .checkbox__input:checked ~ .checkmark {
        background-color: #ccc;
    }

    .checkbox__input:checked~ .checkmark {
        background-color: #10B981;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .checkbox__input:checked + .checkmark:after {
        display: block;
    }
    
    .checkbox__input:checked + .checkmark:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
