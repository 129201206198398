
.delete_template-icon {
    width: 12px;
    height: 12px;
    background: url('../../../../assets/close-grey.svg') no-repeat;
    user-select: none;
    cursor: pointer;
    margin-right: 10px;
}

.msg-form__icon {
    color: #f93232;
    position: absolute;
    top: 12px;
    left: 12px;
}

.msg-form__file-wrap {
	.msg-form__file-wrap-tooltip {
        display: none;
		left: -450px;

		&:after,
		&:before {
			position: absolute;
			pointer-events: none;
			content: '';
			height: 0;
			width: 0;
			top: 100%;
			left: 456px;
		}

    }

    &:hover {
        .msg-form__file-wrap-tooltip {
            display: block;
        }
    }
}

.file-wrap-tooltip {
    display: none;
    border-radius: 5px;
    background: #fff5f5;
    border: 1px solid #ffd8d8;
    box-shadow: 5px 20px 16px rgba(142, 141, 208, 0.12);
    position: absolute;
    width: 506px;
    bottom: calc(100% + 10px);
    left: -450px;
    padding: 0 45px 40px 45px;
    z-index: 10000;
    font-size: 12px;
    line-height: 17px;

    ol {
        padding: 0;
        padding-left: 15px;
        margin: 0;
    }

    &:after,
    &:before {
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        top: 100%;
        left: 456px;
    }

    &:before {
        border-width: 11px;
        border-top-color: #ffd8d8;
    }

    &:after {
        border-width: 10px;
        border-top-color: #fff5f5;
    }
}

.message-form__templates {
    font-size: 12px;
    font-weight: 400;
    padding: 0 40px 30px 56px;
    margin-top: -7px;
}

.message-form__template-button {
    font-size: 10px;
    font-weight: 500;
    padding: 5px 8px;
    appearance: none;
    background: white;
    border: 1px solid gray;
    border-radius: 5px;
    margin-right: 8px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: white;
        background: #a23a99;
    }
}

.anim-rotation {
    animation-name: rotation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.template-not-loaded {
    padding: 10px;
    text-align: center;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.max-length {
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
}

.msg-form__file-wrap {
    &:hover {
        .msg-form__file-wrap-tooltip {
            display: block;
        }
    }
}

.file-wrap-tooltip {
    display: none;
    border-radius: 5px;
    background: #fff5f5;
    border: 1px solid #ffd8d8;
    box-shadow: 5px 20px 16px rgba(142, 141, 208, 0.12);
    position: absolute;
    width: 506px;
    bottom: calc(100% + 10px);
    left: 0;
    padding: 0 45px 40px 45px;
    z-index: 10000;
    font-size: 12px;
    line-height: 17px;

    ol {
        padding: 0;
        padding-left: 15px;
        margin: 0;
    }

    &:after,
    &:before {
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        top: 100%;
        left: 8px;
    }

    &:before {
        border-width: 11px;
        border-top-color: #ffd8d8;
    }

    &:after {
        border-width: 10px;
        border-top-color: #fff5f5;
    }
}

.template-loading {
	display: flex;
	justify-content: center;
	padding: 10px 0;
}
