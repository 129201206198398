
.mb-10 {
	margin-bottom: 10px;
}

.checkbox {
	margin: 0 10px 0 0;
}

.label-row {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.error {
	color: red;
}
