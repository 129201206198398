<template>
  <div class="modal">
    <div class="modal__main">
      <div class="modal__box">
        <div class="modal-box-1">
          <div class="modal-box-1__head">
            <div class="title">
              <img
                src="../../assets/info.svg"
                alt=""
              >
              <div class="modal__title" />
            </div>
            <div
              class="modal-box-1__close"
              @click="close"
            />
          </div>
          <div class="modal-box-1__body">
            <div class="row">
              <label class="input-wrap">
                <span>Шаблоны</span>
                <SelectComponent
                  v-model="selectedTemplateId"
                  :options="templates"
                />
              </label>
              <label
                v-if="!week"
                class="input-wrap"
              >
                <span>Кол-во недель</span>
                <input
                  v-model="amountWeeks"
                  class="form-control"
                >
              </label>
            </div>
            <ScheduleTemplateForm
              v-slot="slotProps"
              :name="template.name"
              :schedule_id="scheduleId"
              :template="template"
              :period="period"
              :type="type"
              @on-accept="handleAccept"
            >
              <button
                class="btn"
                @click="close"
              >
                Отменить
              </button>
              <button
                class="btn"
                :disabled="slotProps.disableAccept || isSubmitting"
                @click="slotProps.accept"
              >
                {{ week ? 'Сохранить изменения' : 'Добавить' }}
              </button>
            </ScheduleTemplateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onUpdated, ref, watch } from 'vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import ScheduleTemplateForm from '@/components/ScheduleConstructor/ScheduleTemplates/ScheduleTemplateForm';
import SelectComponent from '@/components/Select/SelectComponent.vue';
import { store } from '@/store';
import DayForm from '@/components/ScheduleConstructor/DayForm.vue';
import emitter from '@/services/emitter';
import { socketConnection } from '@/socketConnection';
import moment from 'moment/moment';
import objKeys from '@/utils/ObjKeys';

export default {
	components: {
		DayForm,
		DatePicker,
		SelectComponent,
		ScheduleTemplateForm,
	},
	setup() {
		const selectedTemplateId = ref(null);
		const amountWeeks = ref(1);
		const template = ref({});
		const scheduleId = computed(() => store.state.modal.addScheduleWeekModal.data.schedule);
		const workerId = computed(() => store.state.modal.addScheduleWeekModal.data.workerId);
		const lastDate = computed(() => store.state.modal.addScheduleWeekModal.data.lastDate);
		const week = computed(() => store.state.modal.addScheduleWeekModal.data.week);
		const tableModel = computed(() => store.state.scheduleConstructor.tableModel);
		const templateId = computed(() => store.state.modal.addScheduleWeekModal.data.templateId);
		const overlaps = ref([]);
		const type = ref('');
		const isSubmitting = ref(false);
		const setDate = (date, time) => {
			return moment(moment(date).format('YYYY-MM-DD') + ' ' + moment(time).format('HH:mm'));
		};

		const templates = computed(() => {
			return store.state.scheduleConstructor.templates.data.filter(item => item.schedule_id === scheduleId.value).map(item => ({
				id: item.id,
				label: item.name,
				value: item.id,
				...item,
			}));
		});

		const getDayName = (date) => {
			const name = moment(date).format('dddd');
			return name[0].toUpperCase() + name.slice(1);
		};

		if (templates.value.length && !week.value) {
			selectedTemplateId.value = templates.value[0].id;
			template.value = templates.value.find(item => item.id === selectedTemplateId.value);
			type.value = 'addSchedule';
		} else {
			selectedTemplateId.value = templateId.value;
			type.value = 'changeSchedule';


			const obj = JSON.parse(JSON.stringify({ ...templates.value.find(item => item.id === templateId.value) }));
			for (let i = 0; i < tableModel.value?.length; i++) {
				if (tableModel.value[i][8].week === week.value) {
					for (let j = 1; j < 8; j++) {
						const day = tableModel.value[i][j];
						obj.days[getDayName(day.date)].timeRange = day.timeRange.map(item => ({
							...item,
							start: new Date(setDate(day.date, item.start)),
							end: new Date(setDate(day.date, item.end)),

						}));
						if (day.customValue) {
							obj.days[getDayName(day.date)].dayOff = day.dayOff;
							obj.days[getDayName(day.date)].isDirty = true;
						}
					}
					break;
				}
			}
			template.value = obj;
		}
		const schedules = computed(() => store.state.schedule.list.map(({ name, id, ...item }) => {
			return {
				id,
				label: name,
				value: id,
				...item,
			};
		}));

		const period = computed(() => schedules.value.find(item => item.id === scheduleId.value)?.period);


		watch(() => selectedTemplateId.value, () => {
			const data = JSON.parse(JSON.stringify(templates.value.find(item => item.id === selectedTemplateId.value)));
			if (!week.value) {
				template.value = data;
			} else {
				for (let i = 0; i < tableModel.value?.length; i++) {
					if (tableModel.value[i][8].week === week.value) {
						for (let j = 1; j < 8; j++) {
							const day = tableModel.value[i][j];
							data.days[getDayName(day.date)].timeRange = data.days[getDayName(day.date)].timeRange.map(item => ({
								...item,
								start: new Date(setDate(day.date, item.start)),
								end: new Date(setDate(day.date, item.end)),
							}));
						}
						break;
					}
				}
				template.value = data;
			}
		},
		);


		const handleAccept = async () => {
			isSubmitting.value = true;
			if (week.value) {
				const obj = [[]];
				for (let i = 0; i < tableModel.value?.length; i++) {
					if (tableModel.value[i][8].week === week.value) {
						for (let j = 1; j < 8; j++) {
							const day = { ...tableModel.value[i][j] };
							day.dayOff = template.value.days[getDayName(day.date)].dayOff;
							day.timeRange = template.value.days[getDayName(day.date)].timeRange;
							day.customValue = day.customValue || template.value.days[getDayName(day.date)].isDirty;
							day.template_id = template.value.id;
							obj[0].push(day);
						}
						break;
					}
				}

				socketConnection.io.emit('checkIsChangeWorkerSchedule', {
					schedule_id: scheduleId.value,
					weeks: obj,
					worker_id: workerId.value,
				}, (checkResponse) => {
					if (checkResponse.type === 'overlaps') {

						overlaps.value = checkResponse.overlaps;

						store.dispatch('modal/setModalData', {
							modal: 'eventsOverlap',
							data: checkResponse.overlaps,
						});
						store.dispatch('modal/openModal', 'eventsOverlap');
					} else {
						socketConnection.io.emit('createWorkerScheduleGrid', {
							schedule_id: scheduleId.value,
							weeks: obj,
							worker_id: workerId.value,
						}, r => {
							emitter.emit('change-schedule');
							close();
						});
					}
				});
			} else {
				socketConnection.io.emit('createWorkerScheduleGrid', {
					schedule_id: scheduleId.value,
					worker_id: workerId.value,
					weeks: amountWeeks.value,
					template: template.value,
					startDate: lastDate.value,
				}, () => {
					emitter.emit('change-schedule');
					close();
				});
			}


		};

		const close = () => {
			store.dispatch('modal/closeModal', 'addScheduleWeekModal');
			store.dispatch('modal/setModalData', {
				modal: 'addScheduleWeekModal',
				data: {},
			});
		};

		return {
			isSubmitting,
			name,
			week,
			type,
			close,
			period,
			template,
			templates,
			scheduleId,
			amountWeeks,
			handleAccept,
			selectedTemplateId,
		};
	},
};
</script>
<style>
.modal__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #212121;
}

.title {
    display: flex;
    column-gap: 10px;
}
</style>
<style lang='scss' scoped>
.modal-box-1 {
    padding: 10px;

}


.modal-box-1__body {
    padding: 25px 32px 31px 55px;
}

.row {
    display: flex;
    align-items: center;
    column-gap: 55px;
    font-size: 13px;
    color: #262626;

    label {
        font-weight: 400;
    }
}

.input-wrap {
    max-width: 140px;
}

.time-wrap {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.input {
    width: 51px;
    height: 29px;
    padding: 10px;
    font-size: 12px;
    color: #3E3E3E;
}
</style>
