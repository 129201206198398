
.card-budget {
    &__wrapper {
        margin: 0 auto 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
        align-items: center;
        max-width: 400px;
        border-radius: 16px;
    }

    &__section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: white;
        padding: 18px 16px;
    }

    &-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #1c1c1c;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }

        &__buttons {
            display: flex;
            gap: 8px;

            button {
                border: none;
                background: none;
                cursor: pointer;
                color: #b9439d;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                padding: 0;
                margin: 0;
            }
        }
    }

    &-value {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &__budget {
        border-radius: 16px 16px 4px 4px;
    }

    &__incomes {
        border-radius: 4px 4px 4px 4px;
    }

    &__balance {
        border-radius: 4px 4px 16px 16px;
    }
}
