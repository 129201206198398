
.alert {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #9D9EAF;
    padding: 24px;
    height: 123px;
    width: 457px;
    border-radius: 24px;
    background: white;
    color: #10B981;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;

    &__content {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }
}
