
.cancel{
    border: none;
    background: none;
    padding: 10px;
    cursor: pointer;
}

.confirm{
    background: #b9439d;
    border: none;
    color: white;
    border-radius: 4px;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: #b9439d;
        border-radius: 4px;
    }
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
}
