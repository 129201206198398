<template>
  <div
    class="toggle"
    :class="{ 'toggle--checked': value}"
    @click="toggle"
  >
    <div class="toggle__thumb" />
  </div>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		toggle() {
			this.$emit('input', !this.value);
		},
	},
};
</script>

<style scoped>
.toggle {
    width: 40px;
    height: 24px;
    background: #e6e6e6;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s;
}

.toggle--checked {
    background: #4cd964;
}

.toggle__thumb {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.3s;
}

.toggle--checked .toggle__thumb {
    left: 22px;
}
</style>
