
.curtain {
	/*background: rgba(0, 0, 0, .5);*/
	position: fixed;
	top: -1px;
	right: 0;
	bottom: -1px;
	right: 0;
	z-index: 50;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 20px;

	&__main {
		background: #fff;
		position: fixed;
		top: 51px;
		right: 0;
		bottom: 0;
		width: 320px;
		border-left: 1px solid #ccc;
		z-index: 100;
	}

	&__head {
		border-bottom: 1px solid #E5E5E5;
		color: #5b5b5b;
		margin: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		font-size: 18px;
		line-height: 50px;
		padding: 0 30px 0 0;
	}

	&__close {
		width: 16px;
		height: 50px;
		position: relative;
		cursor: pointer;
		margin: 0 0 0 15px;

		&:before {
			position: absolute;
			top: 19px;
			display: block;
			content: '';
			width: 14px;
			height: 14px;
			background: url('./../../assets/close-grey.svg') no-repeat center;
		}
	}

	&__body {
		padding: 10px 20px;
		height: calc(100% - 51px);
		overflow: auto;
	}

	&__desc {
		padding: 0 0 10px;
	}
}

.filters {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 10px 0;
	flex-direction: column;

	&__item {
		user-select: none;
		overflow: hidden;
		position: relative;
		flex: 0 1 auto;
		margin: 0 10px 10px 0;
		display: flex;
	}

	&__input {
		position: absolute;
		left: -9999px;
	}

	&__label {
		display: flex;
		cursor: pointer;
		background: #FFFFFF;
		border: 2px solid #d65ecb;
		border-radius: 3px;
		padding: 0px 5px 1px;
		font-weight: 500;
		color: #333333;
		margin: 0;
		transition: background 1s ease;
	}
}

.filters__input:checked + .filters__label {
	background: linear-gradient(184.71deg, #d65ecb 0%, #A23A99 100%);
	padding: 2px 7px 3px;;
	border: 0px solid #A23A99;
	color: #fff;
}

.filters__input:disabled + .filters__label {
	opacity: .3;
	cursor: default;
	border: 2px solid #ccc;
}

.calls {

	&__item {
		margin: 0 0 10px 0;
	}

	&__desc {
		font-size: 12px;
		margin: 3px 0 2px;
		color: rgba(0, 0, 0, 0.5);
	}

	&__hr {
		border-bottom: 1px solid #e8e8e8;
	}
}

.deal2 {

	&__item {
		background: #fff;
		border-radius: 5px;
		margin: 0 0 10px;
		//cursor: pointer;
		border: 1px solid #eaeaea;
	}

	&__wrap {
		padding: 8px 10px;
	}

	&__name {
		margin: 0 0 2px;
		font-weight: 500;
		line-height: 1.2;
	}

	&__description {
		font-weight: normal;
		margin: 0 0 2px;
	}

	&__manager {
		font-weight: normal;
		margin: 0 0 2px;
		font-size: 12px;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;

		& .btn {
			padding: 6px 10px;
		}
	}

	&__button {
		font-weight: 500;
		display: inline-block;
		color: #A23A99;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		background: #e8e8e8;
		border: 1px solid #e8e8e8;
		border-radius: 2px;
		padding: 6px 10px 6px;
		line-height: 1;
		cursor: pointer;
		transition: .1s;
		width: 100%;

		&:hover {
			background-color: #A23A99;
			border: 1px solid #A23A99;
			color: #fff;
		}
	}
}
