
.message__missed-call-wrapper {
    display: flex;
    align-items: center;
}

.tooltip-icon {
    width: 14px;
    height: 14px;
}

.tooltip-content {
    color: #575757;

    div {
        span {
            color: #1C1C1C;
        }
    }
}
