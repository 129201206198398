.filter {
	padding: 0 0 20px;

	&__container{
		position: relative;
		width: max-content;
	}
	&-items{
		display: flex;
		align-items: center;
	}
	&__desc{
		margin-right: 5px;
	}
	&__wrapper {
		display: flex;
		overflow: auto;
		align-items: center;
		&.range{
			overflow: visible;
		}
	}

	&__item {
		flex: 0 0 auto;
		margin: 0 10px 0 0;
	}

	&__global {
		cursor: pointer;
		padding: 5px 0 0 10px;
		font-weight: 500;
	}

	&__separator {
		margin: 5px 15px 5px 5px;
		width: 2px;
		height: 30px;
		background: #f0f0f0;
	}
}

.search-type{
	position: absolute;
	top:0;
	left:0;
	z-index: 10;
	color: #767676;
	font-size: 9px;
	font-weight: 700;
	border: none;
	text-transform: uppercase;

	&__wrapper{
		display: flex;
		width: 95px;
		padding-left: 12px;
		padding-top: 13px;
		padding-right: 11px;
		align-items: center;
		justify-content: space-between;
		&:hover{
			cursor: url('assets/cursor.svg'), auto;
		}
	}

	&__arrow{
		width: 8px;
		height: 4px;
		mask-image: url('assets/search-arrow.svg');
		mask-position: center center;
		background: #575757;

		background-size: cover;
		&--open{
			background: #A23A9B;
		}
	}
	&__selected{
		
		line-height: 9px;
	}
	
	&__list{
		position: absolute;
		z-index: 1000;
		right: 245px;
		top: 34px;
		width: 95px;
		padding: 13px 0px 8px 10px;
		margin: 0;
		background: #fafafa;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
	}
	&-list__item
	{
		list-style: none;
	}

	&__label{
		font-weight: inherit;
		color: #767676;
		&:hover{
			color: #A23A9B;
			cursor: url('assets/cursor.svg'), auto;
		}
	}
	&__input{
		display: none;
	}

	&__input:checked + label{
		color: black;
	}
}




.filter-item {
	user-select: none;
	// overflow: hidden;
	position: relative;
	.search-type-select{
		display: none;
	}
	&__input {
		position: absolute;
		left: -9999px;
	}

	&__input:disabled + &__label {
		opacity: .3;
		cursor: default;
		border: 2px solid #ccc;
	}
	&__icon{
		width: 16px;
		height: 16px;
	}
	&__label {
		display: flex;
		cursor: pointer;
		background: #FFFFFF;
		//border: 2px solid #ECD8EB;
		//border: 2px solid #d65ecb;
		border: 1px solid #d6d8db;
		//box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
		border-radius: 3px;
		padding: 4px 10px 5px;
		//padding: 3px 15px 4px;
		font-weight: 500;
		align-items: center;
		justify-content: center;
		color: #333333;
		margin: 0;
	}

	&__search {

		display: flex;
		background: #FFFFFF;
		border: 1px solid #d6d8db;
		border-radius: 3px;
		padding: 7px 26px 7px 116px;
		font-weight: 500;
		color: #333333;
		height: 37px;
		position: relative;
		width: 320px;
		height: 32px;
	}

	&__search-icon {
		position: absolute;
		width: 24px;
		height: 24px;
		background: url('assets/img/search.svg') no-repeat center center;
		background-size: cover;
		top: 3px;
		right: 10px;
		z-index: 2;
	}

	&__search-clear {
		position: absolute;
		width: 14px;
		height: 14px;
		background: url('assets/close-grey.svg') no-repeat center;
		background-size: 75%;
		top: 8px;
		right: 12px;
		z-index: 2;
		cursor: pointer;
	}

	&__input:checked + &__label {
		background: $color-main-purple;
		padding: 5px 12px 6px;
		border: 0px solid $color-main-purple;
		color: #fff;
	}

	&__select {
		display: flex;
		background: #FFFFFF;
		border: 2px solid #d65ecb;
		border-radius: 3px;
		padding: 6px 26px 6px 26px;
		font-weight: 500;
		color: #333333;
		position: relative;
		width: 200px;
	}
}

.filter-date-range {
	display: flex;
	background: #FFFFFF;
	border: 1px solid #d6d8db;
	border-radius: 8px;
	font-weight: 500;
	color: #333333;
	margin: 0;

	&__title {
		padding: 4px 10px 5px 10px;
	}

	&__input-wrap {
		display: flex;
		border-left: 1px solid #d6d8db;
		cursor: pointer;

		& > span {
			display: flex;
			padding: 4px 5px 5px 10px;
		}

		& input {
			border: none;
			height: 30px;
			line-height: 30px;
			width: 110px;
			cursor: pointer;

			&:focus {
				outline: none;
			}
		}
	}
}

.filter-group {
	display: flex;
	background: #FFFFFF;
	border: 2px solid #d6d8db;
	border-radius: 3px;
	font-weight: 500;
	color: #333333;
	margin: 0;

	&__title {
		padding: 4px 10px 5px 10px;
	}

	&__input-wrap {
		display: flex;
		border-left: 2px solid #d6d8db;
		cursor: pointer;

		& > span {
			display: flex;
			padding: 3px 5px 4px 10px;
		}

		& input {
			border: none;
			height: 28px;
			line-height: 28px;
			width: 110px;
			cursor: pointer;

			&:focus {
				outline: none;
			}
		}
	}
}

.filter-select {
	display: flex;
	background: #FFFFFF;
	border: 1px solid #d6d8db;
	border-radius: 0.5rem;
	//padding: 3px 0 4px;
	font-weight: 500;
	color: #333333;
	margin: 0;
	width: fit-content;

	&__title {
		padding: 4px 5px 5px 10px;
		border-right: 1px solid #d6d8db;
	}

	&__select-wrap {
		display: flex;
		cursor: pointer;
		padding: 0 5px 0 5px;

		& > select {
			cursor: pointer;
			border: none;
			padding: 4px 0 4px;
			background: white;

			&:focus {
				outline: none;
			}
		}
	}
}



@media only screen and (max-width : 980px) {
	.filter__container{
		width: unset !important;
	}
	.page__funnel{
		.filter__wrapper {
			row-gap: 10px;
			padding: 0 0 5px;
		}
		.search-type__list{
			left: 0!important;
		}
	}
	
	
	// .search-type-select{
	// 	display: block !important;
	// 	z-index: 99;
	// 	left: 12px;
	// 	top: 50%;
	// 	color: #767676;
	// 	transform: translateY(-12px);
	// 	position: absolute;
	// 	&--no-mobile{
	// 		border: none;
	// 		background: none;
	// 		transform: translateY(-9px);
	// 	}
	// 	text-transform: uppercase;
	// }
	.filter-item__label {
		white-space: nowrap;
	}
	.filter {
		padding: 0 0 10px;
	}
	.filter-item__search{
		width: 280px !important;
	}
	
}


	
