
.modal-instruction-form {
    margin: 0 auto;
    width: 100%;
    max-width: 453px;
    background: white;
    border-radius: 24px;
    border: 1px solid #9D9EAF;
    padding: 24px;

    .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
    }

    .row {
        display: flex;
        column-gap: 20px;
    }
}

