
.accordion {
    list-style: none;
    margin: 0;
    padding: 10px 0;

    &__item:last-child {
        border-bottom: none;
    }
}
