
.modal-set-working-time-instruction {
    margin: 0 auto;
    border-radius: 24px;
    background: white;
    padding: 24px;
    align-self: center;
    min-height: 654.17px;
    min-width: 576px;
    position: relative;
}

.header-modal {
    display: flex;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.weekday-label-container {
    display: flex;
    flex: 1;
}

.weekday-label {
    display: flex;
    column-gap: 16px;
    align-items: center;
}

.weekday {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
    &.disabled {
        color: #C7C7C7;
    }

    .off {
        font-size: 12px;
        line-height: 16px;
        min-height: 16px;
    }
}

.day {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    column-gap: 5px;
}

.row {
    display: flex;
    align-items: center;
    font-size: 13px;
    column-gap: 16px;
    margin-bottom: 16px;
}

.cancel-button {
    color: #1C1C1C;
    background: none;
    border: none;
    cursor: pointer;
}

.button {
    color: white;
    background: #B9439D;
    cursor: pointer;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;

    &:active {
        &:not(:disabled) {
            transform: scale(0.99);
        }
    }

    &:disabled {
        background: #E6E6E6;
    }
}

.row-button {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
}

.error {
    font-weight: 600;
    max-width: 260px;
    color: red;
}

.time-picker-wrap {
    flex: 1;
}

.checkbox-green {
    background: #0B5B0B;
}

.tab-container {
    padding-top: 8px;
}

.delete-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: #2B3F6C;
    flex-shrink: 0;
    mask-image: url(../../assets/trash.svg);
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-clear {
    padding: 8px 0;
    display: block;
    text-align: center;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    font-weight: 500;
    color: #B9439D;

    &:active {
        transform: scale(0.99);
    }

    margin-bottom: 16px;
}

.date-time-range-container {
    display: flex;
    column-gap: 16px;
}
/deep/ .vc-time-picker {
    .vc-date{
        display: none;
    }
}

