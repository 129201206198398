
.schedule-container {
    background: #fff;
    padding: 15px;
    border-radius: 6px;

    .event-label {
        margin-bottom: 12px;
    }

    &__text {
        word-break: break-all;
        color: #2f2f2f;
        line-height: 15px;
        font-weight: 400;

        &--semiBold {
            font-weight: 600;
        }
    }

    &__item {
        flex: 0 0 35%;

        &:nth-child(2) {
            flex: 0 0 60%;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }

        margin-bottom: 15px;
    }

    &__icon-row {
        display: flex;
        column-gap: 5px;
        align-items: center;
        margin-bottom: 7px;
    }
}
