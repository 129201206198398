
.referral-wrap {
    padding: 10px 10px 5px 10px;
}

.referral {
    word-break: break-word;
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    padding: 10px;
    border-radius: 5px;
    background: #beb9b9;

    .content {
        white-space: normal;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}
