@import "./../variables";


.message-form {
	position: relative;
	// border-top: 1px solid #e5e5e5;
	// background: #fff;
	&__phones {
		padding: 10px 20px 5px;
	}

	&__form {
		// border-top: 1px solid #F1F1F1;
		padding: 5px 20px 23px;
	}

	&.--overlay {
		background: #fcf9fc;
	}
}


.deal-phones {
	$root: &;
	display: flex;
	flex-wrap: wrap;
	width: 550px;
	row-gap: 15px;
	padding-right: 70px;
	overflow: hidden;
	height: 52px;
	position: relative;

	@media (max-width: 867px) {
		width: 435px;
	}
	@media (max-width: 739px) {
		width: 325px;
	}
	@media (max-width: 620px) {
		width: 550px;
	}
	@media (max-width: 548px) {
		width: 435px;
	}
	@media (max-width:440px) {
		width: 325px;
	}

	&__expander {
		cursor: pointer;
		position: absolute;
		width: 16px;
		height: 16px;
		top: 20px;
		right: 35px;
		color: #A23A99;
		transform: rotate(180deg);
	}

	&__ntf-counter {
		position: absolute;
		display: flex; 
		align-items: center; 
		justify-content: center; 
		width: 25px; 
		height: 25px; 
		border-radius: 50%; 
		background: #EF5350; 
		border: 2px solid white; 
		color: white; 
		font-size: 10px; 
		top: -30%;
		right: 5px;
		z-index: 1000;
	}

	&__item {
		flex: 0 0 110px;
		max-width: 110px;
		margin-right: 5px;
		margin-bottom: 50px;
		label{
			margin-bottom: 0;
		}

		&:hover {
			border-right: 1px solid transparent;
		}
				
		&--in-group {
			margin-right: -15px;
			
			.deal-phones__label{
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}
		&--top-connector {
			position: relative;
			
			& .deal-phones__label {
				border-top-right-radius: 20px;
				border-bottom-right-radius: 20px;
				// border-right: 1px solid #DBDBDB;

				&:hover {
					border-right: 1px solid #A23A99;
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 20px;
					height: 20px;
					top: calc(50% - 10px);
					right: -23px;
					background: url('assets/img/link.svg') no-repeat;
					background-size: cover;
				}
			}

			& .deal-phones__ntf-counter {
				right: -5px;
			}
		}
		
	}

	&__input {
		position: absolute;
		left: -9999px;
	}

	&__label {
		&--hover{
			border: 1px solid #A23A99;
		}
		display: flex;
		background: white;
		border: 1px solid #DBDBDB;
		margin-right: 10px;
		border-radius: 20px;
		padding: 2px;
		width: 100%;
		cursor: pointer;

		// &:hover {
		// 	border: 1px solid #A23A99;
		// }
	}

	&__input:checked + &__label {
		background: $color-main-purple;
		border: 1px solid $color-main-purple;

		& > .deal-phones__info-wrap {
			width: calc(100% - 30px);
		}

		& > .deal-phones__phone-wrap {
			display: block;
		}

		& > .deal-phones__inst-wrap {
			display: block;
		}

		& .deal-phones__phone-name {
			color: white;
		}

		& .deal-phones__contact-name {
			color: rgba(255, 255, 255, 0.8);
		}
	}

	&__phone-wrap {
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: white;
		flex-shrink: 0;
		margin: 0 -3px 0 0;
		position: relative;
		display: none;

		&:hover {
			background: #66BB6A;

			&:before {
				background: url('assets/img/phone-1.svg') -24px 0px;
				background-size: cover;
			}
		}

		&:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/phone-1.svg') -12px 0px;
			width: 12px;
			height: 12px;
			top: calc(50% - 6px);
			left: calc(50% - 6px);
			background-size: cover;
		}
	}

	&__phone-wrap.--disabled {
		background: gray;

		&:hover {
			background: red;

			&:before {
				background: url('assets/img/phone-1.svg') -24px 0px;
				background-size: cover;
			}
		}
	}

	&__inst-wrap {
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: white;
		flex-shrink: 0;
		margin: 0 -3px 0 0;
		position: relative;
		display: none;

		&:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/instagram-icon.svg');
			width: 16px;
			height: 16px;
			top: calc(50% - 8px);
			left: calc(50% - 8px);
			background-size: cover;
		}
	}

	&__info-wrap {
		display: flex;
		width: calc(100% - 20px);
		margin: 0 10px 0 10px;
		flex-direction: column;
	}

	&__phone-name, &__contact-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__phone-name {
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		height: 14px;

	}

	&__contact-name {
		width: 60px;
		font-weight: 600;
		font-size: 10px;
		line-height: 11.7px;
		color: rgba(121, 121, 121, 0.8);
	}

	&--expanded {
		height: auto;

		& .deal-phones__item {
			margin-bottom: 0;
		}

		& .deal-phones__expander {
			transform: rotate(0);
		}
	}
}

.msg-answer {
	padding: 0 82px 10px 46px;

	&__wrap {
		border-left: 2px solid #b6dfb8;
		padding: 0 0 0 10px;
		position: relative;

		& .--close {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 12px;
			height: 12px;
			cursor: pointer;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/close.svg');
				width: 12px;
				top: 4px;
				left: 0;
				height: 12px;
				background-size: cover;
			}
		}

		& .--name {
			display: block;
			font-size: 12px;
			font-weight: 700;
		}

		& .--text {
			display: block;
			font-size: 12px;
		}
	}
}

.msg-form {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__file-wrap {
		width: 36px;
		height: 36px;
		border-radius: 18px;

		& span {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/attachment.svg');
				width: 20px;
				top: 7px;
				left: 7px;
				height: 20px;
				background-size: cover;
			}

			&:hover:before {
				background-position: -20px 0;
			}

			& input {
				display: block;
				width: 300px;
				height: 100px;
				position: absolute;
				cursor: pointer;
				opacity: 0;
				filter: alpha(opacity=0);
				top: 0px;
				right: 0px;
			}
		}
	}

	&__msg-wrap {
		position: relative;
		// background: #fff;
		padding-left: 5px;
		border: 1px solid #A6A6A6;
		border-radius: 6px;
		flex: 1 1 auto;
		align-items: center;
		display: flex;
		&-wa-not-working{
			background-color: #FFF5F5;
			border-color: #FFD8D8;
		}
	}

	&__type-wrap {
		width: 60px;
		height: 36px;
		color: #4CAF50;
		display: flex;
		align-self: start;
		align-items: center;
		justify-content: center;

		&--with-error {
			color: #EF5350;
		}
	}

	&__but-wrap {
		width: 36px;
		height: 36px;

		&.micro{
			width: 26px;
			height: 20px;
			cursor: pointer;
		}
		& span.--micro {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/microphone.svg');
				width: 20px;
				top: 7px;
				left: 7px;
				height: 20px;
				background-size: cover;
			}

			&:hover:before {
				background-position: -20px 0;
			}
		}

		& span.--send {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/send.svg');
				width: 20px;
				top: 7px;
				left: 7px;
				height: 20px;
				background-size: cover;
				background-position: -20px 0;
			}

			&:hover:before {
				background-position: -20px 0;
			}
		}
	}

	&__wa-error {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		padding-right: 13px;
		flex-wrap: wrap;
	}

	&__textarea-wrap {
		flex: 1 1 auto;
		padding: 2px 0 2px 0;
		max-height: 120px;
		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: auto;
		& textarea {
			display: block;
			background: transparent;
			width: 100%;
			height: 30px;
			border: none;
			padding: 9px 0 5px;
			resize: none;
		}
	}

	&__smiles-wrap {
		padding: 9px 4px;
		border-radius: 11px;
		position: relative;
		align-self: start;
		& .span-smile {
			display: block;
			width: 22px;
			height: 22px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 11px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/smiles.svg');
				width: 20px;
				top: 0px;
				left: 0px;
				height: 20px;
				background-size: cover;
			}
		}
	}

	&__error {
		flex: 1;
		color: #a94442;
		height: 38px;
		background-color: #f2dede;
		border: 1px solid #ebccd1;
		border-radius: 6px;
		padding: 4px 12px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.msg-files {

	&__file {
		display: flex;
		justify-content: space-between;
		margin: 12px 83px 12px 45px;

		&:nth-last-child(1) {
			margin: 12px 83px 0 45px;
		}
	}

	&__icon {
		flex: 0 0 36px;
		margin: 0 10px 0 0;

		& span {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;

			&.--uploading:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/cloud-upload.svg');
				width: 30px;
				top: 3px;
				left: 3px;
				height: 30px;
				background-size: cover;
			}

			&.--completed:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/cloud-upload.svg');
				width: 30px;
				top: 3px;
				left: 3px;
				height: 30px;
				background-size: cover;
				background-position: -30px 0;
			}
		}
	}

	&__info {
		flex: 1 0 auto;
	}

	&__title-wrap {
		display: flex;
		justify-content: space-between;
		margin: 0 0 2px;
	}

	&__title {
		flex: 1 0 auto;
		font-size: 12px;
		line-height: 1;
		color: #333;
	}

	&__percent {
		flex: 0 0 30px;
		font-size: 12px;
		text-align: right;
		margin: 0 0 0 5px;
		line-height: 1;
		color: #8F8F8F;
	}

	&__delete {
		flex: 0 0 14px;
		margin: 0 0 0 5px;

		& span {
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: #FF5151;
			position: relative;
			cursor: pointer;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/message-form-file-delete.svg');
				width: 6px;
				top: 4px;
				left: 4px;
				height: 6px;
			}
		}
	}

	&__status {
		font-size: 10px;
		line-height: 1;
		margin: 0 0 6px;

		&.--completed {
			color: #4CAF50;
		}

		&.--uploading {
			color: #8F8F8F;
		}
	}

	&__percent-bar {

	}

	&__percent-wrap {
		height: 3px;
		border-radius: 5px;
		overflow: hidden;
		background: #e5e5e5;

		& span {
			display: block;
			height: 3px;
			border-radius: 5px;
			overflow: hidden;
			background: $color-main-purple;
			transition: width .3s ease;
		}
	}
}

.msg-note {
	display: flex;
	justify-content: space-between;

	&__close-wrap {
		width: 36px;
		height: 36px;
		border-radius: 18px;

		& span {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/close.svg');
				width: 12px;
				top: 11px;
				left: 11px;
				height: 12px;
				background-size: cover;
			}
		}
	}

	&__msg-wrap {
		background: #fff;
		border: 1px solid #e5e5e5;
		//height: 36px;
		border-radius: 6px;
		flex: 1 0 auto;
		margin: 0 0 0 10px;
		display: flex;
	}

	&__textarea-wrap {
		flex: 1 0 auto;
		padding: 2px 0 2px 12px;

		& textarea {
			display: block;
			width: 100%;
			height: 30px;
			border: none;
			padding: 7px 0 5px;
			resize: none;
		}
	}

	&__send-wrap {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		margin: 0 0 0 10px;

		& span {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/send.svg');
				width: 20px;
				top: 7px;
				left: 7px;
				height: 20px;
				background-size: cover;
				background-position: -20px 0;
			}

			&:hover:before {
				background-position: -20px 0;
			}
		}
	}
}

.msg-micro {
	display: flex;
	justify-content: space-between;

	&__close-wrap {
		width: 36px;
		height: 36px;
		border-radius: 18px;

		& span {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/close.svg');
				width: 12px;
				top: 11px;
				left: 11px;
				height: 12px;
				background-size: cover;
			}
		}
	}

	&__micro-wrap {
		background: #fff;
		border: 1px solid #e5e5e5;
		height: 36px;
		border-radius: 18px;
		flex: 1 0 auto;
		margin: 0 0 0 10px;
		display: flex;
	}

	&__stop-wrap {
		flex: 0 0 36px;
	}

	&__stop {
		border-radius: 15px;
		background: #fff;
		width: 28px;
		height: 28px;
		margin: 3px;
		cursor: pointer;
		border: 1px solid #e5e5e5;
		position: relative;
		transition: .2s;

		&:hover {
			border: 1px solid #FF5151;
		}

		&:before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background: url('assets/img/player-control-sprite.svg') no-repeat;
			background-position: -110px 0px;
			background-size: cover;
			position: absolute;
			top: 8px;
			left: 8px;
		}
	}

	&__play-wrap {
		flex: 0 0 36px;
	}

	&__play {
		border-radius: 15px;
		background: #fff;
		width: 28px;
		height: 28px;
		margin: 3px;
		cursor: pointer;
		border: 1px solid #e5e5e5;
		position: relative;
		transition: .2s;

		&:hover {
			border: 1px solid #57BF47;
		}

		&:before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background: url('assets/img/player-control-sprite.svg') no-repeat;
			background-position: 0px 0px;
			background-size: cover;
			position: absolute;
			top: 8px;
			left: 9px;
		}
	}

	&__pause-wrap {
		flex: 0 0 36px;
	}

	&__pause {
		border-radius: 15px;
		background: #fff;
		width: 28px;
		height: 28px;
		margin: 3px;
		cursor: pointer;
		border: 1px solid #e5e5e5;
		position: relative;
		transition: .2s;

		&:hover {
			border: 1px solid #57BF47;
		}

		&:before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background: url('assets/img/player-control-sprite.svg') no-repeat;
			background-position: -10px 0px;
			background-size: cover;
			position: absolute;
			top: 8px;
			left: 8px;
		}
	}

	&__player-wrap {
		margin: 0 10px 0 7px;
		flex: 1 0 auto;
		padding: 2px 0 0;
		position: relative;
	}

	&__send-wrap {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		margin: 0 0 0 10px;

		& span {
			display: block;
			width: 36px;
			height: 36px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			transition: .2s;
			border-radius: 18px;
			border: 1px solid transparent;

			&:before {
				position: absolute;
				content: '';
				display: block;
				background: url('assets/img/send.svg');
				width: 20px;
				top: 7px;
				left: 7px;
				height: 20px;
				background-size: cover;
				background-position: -20px 0;
			}

			&:hover:before {
				background-position: -20px 0;
			}
		}
	}

	&__img-wrap {
		position: absolute;
		height: 30px;
		background: #fff;
		top: 2px;
		left: 0;
		right: 0;
		z-index: 10;
	}

	&__status {
		position: absolute;
		height: 30px;
		background: #fff;
		top: 2px;
		left: 0;
		right: 0;
		z-index: 10;
		line-height: 30px;
		color: $color-main-purple;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #fff;
		overflow: hidden;
	}

	&__duration {
		flex: 0 0 auto;
		padding: 0 10px;

		& span {
			line-height: 34px;
			color: $color-main-purple;
		}
	}

	&__upload-wrap {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		margin: 0 0 0 10px;
		padding: 8px;

		& .loader3 {
			height: 20px;
		}

		& span {
			//display: block;
			//width: 36px;
			//height: 36px;
			//position: relative;
			//cursor: pointer;
			//overflow: hidden;
			//transition: .2s;
			//border-radius: 18px;
			//border: 1px solid transparent;
			//
			//&:before {
			//	position: absolute;
			//	content: '';
			//	display: block;
			//	background: url('assets/img/send.svg');
			//	width: 20px;
			//	top: 7px;
			//	left: 7px;
			//	height: 20px;
			//	background-size: cover;
			//}
		}
	}
}


.msg-drop-area {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	display: flex;
	padding: 10px;
	z-index: 15000000;

	&__wrap {
		display: flex;
		width: 100%;
		border: 1px solid #e5e5e5;
		border-radius: 10px;

		&.--active {
			background: #fcf9fc;
		}

		& span {
			display: block;
			margin: auto;
			color: #9e9e9e;
		}
	}
}





.message-form2 {
	border-top: 1px solid #e5e5e5;
	padding: 15px 20px;
	background: #fff;

	&__form {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		position: relative;
	}

	&__settings {
		display: flex;
		justify-content: space-between;
	}

	&__clients {
		flex: 1 0 auto;
	}

	&__messengers {
		flex: 0 0 auto;
	}

	&__separator {
		margin: 3px 10px 13px 0px;
		width: 1px;
		height: 20px;
		background: #e5e5e5;
		flex: 0 0 1px;
	}

	&__call {
		flex: 0 0 26px;
		margin: 0 0 0 10px;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: #fff;
		border: 2px solid $color-main-purple;
		position: relative;
		cursor: pointer;
		transition: background .2s ease;
		margin: 3px 0px 10px 0;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/phone-1.svg');
			background-size: cover;
			background-position: 36px 0;
			width: 12px;
			height: 12px;
			top: 5px;
			left: 5px;
		}

		&:hover {
			background: $color-main-purple;

			&:before {
				background-position: -24px 0;
			}
		}

		&:disabled {
			opacity: .5;
			cursor: auto;

			&:hover {
				background: #fff;

				&:before {
					background-position: 36px 0;
				}
			}
		}

	}

	&__file {
		width: 28px;
		height: 36px;
		//background: #ccc;
		flex: 0 0 28px;
		margin: 0 10px 0 0;
		position: relative;
		cursor: pointer;
		overflow: hidden;

		&:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/attachment.svg');
			width: 20px;
			top: 8px;
			left: 4px;
			height: 20px;
			background-size: cover;
		}
		
		&:hover:before {
			background-position: -20px 0;
		}

		& input {
			display: block;
			width: 300px;
			height: 100px;
			position: absolute;
			cursor: pointer;
			opacity: 0;
			filter: alpha(opacity=0);
			top: 0px;
			right: 0px;
		}
	}

	&__smiles {
		width: 28px;
		height: 36px;
		//background: #ccc;
		flex: 0 0 28px;
		margin: 0 0 0 10px;
		position: relative;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/smiles.svg');
			width: 20px;
			top: 8px;
			left: 4px;
			height: 20px;
			background-size: cover;
		}

		&:hover:before {
			background-position: -20px 0;
		}
	}

	&__send {
		width: 28px;
		height: 36px;
		//background: #ccc;
		flex: 0 0 28px;
		margin: 0 0 0 10px;
		position: relative;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/send.svg');
			width: 20px;
			top: 7px;
			left: 7px;
			height: 20px;
			background-size: cover;
			background-position: -20px 0;
		}

		&:hover:before {
			background-position: -20px 0;
		}

		&.--active:before {
			background-position: -20px 0;
		}
	}

	&__textarea {
		flex: 1 0 auto;
		background: #fff;
		border: 1px solid #e5e5e5;
		border-radius: 5px;

		& textarea {
			width: 100%;
			display: block;
			resize: none;
			border: none;
			padding: 9px 10px 5px 10px;
			height: 36px;
			overflow: hidden;
			background: transparent;
		}
	}

	&__over {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255,255,255,.8);
		z-index: 10;
		display: flex;

		& span {
			margin: auto;
			color: #f44336;
			font-weight: 500;
			text-align: center;
		}
	}
}

.message-form-clients {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 auto;

	&__item {
		cursor: pointer;
		display: inline-block;
		padding: 1px 10px 0px 10px;
		background: #fff;
		border: 1px solid #e5e5e5;
		border-radius: 2px;
		color: #585858;
		font-weight: 400;
		margin: 0 10px 15px 0;
		height: 26px;
		transition: border .2s ease, background .2s ease, color .2s ease;

		&.--active {
			background: $color-main-purple;
			border: 1px solid $color-main-purple;
			color: #fff;
		}

		&:not(.--active):hover {
			border: 1px solid $color-main-purple;
			color: $color-main-purple;
		}
	}

	&__add {
		cursor: pointer;
		display: inline-block;
		padding: 1px 6px 0px 6px;
		background: #fff;
		border: 1px solid transparent;
		border-radius: 2px;
		color: #585858;
		font-weight: 400;
		margin: 0 10px 10px 0;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/plus.svg');
			background-size: cover;
			width: 12px;
			height: 12px;
			top: 6px;
			left: 0px;
		}

		&:hover:before {
			background-position: 12px 0;
		}
	}
}

.message-form-contacts {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 auto;

	&__item {
		display: inline-block;
		padding: 0;
		border: 1px solid $color-main-purple;
		color: $color-main-purple;
		border-radius: 2px;
		color: #585858;
		font-weight: 400;
		margin: 0 10px 15px 0;
		height: 26px;
		display: flex;
	}

	&__name {
		padding: 1px 10px 0 10px;
	}

	& input {
		position: absolute;
		left: -9999px;
	}

	&__label {
		padding: 1px 10px 0 10px;
		border-left: 1px solid $color-main-purple;
		display: block;
		margin: 0;
		cursor: pointer;
	}

	& input:checked + label {
		background: $color-main-purple;
		color: #fff;
	}
}

.message-form-messengers {
	display: flex;

	&__item {
		cursor: pointer;
		display: inline-block;
		width: 26px;
		height: 26px;
		background: #fff;
		border: 2px solid #e5e5e5;
		border-radius: 50%;
		margin: 0 10px 15px 0;
		transition: border .2s ease;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: url('assets/img/whatsapp.svg');
			background-size: cover;
			background-position: 24px 0;
			width: 12px;
			height: 12px;
			top: 5px;
			left: 5px;
		}

		&:hover {
			border: 2px solid $color-main-purple;
		}

		&.--active {
			border: 2px solid $color-main-purple;
		}
	}
}

@media only screen and (min-width : 320px) and (max-width : 480px) {
	.messages__group {
		padding: 5px 10px;
	}
	.message-form {
		padding: 10px 10px;
	}
	.message-form-clients__item {
		margin: 0 10px 10px 0;
	}
	.message-form-messengers__item {
		margin: 0 10px 10px 0;
	}
	.messages__date {
		margin: 5px 10px;
	}
}
