
@import '@/styles/_variables.scss';

.datetime {
    font-family: 'Roboto Flex', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 15px;
}
.cross {
    position: absolute;
    width: 15px;
    height: 15px;
    cursor: pointer;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.cross::before,
.cross::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 2px;
    background-color: gray;
    transform: translate(-50%, -50%);
}

.cross::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.cross::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
.input-group {
    width: 100%;
    background: #fff;
    padding: 1px 12px;
    border-radius: 8px;
    border: 1px solid #9d9eaf;

    &.--disabled {
        pointer-events: none;
        cursor: not-allowed;
        background-color: #ececec;

        .input {
            color: #a8a9b3;
        }

        .input-group__label {
            color: #a8a9b3;
        }
    }

    &__label {
        font-size: 12px;
        margin: 0;
        color: #792d67;
        font-weight: 500;
    }

    &:focus-within {
        border-color: $color-main-purple;

        .input-group__label {
            color: $color-main-purple;
        }
    }

    &__error {
        font-size: 12px;
        color: red;
        margin: 5px 0;
        margin-left: 5px;
    }
}
