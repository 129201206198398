<script>
import { mapActions, mapState } from 'vuex';
import IconReply from '@/components/common/Icon/IconReply.vue';
import QuotedMessage from '@/components/funnel/AppFunnelQuotedMessage';

export default {
	name: 'AppAnswer',
	computed: {
		...mapState({
			isAnswer: state => state.answer.isAnswer,
			answerContent: state => state.answer.answerContent,
			answeredType: state => state.answer.answeredType,
			answeredMessageId: state => state.answer.answeredMessageId,
			answeredChatType: state => state.answer.answeredChatType,
		}),
	},
	methods: {
		...mapActions({
			closeAnswer: 'answer/closeAnswer',
		}),
	},
	components: {
		IconReply,
		QuotedMessage,
	},
};
</script>

<template>
  <div
    v-if="isAnswer"
    class="answer__wrap"
  >
    <div class="answer__content">
      <div class="answer__quote">
        <QuotedMessage
          :message-type="answeredType"
          :message-body="answerContent" 
          :message-id="answeredMessageId"
          :chat-type="answeredChatType"
        />
      </div>

      <div class="answer__close-wrap">
        <button
          class="answer__close-button"
          @click="closeAnswer"
        >
          ✕
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.answer__wrap {
    display: flex;
    width: 100%;
    padding: 10px;
}

.answer__content {
    background-color: #e1e1e1;
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    align-items: center;
}

.answer__close-wrap {
    position: absolute;
    right: 20px;
}

.answer__close-button {
    display: inline-block;
    padding: 3px 5px 0px 5px;
    border: none;
    border-radius: 100%;
    background-color: #eee;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    justify-content: center;
    text-align: center;
}

.answer__quote {
    max-width: 80%;
}
</style>
