
button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.income-modal {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    max-width: 480px;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    position: relative;

    &__product-amount-input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 35%;
    }

    &__button-wrapper {
        position: sticky;
        bottom: 0;
        z-index: 1;
        width: 100%;
        background: white;
        padding-bottom: 25px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: sticky;
        top: 0;
        background: white;
        padding: 20px 0 10px 0;

        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
    }

    &__input-container {
        flex-grow: 1;
        position: relative;
    }

    &__input-label {
        position: absolute;
        top: 8px;
        left: 12px;
        font-size: 12px;
        color: grey;
        transition: all 0.3s ease;
        pointer-events: none;
    }

    &__input:not(:placeholder-shown) + &__input-label,
    &__input:focus + &__input-label {
        top: -1px;
        left: 12px;
        font-size: 10px;
        color: rgb(194, 62, 255);
    }

    &__input {
        width: 100%;
        padding: 16px 12px 3px 12px;
        border: 1px solid #A6A6A6;
        border-radius: 10px;
        font-size: 16px;

        &:focus {
            outline: none;
            border-color: rgb(194, 62, 255);
            box-shadow: 0 0 5px rgba(231, 113, 255, 0.7);
        }

        &::placeholder {
            color: #575757;
        }
    }

    &__manager-name {
        font-weight: 400;
        line-height: 16px;
        color: #575757;
    }

    &__product {
        background: #F5F5F5;
        margin-bottom: 8px;
        padding: 12px 16px;
        border-radius: 4px;

        &-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex-grow: 1;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            margin-top: 14px;

            button {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: #1C1C1C;
            }
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &-top {
            margin-bottom: 4px;
        }

        &-date {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #1C1C1C;
        }

        &-total {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #10B981;
        }

        &-refund {
            color: #EF4444;
        }

        &-name {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #575757;

            margin-bottom: 4px;
        }

        &-amount {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #1C1C1C;

            margin-bottom: 4px;
            display: flex;
            width: 100%;
            justify-content: end;
        }
    }

    &__add-payment-button {
        width: 100%;
        background: #10B981;
        color: white;
        padding: 8px 0;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        transition: background 0.3s ease, box-shadow 0.2s ease;

        &:hover {
            background: #0F9B75;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        }
    }

    &__error {
        color: red;
        font-size: 12px;
    }
}

.closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon {
    cursor: pointer;
}

.modal-transition {
    &-enter {
        opacity: 0;

        & .income-modal {
            opacity: 0;
            transform: scale(0.1, 0.1);
        }
    }

    &-enter-active {
        transition: opacity 0.3s ease, transform 0.3s ease;

        & .income-modal {
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }

    &-enter-to {
        opacity: 1;

        & .income-modal {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-leave {
        opacity: 1;

        & .income-modal {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-leave-active {
        transition: opacity 0.3s ease, transform 0.3s ease;

        & .income-modal {
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }

    &-leave-to {
        opacity: 0;

        & .income-modal {
            opacity: 0;
            transform: scale(0.8);
        }
    }
}

.validation-error {
    color: red;
    margin: 2px 0;
    font-size: 12px;
}
