.slick-track[data-v-e4caeaf8] {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    // height: 26px;
    overflow: hidden;
}

.slick-track.slick-center[data-v-e4caeaf8] {
    margin-left: auto;
    margin-right: auto;
}

.slick-track[data-v-e4caeaf8]:after,
.slick-track[data-v-e4caeaf8]:before {
    display: table;
    content: '';
}

.slick-track[data-v-e4caeaf8]:after {
    clear: both;
}

.slick-loading .slick-track[data-v-e4caeaf8] {
    visibility: hidden;
}

.slick-slide[data-v-e4caeaf8] {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-slide img[data-v-e4caeaf8] {
    display: block;
}

.slick-slide.slick-loading img[data-v-e4caeaf8] {
    display: none;
}

.slick-slide.dragging img[data-v-e4caeaf8] {
    pointer-events: none;
}

.slick-initialized .slick-slide[data-v-e4caeaf8] {
    display: block;
}

.slick-loading .slick-slide[data-v-e4caeaf8] {
    visibility: hidden;
}

.slick-vertical .slick-slide[data-v-e4caeaf8] {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden[data-v-21137603] {
    display: none;
}

.slick-slider[data-v-3d1a4f76] {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list[data-v-3d1a4f76] {
    height: auto !important;
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    width: 20px;
    height: 20px;
    z-index: 200;
}

.slick-prev {
    left: -20px;
}

.slick-prev:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: url('assets/img/arrow-1-sprite.svg') no-repeat -24px 0px;
    background-size: cover;
}

.slick-next {
    right: 0px;
}

.slick-next:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: url('assets/img/arrow-1-sprite.svg') no-repeat 0px 0px;
    background-size: cover;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}
.slick-list[data-v-3d1a4f76]:before {
    content: '';
    display: block;
    width: 50px;
    height: 26px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, (#f0f0f0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, (#f0f0f0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, (#f0f0f0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
}
