.page {
	padding: 70px 0 0;
	height: 100%;

	&__funnel {
		display: flex;
		flex-direction: column;
	}
}

.page-header {

	&__header {
		font-size: 24px;
		font-weight: 500;
		margin: 0 0 20px;
	}
}