html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 100%;
  line-height: 150%;
  color: #333333;
  letter-spacing: .2px;
}

*, *:focus {
  box-sizing: border-box;
  outline: none;
}

.no-use {
  user-select: none;
}

.off {
  opacity: .5;
  pointer-events: none;
}

.wrapper {
  height: 100%;
}

.main {
  //max-width: 1200px;
  padding: 0 20px;
  //margin: auto;
}

.txt-center {
  text-align: center;
}
.text-right{
  text-align: right;
}
.flex {
  display: flex;
  &.a-center {
    align-items: center;
  }
  &.j-between {
    justify-content: space-between;
  }

}

.main {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  transition: width .2s ease;

  &.--full {
    max-width: 100%;
  }
}

.leftSideButtons {
  .transferButton {
    background-color: #FFFFFF;
    border: none;
    border-radius: 8px;
    color: #B9439D;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .main {
    padding: 0 10px;
  }
}

.chart-tooltip, #chartjs-tooltip {
  background: #fff !important;
  border-radius: 4px;
  padding: 10px 15px;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);

  &:after, &:before {
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    border-width: 6px;
    border-top-color: #dbdbdb;
  }

  &:after {
    border-width: 5px;
    border-top-color: #fff;
  }
}

.label {

  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  font-size: 12px;
  border-radius: 3px;
  background: #ccc;
  margin: 0 0 0 5px;
  display: inline-block;
  font-weight: bold;

  &.--green {
    background: #7ada68;
    color: #297328;
  }

  &.--red {
    background: #ff8a82;
    color: #881f1f;
  }
}

.elem-off {
  pointer-events: none;
  opacity: .5;
}

.sub-deals {
  padding: 5px 0 0;

  &__item {
    border: 1px solid #e5e5e5;
    padding: 5px 10px 0;
    border-radius: 3px;
    margin: 0 0 10px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.075);

    &:hover .sub-deals__edit, &:hover .sub-deals__delete {
      opacity: 1
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 500;
    flex: 1 0 auto;
  }

  &__edit {
    flex: 0 0 auto;
    opacity: 0;
    transition: opacity .2s ease;

    & button {
      background: none;
      border: none;
      color: #54B744;
      font-size: 12px;
      line-height: 1;
      cursor: pointer;
      display: block;
      margin: 0 0 0 10px;
      padding: 0;
    }
  }

  &__delete {
    flex: 0 0 auto;
    opacity: 0;
    transition: opacity .2s ease;

    & button {
      background: none;
      border: none;
      color: #FF5151;
      font-size: 12px;
      line-height: 1;
      cursor: pointer;
      display: block;
      margin: 0 0 0 10px;
      padding: 0;
    }
  }

  &__value {
    padding: 10px 0 10px;
    line-height: 120%;
  }
}
.pointer{
  cursor: pointer;
}
.main-color{
  color: #a23a99;
}
.tippy-tooltip.custom-theme {
  background: #FEF3C7;
  border: 1px solid #F59E0B;
  font-size: 10px;
  line-height: 16px;
  color: #1C1C1C;
  border-radius: 8px;
  padding: 8px;

  .tippy-arrow {
    bottom: -8px;
    border-top-color: #F59E0B;
    border-bottom-color: #F59E0B;
  }
}
