
@import '../../../../../CalendarTasks/variables.scss';

.btns {
    display: flex;
    align-items: center;
    gap: 20px;
}

.return-button {
    cursor: pointer;
    margin-top: 6px;
    margin-right: 4px;
}

.type-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
}

.type-button {
    padding: 4px 8px;
    border-radius: 8px;
    color: #1C1C1C;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #A6A6A6;

    &__active {
        background-color: #575757;
        border: 1px solid #575757;
        color: white;
    }
}

.close-btn {
    cursor: pointer;
}

.edit-btn {
    border: none;
    cursor: pointer;
    width: 28px;
    height: 28px;
    background-color: $accent-800;
    mask-image: url('../../../../../../assets/edit.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 20px;
    transition: all 0.15s ease-in-out;

    &:hover {
        background-color: $accent-500;
    }

    &.active {
        background-color: $accent-500;

        &:hover {
            background-color: $accent-800;
        }
    }
}

.calendar-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    cursor: pointer;
}

.calendar-modal {
    position: absolute;
    transform: translate(0, -50%);
    margin: 0 auto;
    z-index: 1000;
    width: 480px;
}

.calendar-form {
    margin: 0 10px;
    padding: $xl;
    border-radius: $xl;
    color: $accent-800;
    background-color: white;
    border: 1px solid #E6E6E6;
}

.modal-header {
    padding-bottom: $s;
    display: flex;
    justify-content: space-between;
}

.type-list {
    position: absolute;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: $s;
    opacity: 0;
    visibility: hidden;
    transition: all 0.15s ease-in-out;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.header-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header-type {
    border-radius: 8px;
    padding: 3px 12px;
    font-size: 12px;
    transition: all 0.10s ease-in-out;
    pointer-events: none;
    color:#000000;

    &[data-clickable]{
        cursor: pointer;
        pointer-events: all;
        &:hover {
            filter: brightness(0.95);
        }
    }
}


.modal-description {
    padding: 25px 13px;
    width: 100%;
    border-radius: $xs;
    resize: vertical;
    border: 1px solid #A6A6A6;

    &__div {
      color: #17171C;
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
    }

    &__placeholder {
      color: #575757;
      font-size: 11px;
      font-weight: 500;
      position: absolute;
      top: 4px;
      left: 13px;
    }
}

.modal-container {
    margin-bottom: 16px;
}

.modal-date-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: $s;
    }
}

.modal-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.modal-list-item {
    margin-bottom: 16px;
    position: relative;
}

.list-time {
    display: grid;
    align-items: center;

    &__wrapper {
      display: flex;
    }

    &__date {
      font-weight: 500;
      color: #575757;
      font-size: 12px;
    }
}

.list-item__buttons {
  display: flex;
  gap: 10px;
  align-items: center;

  .task-to-calendar {
    color: #1C1C1C;
    font-weight: 500;
    cursor: pointer;
  }
}

.list-item-modal {
  font-size: 12px;
}

.list-item-date {
  display: flex;
  gap: 5px;

  &__date {
    color: #575757;
    font-weight: 500;
  }

  &__time {
    color: #1C1C1C;
    font-weight: 500;
  }
}

.list-item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text-primary;

    .datepicker-dash {
      font-weight: 500;
      margin-right: 5px;
    }

    .datepicker-input {
      background-color: transparent;
      border: none;
      color: #17171C;
      font-size: 14px;
      font-weight: 500;
    }

    .datepicker-input__hour {
      background-color: transparent;
      border: none;
      color: #17171C;
      font-size: 14px;
      font-weight: 500;
      width: 45px;
    }
}

.list-item-date-complete {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-complete {
  color: #FFFFFF;
  background-color: #B9439D;
  padding: 4px 5px;
  border-radius: 4px;
  width: max-content;
  cursor: pointer;
}

 .task-completed {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #10B981;
  font-size: 12px;
  width: max-content;
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;

  &__check {
    margin-left: 4px;
  }
}


.modal-textarea {
    border: none;
    resize: none;
    width: 100%;
    border-radius: $xs;
}

.modal-footer {
    display: flex;
    margin-top: 16px;
}

.modal-footer-btns {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.modal-footer-btn {
    border-radius: $xs;
    padding: 4px 8px;
    background: $accent-500;
    border: none;
    color: white;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
    &.disabled {
        filter: grayscale(0.5)
    }

    &:hover {
        filter: brightness(0.9);
    }

    &__delete {
      border: none;
      background: transparent;
      cursor: pointer;
      color: #EF4444;

      &:hover {
        opacity: 0.8;
      }
    }
}

.modal-select-info {
    color: $surface-default-secondary;
    font-size: 11px;
    font-weight: 500;
}

.modal-hidden-input {
    visibility: hidden;
    padding: 0;
    margin: 0;
    position: absolute;
    transform: translate(0, 230px);
}

.modal-hidden-input {
    visibility: hidden;
    max-height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    position: absolute;
}

.modal-select {
    border-radius: $xs;
    border: 1px solid $surface-default-tertriany;
    padding: 2px $s;
    width: 100%;

    &[data-clickable] {
        cursor: pointer;
    }
}

.modal-date-select {
    border-radius: $xs;
    padding: 2px $s;
    width: 100%;

    label, input {
        all: unset;
    }

    &[data-clickable] {
        cursor: pointer;
    }
}

.modal-select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-select-left {}

.modal-select-info {
    color: $surface-default-secondary;
    font-size: 11px;
    font-weight: 500;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, height 1.5s ease;
}

.fade-leave-from,
.fade-enter-to {
    opacity: 1;
    height: 100%;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    height: 0px;
}

.input-container {
    display: flex;
    gap: $xs;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    .checkmark {
        position: relative;
        display: block;
        height: 25px;
        width: 25px;
        background-color: $fill-inactive-static;
        border-radius: $xxs;
        transition: background-color 0.15s ease-in-out;

        &::before {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 4px;
            width: 8px;
            height: 14px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.input-container input:checked ~ .checkmark {
    background-color: $fill-active-dynamic;

    &::before {
        display: block;
    }
}

.input-container:hover .checkmark {
    background-color: $fill-active-static
}

.checkmark::after {
    display: absolute;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/deep/ .vc-popover-content-wrapper {
  .vc-weekday {
    text-transform: uppercase;
    color: #575757;
    font-size: 12px;
  }

  .vc-highlights {
    background-color: #B9439D;
  }

  .vc-title {
    font-size: 14px;
    color: #575757;
    font-weight: 500;
  }

  .vc-weeks::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #D4D4D4;
    position: absolute;
    top: 30px;
  }
}

/deep/ .vc-time-picker {
  .vc-month {
    display: none;
  }

  .vc-time-icon {
    display: none;
  }

  .vc-day {
    display: none;
  }

  .vc-year {
    display: none;
  }

  .vc-weekday {
    display: none;
  }

  select {
    background-color: #E6E6E6;
    color: #B9439D;
  }

  select:hover {
    background-color: #E6E6E6; 
    color: #B9439D;
  }
}

