
@import '@/styles/_variables.scss';

.input-container {
    font-family: 'Roboto Flex', sans-serif;
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    &.--disabled {
        pointer-events: none;
        cursor: not-allowed;

        .input-group {
            background-color: #ececec;

            &__label {
                color: #a8a9b3;
            }
        }
    }

    &.--error {
        .input-group {
            border-color: red;

            &__label {
                color: red;
            }
        }
    }
}

.input-group {
    width: 100%;
    background: #fff;
    padding: 1px 12px;
    border-radius: 8px;
    border: 1px solid #9d9eaf;

    .input {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
    }

    .input::placeholder {
        color: #757575;
        font-weight: 400;
    }

    &__label {
        font-size: 12px;
        margin: 0;
        color: #792d67;
        font-weight: 500;
    }

    &:focus-within {
        border-color: $color-main-purple;

        .input-group__label {
            color: $color-main-purple;
        }
    }

    &__error {
        font-size: 12px;
        color: red;
        margin: 5px 0;
        margin-left: 5px;
    }

    &__description {
        font-size: 12px;
        color: #9d9eaf;
        margin: 5px 0;
        margin-left: 5px;
    }

    &__save {
        position: absolute;
        top: 25%;
        right: 0;
        font-size: 12px;
        // background: #fff;
        padding: 3px 7px 2px;
        font-weight: 500;
        color: $color-main-purple;
        display: block;
        visibility: visible;
        pointer-events: auto;
        animation: save 2s infinite ease-in-out;
        pointer-events: none;
        transition: opacity 0.5s ease;
    }
}

@keyframes save {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
