

.row-button {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.curtain {
    position: fixed;
    top: -1px;
    left: 0;
    bottom: -1px;
    right: 0;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);

    &__main {
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 320px;
    }

    &__head {
        color: #5b5b5b;
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        padding: 0 30px 0 0;
        color: #1C1C1C;
    }

    &__close {
        width: 16px;
        height: 50px;
        position: relative;
        cursor: pointer;
        margin: 0 0 0 15px;

        &:before {
            position: absolute;
            top: 12px;
            display: block;
            content: '';
            width: 24px;
            height: 24px;
            background: url('./../../assets/close-dialog-icon.svg') no-repeat center;
        }
    }

    &__body {
        padding: 10px 20px;
        height: calc(100% - 51px);
        overflow: auto;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a23a99;
            border-radius: 4px;
            border: 2px solid #f1f1f1;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #7a2a73;
            cursor: pointer;
        }
    }
}

.deal2 {

    &__item {
        background: #fff;
        border-radius: 8px;
        margin: 0 0 10px;
        //cursor: pointer;
        border: 1px solid #E6E6E6;
    }

    &__wrap {
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &__name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        margin: 0 0 4px;
        color: #1C1C1C;
    }

    &__description {
        font-weight: normal;
        margin: 0 0 2px;
    }

    &__manager {
        font-weight: normal;
        margin: 0 0 2px;
        font-size: 12px;
    }

    &__info {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: #575757;

        &-data {
            color: #1C1C1C;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;

        & .btn {
            padding: 6px 10px;
        }
    }

    &__button {
        margin-top: 8px;
        font-weight: 500;
        display: inline-block;
        color: #B9439D;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background: #F5EFF4;
        border-radius: 4px;
        padding: 6px 10px;
        line-height: 1;
        cursor: pointer;
        position: relative;
        transition: .1s;
        width: 100%;
        border: none;

        &--disabled {
            background: inherit;
            color: #A6A6A6;
            cursor: not-allowed;
        }

        &::after {
            content: '';
            display: block;
        }

        &:not(&--disabled):hover {
            color: #8A347A;
        }
    }

    &__tooltip {
        text-align: left;
        position: absolute;
        top: 100%;
        left: 30%;
        transform: translateY(-140%);
        padding: 8px;
        background-color: #FEF3C7;
        color: #1C1C1C;
        border-radius: 8px;
        border: 1px solid #F59E0B;
        font-size: 12px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.4s;
        width: 100%;
        max-width: 200px;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 10%;
            width: 12px;
            height: 12px;
            background-image: url('./../../assets/icons/tooltip-snot.svg');
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    &__button--disabled:hover &__tooltip {
        visibility: visible;
        opacity: 1;
    }
}
