
.search-button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: #575757;
    cursor: pointer;
}

.media-cross {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 10px;
}
