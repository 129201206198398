
.player-controls {
    padding: 10px;
    display: flex;
    align-items: center;

    &__playback-rate {
        margin-right: 16px;
    }

    &__skip-backward {
        transform: scale(-1, 1);
        margin-right: 10px;
    }

    &__skip-backward,
    &__skip-forward {
        cursor: pointer;
        user-select: none;
        color: #333;
        transition: .3s;
    }
}

.selector {
    color: #333;
    user-select: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &__title {
        padding: 5px 7px;
        font-size: 10px;
        line-height: 11px;
        border-right: 1px solid #ddd;
    }

    &__input {
        cursor: pointer;
        background: transparent;
        height: 21px;
        border: none;
        line-height:21;
        font-size: 10px;
        option{
          font-size: 13px;
        }
    }
}
