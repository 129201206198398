.loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, .5);
	z-index: 1000000000;
	display: flex;
	border-radius: inherit;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition: .2s opacity ease;

	&.--fixed {
		position: fixed;
	}

	&.--show {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
	}

	&__spinner {
		position: relative;
		width: 50px;
		height: 50px;
		margin: auto;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			border-width: 4px;
			border-style: solid;
			border-radius: 50%;
		}

		@keyframes scale-2 {
			0% {
				transform: scale(0);
				opacity: 0;
			}

			50% {
				transform: scale(0.7);
				opacity: 1;
			}

			100% {
				transform: scale(1);
				opacity: 0;
			}
		}

		&:before {
			width: 50px;
			height: 50px;
			border-color: $color-main-purple;
			top: 0px;
			left: 0px;
			animation: scale-2 1s linear 0s infinite;
		}

		&:after {
			width: 50px;
			height: 50px;
			border-color: $color-main-purple;
			top: 0;
			left: 0;
			opacity: 0;
			animation: scale-2 1s linear 0.5s infinite;
		}
	}
}

.loader2 {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000000000;
	display: flex;

	&__spinner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			border: 2px solid;
			border-radius: 50%;
			width: 100%;
			height: 100%;
		}

		&:before {
			border-color: rgba($color-main-purple, .5);
			opacity: .3;
		}

		&:after {
			border-color: transparent;
			border-bottom-color: $color-main-purple;
			animation: loader2 1s infinite linear;
		}

		@keyframes loader2 {
			100% {
				transform: rotate(360deg);
			}
		}

	}
}

.loader3 {
	display: flex;
	position: relative;

	&__spinner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			border: 2px solid;
			border-radius: 50%;
			width: 100%;
			height: 100%;
		}

		&:before {
			border-color: rgba($color-main-purple, .5);
			opacity: .3;
		}

		&:after {
			border-color: transparent;
			border-bottom-color: $color-main-purple;
			animation: loader2 1s infinite linear;
		}

		@keyframes loader2 {
			100% {
				transform: rotate(360deg);
			}
		}

	}
}