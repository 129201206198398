
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; 
}

.msg-loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #a33b9a;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
	to {
		transform: rotate(1turn)
	}
}

.media {
	background: #FFFFFF;
	padding-top: 20px;
}

.file-component {
	padding-left: 20px;
}

.messages__wrapper {
	margin-top: 10px;
}

.pagination {
	height: 20px;
}

.media-viewer-container {
	padding: 4px 20px 0px 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 2px;
}
