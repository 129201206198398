

.clear-context-loader {
    width: 20px;
    height: 20px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #A23A99 90%, #0000);
    background: var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }
    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }
    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }
    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}

.switch-wrapper {
    padding: 4px 16px 4px;
    background: white;
    position: relative;
    z-index: 500;
	height: 24px;
	transition: height 0.3s ease;

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 16px;
        font-size: 12px;
        flex-wrap: wrap;
    }

    .is-work {
        display: flex;
        color: #1D4ED8;
        column-gap: 8px;
        flex-wrap: wrap;
		cursor: pointer;
    }

    .label-checkbox {
		margin-top: 4px;
		margin-right: 12px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        font-size: 12px;
        cursor: pointer;
    }
}

.transition-height {
  transition: height 0.3s ease;
  overflow: hidden;
  height: 54px;
}

.clear-context {
    cursor: pointer;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    border-radius: 5px;
    color: #A23A99;
    background: #ffffff;
    border: none;
}

.bpm-arrow {
	cursor: pointer;
 	transition: transform 0.3s ease;
}

.collapsed {
	transform: rotate(180deg);
}
