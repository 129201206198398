
@import '../../styles/variables';
.profile__settings-icon {
    display: flex;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
}
.source {
    display: none;
    position: absolute;
    min-height: unset;
    height: unset !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-deal__body2 {
    // width: calc(920px - 270px);
    width: 100%;
    background: #f0f0f0;
    flex: 1 0 auto;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
