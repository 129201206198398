<template>
  <div
    class="wrapper"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    <svg
      v-if="!hover"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.42793 3.98247L1.69329 0.228179C1.00864 -0.315036 1.05247e-06 0.172892 9.76095e-07 1.04654L9.15013e-08 11.1651C1.50688e-08 12.0394 1.00928 12.5267 1.69329 11.9835L6.42857 8.22918L6.42857 11.1645C6.42857 12.0388 7.43786 12.526 8.1225 11.9828L14.3171 7.07075C14.4626 6.95536 14.5801 6.80858 14.6609 6.64138C14.7417 6.47418 14.7837 6.29088 14.7837 6.10518C14.7837 5.91948 14.7417 5.73618 14.6609 5.56898C14.5801 5.40178 14.4626 5.255 14.3171 5.13961L8.12186 0.228179C7.43721 -0.315036 6.42857 0.172893 6.42857 1.04654L6.42793 3.98247ZM6.42793 6.99746L1.09414 11.2275C1.0826 11.2375 1.06824 11.2438 1.053 11.2455C1.03794 11.2466 1.02285 11.2437 1.00929 11.2371C0.995529 11.2307 0.983757 11.2207 0.975213 11.2082C0.96715 11.1951 0.963334 11.1798 0.964286 11.1645L0.964287 1.04654C0.963427 1.0314 0.96724 1.01636 0.975214 1.00346C0.983758 0.990928 0.99553 0.980934 1.00929 0.974536C1.0228 0.967719 1.03789 0.964613 1.053 0.965536C1.06824 0.967166 1.08261 0.97345 1.09414 0.983536L6.42857 5.21289L6.42793 6.99746ZM7.52336 11.2275C7.51164 11.2377 7.49703 11.244 7.48157 11.2455C7.46652 11.2466 7.45143 11.2437 7.43786 11.2371C7.42412 11.2307 7.41235 11.2207 7.40379 11.2082C7.39576 11.1951 7.39195 11.1798 7.39286 11.1645L7.39286 1.04654C7.39223 1.03133 7.39627 1.01631 7.40443 1.00347C7.41282 0.991038 7.42435 0.981057 7.43786 0.974536C7.45138 0.967719 7.46646 0.964613 7.48157 0.965536C7.49704 0.967038 7.51164 0.97333 7.52336 0.983536L13.7179 5.89497C13.7495 5.92007 13.775 5.95198 13.7926 5.98833C13.8101 6.02467 13.8192 6.0645 13.8192 6.10486C13.8192 6.14521 13.8101 6.18505 13.7926 6.22139C13.775 6.25774 13.7495 6.28965 13.7179 6.31475L7.52336 11.2275Z"
        fill="#047857"
      />
    </svg>
    <svg
      v-else
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @mouse
    >
      <path
        d="M6.42794 3.98247L1.6933 0.228179C1.00866 -0.315036 1.63113e-05 0.172892 1.62349e-05 1.04654L1.53503e-05 11.1651C1.52739e-05 12.0394 1.0093 12.5267 1.6933 11.9835L6.42859 8.22918L6.42859 11.1645C6.42859 12.0388 7.43787 12.526 8.12251 11.9828L14.3171 7.07075C14.4626 6.95536 14.5801 6.80858 14.6609 6.64138C14.7417 6.47418 14.7837 6.29088 14.7837 6.10518C14.7837 5.91948 14.7417 5.73618 14.6609 5.56898C14.5801 5.40178 14.4626 5.255 14.3171 5.13961L8.12187 0.228179C7.43723 -0.315036 6.42859 0.172893 6.42859 1.04654L6.42794 3.98247Z"
        fill="#047857"
      />
    </svg>
  </div>
</template>

<script>
export default {
	data() {
		return {
			hover: false,
		};
	},
};
</script>

<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>