
.input-wrap {
    position: relative;
    background: white;
    &.disabled{
        .input {
            color: #A6A6A6;
            background: #C7C7C7;
        }
    }
}

.input {
    display: block;
    width: 100%;
    padding: 18px 32px 2px 12px;
    background: none;
    border: 1px solid #A6A6A6;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.label-input {
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: #A6A6A6;
}
.calendar-icon{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    background: url("../assets/calendar-input.svg") no-repeat;
    background-size: cover;
}
.cross-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    background: url("../assets/close-grey.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
}
