
.container-blur {
    width: 100%;
    height: 150px;
    position: relative;
    .blur {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('/img/placeholder-image.jpg') no-repeat;
        background-size: cover;
        filter: blur(9px);
        opacity: 0.5;
    }
    .container-loader {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
