
$border-color: #ccc;
$label-border-color: #A6A6A6;
$background-color: white;
$font-size: 12px;
$padding: 5px 7px;
$border-radius: 8px;

.category-select-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: start;
}

.category-select {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__select-wrap {
        position: relative;
        display: inline-flex;
        width: auto;
    }
}

.split-select {
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $background-color;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    max-width: 100%;

    &__label {
        padding: $padding;
        border-right: 1px solid $label-border-color;
        font-size: $font-size;
        white-space: nowrap;
        flex-shrink: 0;

    }

    &__dropdown {
        padding: 5px 7px;
        font-size: $font-size;
        flex-grow: 1;
        background-color: $background-color;

        display: flex;
        align-items: center;

        justify-content: center;

        &::after {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            background-image: url('../../assets/v2/gray-dropdown-arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 8px;
            transition: transform 0.3s ease;
            transform: rotate(0deg);
        }

        &.is-open::after {
            transform: rotate(180deg);
        }
    }
}

.custom-dropdown {
    position: absolute;
    top: calc(100% + 13px);
    left: 0;
    width: 100%;
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    animation: slide-down 0.3s ease-out;
    z-index: 1000;
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.3);
    }

    &__option {
        padding: 8px 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    &__tail {
        position: absolute;
        top: -12px;
        left: 50%;
        width: 14px;
        height: 12px;
        background-image: url('../../assets/v2/dropdown-top-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
