


.checkbox {
    margin: 0 5px;
}

.list__item {
    display: flex;
    align-items: center;
    column-gap: 10px;

    input {
        margin: 0;
    }
}

.row {
    display: flex;
    align-items: center;
}
