
.error {
    color: red;
    margin-top: 10px;
}

.cancel-button {
    color: #1C1C1C;
    background: none;
    border: none;
    cursor: pointer;
}

.button {
    color: white;
    background: #B9439D;
    cursor: pointer;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;

    &:active {
        &:not(:disabled) {
            transform: scale(0.99);
        }
    }

    &:disabled {
        background: #E6E6E6;
    }
}

.row-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.modal-instruction-form {
    margin: 0 auto;
    width: 100%;
    max-width: 1078px;
    background: white;
    border-radius: 24px;
    border: 1px solid #9D9EAF;
    padding: 24px;

    .header-row {
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
    }

    .row {
        display: flex;
        column-gap: 20px;
    }
}

