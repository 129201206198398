
.reciever-selector {
    position: relative;

    &__item {
        color: #333;

        &-label {
            display: flex;
            align-items: center;
        }

        &-icon {
            margin: 0 8px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &--active {
            color: #a23a99;
        }
    }

    &__dropdown {
        position: absolute;
        top: 50px;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transform: translateX(-50%);
        background: #fff;
        border-radius: 4px;
        padding: 24px 21px;
        width: max-content;
        border: 1px solid #dbdbdb;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        transition: 0.2s;
        cursor: auto;
        color: #333333;

        &:after,
        &:before {
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            border-width: 6px;
            border-bottom-color: #dbdbdb;
        }

        &:after {
            border-width: 5px;
            border-bottom-color: #fff;
        }

        &--shown {
            top: 60px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }

    &__tooltip {
        padding: 4px 8px;
        font-size: 10px;
        line-height: 12px;
        top: calc(50%);
        transform: translateY(-50%) translateX(10%);
        right: calc(100% + 16px);
        width: max-content;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        transition: 0.2s;
        cursor: auto;
        color: #333333;

        &:after,
        &:before {
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
        }

        &:before {
            border-width: 6px;
            border-left-color: #dbdbdb;
        }

        &:after {
            border-width: 6px;
            border-left-color: #fff;
        }
    }

    &__button {
        width: 30px;
        height: 50px;
        position: relative;
        transition: 0.2s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-bottom: 2px solid transparent;

        &:hover {
            border-bottom-color: #4A1C3F;
            opacity: 0.8;
            background: rgba(255, 255, 255, .2);
        }
    }

    &:hover {
        .reciever-selector__tooltip {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transform: translateY(-50%) translateX(0);
        }
    }
}
