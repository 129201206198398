
.tabs__item {
    cursor: pointer;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
}

.deal_name {
    font-weight: 500;
    color: #17171c;
}
.red {
    color: #EF4444;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.green {
    color: #10B981;
}
.container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    gap: 12px;
    color: #575757;
    .value{
       color: #1C1C1C;
    }

}
.deal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
