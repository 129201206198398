
.row {
    display: flex;
    align-items: center;
    column-gap: 28px;
}

.instruction-status {
    font-size: 14px;
    line-height: 20px;
    color: #1C1C1C;
    font-weight: 400;
    min-width: 163px;
}

.range-value {
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 2px 5px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 10px;
    color: white;
    width: 29px;
    height: 16px;
    transform: translateX(-50%);
    background: #B9439D; /* Замените #B9439D на желаемый цвет */
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.error {
    color: red;
}

.label-checkbox {
    display: flex;
    column-gap: 10px;
    cursor: pointer;
    user-select: none;
}

.list {
    padding: 0;
    margin: 0;
}

.list-item {
    list-style: none;
}

.modal-description {
    border: 1px solid #A6A6A6;
    width: 100%;
    border-radius: 8px;
    padding: 8px 12px;
    resize: none;
    min-height: 428px;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.params-container {
    padding: 16px;
    border: 1px solid #A6A6A6;
    border-radius: 8px;
    margin-bottom: 16px;
}

.set-working-time {
    button {
        padding: 0;
        background: none;
        cursor: pointer;
        border: none;
        color: #3B82F6;
    }
}

.param-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #1C1C1C;
    margin-bottom: 8px;
    column-gap: 4px;
    cursor: pointer;
}

.radio-group {
    display: flex;
    column-gap: 16px;

    span {
        font-weight: 400;
    }
}

.input-name {
    border: 1px solid #A6A6A6;
    width: 100%;
    border-radius: 8px;
    padding: 8px 12px;
}

.label-radio {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    user-select: none;
    max-width: 200px;
}

.group-params {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.radio-button {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #D4D4D4;

    &.active {
        background: #D1FAE5;

        &::before {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            background: #10B981;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.select-model {
    display: flex;
    align-items: center;
    margin-top: 16px;
    column-gap: 8px;

    &::v-deep {
        .select-wrap {
            flex: 1;
            margin-bottom: 0;

            .items {
                top: 45px !important;
                border-top: 1px solid #A6A6A6;
                max-width: 288px;

                .item {
                    &:hover {
                        background: none;
                        color: #B9439D;
                    }

                    &.active {
                        background: none;
                        color: #B9439D;
                    }
                }

            }
        }
    }
}

.range-container {
    position: relative;
}

#rangeInput {
    -webkit-appearance: none;
    width: 100%;
    background: #E6E6E6; /* Замените #yourColor на желаемый цвет */
    height: 5px;
    margin-bottom: 10px;
}

#rangeInput::-webkit-slider-runnable-track {
    background: #E6E6E6; /* Замените #yourColor на желаемый цвет */
    height: 8px;
    border-radius: 8px;
}

#rangeInput::-moz-range-track {
    height: 8px;
    background: #E6E6E6; /* Замените #yourColor на желаемый цвет */
}

#rangeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent; /* Замените #B9439D на желаемый цвет */
    width: 29px;
    height: 16px;
    cursor: pointer;
    border-radius: 8px;
}

#rangeInput::-moz-range-thumb {
    background: transparent; /* Замените #B9439D на желаемый цвет */
    width: 29px;
    height: 16px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
}

.fw-500 {
    font-weight: 500;
}
