//Основной цвет
$color-main-purple: #a23a99;

$color-grey-90: #e5e5e5;
$color-grey-36: #5b5b5b;

$color-main-text-1: #5b5b5b;

// For status components colors:

$s-color-w: #fff;
$s-success: #10b981;
$s-edit-bg: #dbeafe;
$s-comfort-color: #1d4ed8;
$s-hidden-off-color: #575757;

$color-border: #9d9eaf;
