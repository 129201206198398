.btn {
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: $color-main-purple;
    border: 1px solid $color-main-purple;
    border-radius: 2px;
    padding: 6px 10px 6px;
    line-height: 1;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
        color: #fff;
        background: darken($color-main-purple, 10);
        border: 1px solid darken($color-main-purple, 10);
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.btn.--red-1 {
    color: #fff;
    background-color: #ef4444;
    border-color: #ef4444;

    &:hover {
        background: lighten(#ef4444, 5%);
        //color: #dc3545;
    }
}

.btn.--green-1 {
    color: #fff;
    background-color: #10b981;
    border-color: #10b981;

    &:hover {
        background: lighten(#10b981, 5%);
        //color: #dc3545;
    }
}

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-main-1 {
    border: 1px solid $color-main-purple;
    background: #fff;
    color: $color-main-purple;

    &:hover {
        background: $color-main-purple;
        color: #fff;
    }
}
