<template>
  <div
    :style="{ backgroundColor: backgroundColor, width: size, height: size }"
    class="avatar"
  >
    <span
      class="initials"
      :style="{ fontSize: `calc(${size} / 2.2)` }"
    >{{ initials }}</span>
  </div>
</template>

<script>

import avaColor from '@/utils/avaColor';
import shortName from '@/utils/shortName';

export default {
	props: {
		name: {
			type: String,
			required: true
		},
		size: {
			type: String,
			default: '50px'
		},
		clientId: {
			type: [
				String,
				Number
			],
			required: true
		}
	},
	computed: {
		initials() {
			return shortName(this.name);
		},
		backgroundColor() {
			return avaColor(this.initials, this.clientId);
		}
	},
};
</script>

<style scoped>
.avatar {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.initials {
    color: white;
}
</style>