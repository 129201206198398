.call-wrapper {
	position: relative;
	background: #fff;
	z-index: 1000;
	width: 100%;
	//top: 0;
	//left: 0;
	//right: 0;
	//bottom: 0;
	//display: flex;

	border-bottom: 1px solid #e5e5e5;
	padding: 10px 20px;
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__text {
		margin: auto;
		font-weight: 500;
	}
}