
.modal-box-1 {
    max-width: 652px;
}

.error {
    height: 21px;
    color: red;
}

.template-message-modal {
    &__body {
        padding: 10px 55px 30px 55px;

        @media screen and (max-width: 557px) {
            padding-bottom: 5px;
        }
    }

    &__info-icon {
        position: absolute;
        top: 29px;
        left: 28px;
        color: #A23A99;
    }


    .textarea {
        max-width: 398px;
        width: 100%;
        background: #f7f7fd;
        border: 1px solid #f7f7fd;
        border-radius: 4px;
        font-size: 13px;
        height: 200px;
        line-height: 24px;
        overflow: auto;
    }

    .button-type {
        appearance: none;
        background: transparent;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 10px 16px;
        font-size: 12px;
        margin-right: 5px;
        cursor: pointer;

        &:hover,
        &--active {
            color: rgba(162, 58, 153, 1);
            border-color: rgba(162, 58, 153, 1);
        }
    }

    .letter-counter {
        font-size: 12px;
        color: #aaaaaa;
    }

    .file-attacher-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 10px;
        flex-wrap: wrap;
        margin-bottom: 10px;
        // margin-top: 54px;
    }

    .file-attacher {
        display: flex;
        align-items: center;

        &__label {
            display: flex;
            cursor: pointer;

            &:hover .file-attacher__icon {
                background-position: -20px 0;
            }
        }

        &__icon {
            margin-right: 5px;
            display: block;
            background: url('../../assets/img/attachment.svg');
            width: 20px;
            height: 20px;
            background-size: cover;
        }

        &__input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    &__close-btn {
        position: absolute;
        width: 16px;
        height: 16px;
        background: url('../../assets/img/close.svg');
        background-size: cover;
        cursor: pointer;
        right: 30px;
        top: 30px;

        &:hover {
            background-position: 16px 0;
        }
    }

    .upload-ready-file {
        font-weight: 400;
        font-size: 14px;
        color: #949494;
    }

    .submit-button {
        background: #a23a99;
        border: 1px solid #a23a99;
        border-radius: 6px;
        padding: 12px 30px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        cursor: pointer;

        &:disabled {
            background: #72466e;
            cursor: auto;
        }
    }
}

.files-type {
    margin-bottom: 10px;
}

@media screen and (max-width:557px) {
    .files-type {
        max-width: 180px;

        .button-type:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    .file-attacher-wrapper {
        margin-top: 26px !important;
        border-bottom: none !important;
    }
}

@media screen and(max-width:475px) {

    .file-attacher-wrapper {
        max-width: 210px;
        row-gap: 10px;
    }
}
