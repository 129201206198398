
@import '@/styles/_variables.scss';

.modal__box {
    max-width: 1300px;
    font-family: 'Roboto Flex', sans-serif;
}

.modal-box-1 {
    padding: 10px;
}

.modal-box-1__head {
    border: none;
}

.modal-box-1__text {
    margin: 0 20px;
}

.modal-box-1__title {
    font-size: 20px;
    font-weight: 600;
    color: #17171c;
    letter-spacing: 0.5px;
}

.close {
    cursor: pointer;
}

.union {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
    padding: 10px 0;

    &__col {
        flex: 1 1 33.333333%;
        margin: 0 15px;
        padding: 15px;
        border-radius: 8px;

        &--1 {
            background: #e8eefc;
        }

        &--2 {
            background: #fce8e8;
        }

        &--3 {
            background: #f5eff4;
        }

        &:nth-last-child(1) {
            border: none;
        }
    }

    // &__wrap {
    //     //background: #ececec;
    // }

    &__wrap-deals {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    // &__block {
    // }

    &__header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }
}

.form-group {
    background: #fff;
    padding: 1px 12px;
    border-radius: 8px;
    border: 1px solid $color-border;
    margin-bottom: 15px;

    &:focus-within {
        border-color: $color-main-purple;

        .form_title {
            color: $color-main-purple;
        }
    }
}

.form-title {
    font-size: 12px;
    color: #9d9eaf;
    margin: 0;
    font-weight: 400;
}

.form-control {
    border: none;
    height: auto;
    padding: 0;
    font-size: 14px;
    color: #17171c;
}

.form .form-group:nth-last-child(1) {
    border: none;
}

.form .form-group:nth-last-child(1) {
    margin: 0 !important;
}

.input-group {
    margin: 0 0 0 0;
    position: relative;

    &.--fluid {
        margin: 0;
    }
}

.input-with-save {
    position: relative;
}

.copy {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 24px;
    height: 18px;
    cursor: pointer;
    transition: 0.2s;
    z-index: 10;
    background: #ececec;

    &.--white {
        background-color: transparent;
    }

    &:hover:before {
        opacity: 1;
    }

    &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('./../../assets/img/copy.svg') no-repeat center;
        position: absolute;
        top: 1px;
        left: 3px;
        opacity: 0.8;
    }
}

.union-deal {
    background: #fff;
    border-radius: 8px;

    &__head {
        border-radius: 8px 8px 0 0;
        padding: 12px;
        background: #e3e3e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__status {
        font-weight: 500;
        font-size: 12px;
        padding: 3px 8px;
        border-radius: 5px;
        color: #0b225b;
        background-color: #a4baf4;
    }

    &__body {
        padding: 12px;
    }

    &__footer {
        padding: 10px 15px;
        display: flex;
        justify-content: flex-end;
    }

    &__id {
        font-weight: 500;
    }

    &__field {
        display: flex;
        align-items: start;
        gap: 20px;
        margin-bottom: 8px;
    }

    &__check {
        margin: 0;
        background: #a23a99;
        color: #fff;
        padding: 7px 9px;
        border-radius: 5px;

        &.--check {
            background: #af2020;
            border: 1px solid #a23a99;
            color: #fff;
        }
    }

    &__union {
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 16px;
    }
}

.union-contact {
    &__name {
        font-weight: 500;
    }

    &__phone {
        & span {
            font-weight: 500;
        }
    }

    &__check {
        width: 100%;
        margin: 10px 0 0;
        background: #fff;
        color: #a23a99;

        &.--check {
            background: #a23a99;
            color: #fff;
        }
    }

    &__union {
        width: 100%;
        margin: 10px 0 0;
    }
}

.--child {
    position: relative;
    margin-left: 15px;
}

.--child-arrow {
    position: absolute;
    top: -10px;
    left: -13px;
    width: 1px;
    height: 63px;
    background: #9d9eaf;
}

.deals__title {
    font-size: 18px;
    font-weight: 500;
}

.alert-warning {
    width: 100%;
    text-align: center;
    border: none;
    background: transparent;
    font-size: 16px;
    color: #9d9eaf;
}

.deal__field-title {
    width: 120px;
    font-size: 13px;
    color: #505162;
}

.deal__field-value {
    color: #17171c;
    font-size: 13px;
}
