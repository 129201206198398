
@import '../../../../styles/variables.scss';
.select-group {
    background: #fff;
    padding: 1px 12px;
    border-radius: 8px;
    border: 1px solid $color-border;
    &--child {
        position: relative;
        margin-left: 20px;
    }

    &--parent {
        position: relative;
    }

    &:focus-within {
        border-color: $color-main-purple;

        .profile-form__title {
            color: $color-main-purple;
        }
    }
}
