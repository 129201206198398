@import './../variables';

.modal {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -1px;
    left: 0;
    bottom: -1px;
    right: 0;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px;

    &.--scroll {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__main {
        min-height: 100%;
        display: flex;
    }

    &__box {
        margin: auto;
        overflow: hidden;
        background: #fff;
        border-radius: 20px;
        position: relative;
        max-width: 1200px;
    }
}

.modal-box-1 {
    &__head {
        border-bottom: 1px solid #e5e5e5;
        color: #5b5b5b;
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 18px;
        line-height: 50px;
        padding: 0 30px 0 0;
    }

    &__close {
        width: 16px;
        height: 50px;
        position: relative;
        cursor: pointer;
        margin: 0 0 0 15px;

        &:before {
            position: absolute;
            top: 19px;
            display: block;
            content: '';
            width: 14px;
            height: 14px;
            background: url('assets/close-grey.svg') no-repeat center;
        }
    }

    &__body {
        padding: 10px 20px;
    }
}

.modal-dialog {
    background: #fff;
    border-radius: 5px;

    &__header {
        display: flex;
        border-bottom: 1px solid $color-grey-90;
    }

    &__body {
        display: flex;
    }
}

.modal-transition-1 {
    &-enter {
        //Начало анимации появления

        &.modal {
            opacity: 0;
        }

        & .modal__box {
            opacity: 0;
            transform: scale(0.1, 0.1);
        }
    }

    &-enter-active {
        //Анимация появления идет

        &.modal {
            transition: 0.3s ease;
        }

        & .modal__box {
            transition: 0.3s ease;
        }
    }

    &-enter-to {
        //Анимация появления закончилась
    }

    &-leave {
        //Начало анимации исчезновения
    }

    &-leave-active {
        //Анимация исчезнованеия идет
    }

    &-leave-to {
        //Анимация исчзноваения закончилась
    }
}

.modal-deal {
    .modal__main {
        height: 100%;
    }

    .modal__box {
        width: 920px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    &__col-1 {
        flex: 0 0 270px;
        border-right: 1px solid #e5e5e5;
    }

    &__col-2 {
        flex: 1 1;
    }

    &__col-1,
    &__col-2 {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__head {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px 8px 0 20px;
        display: flex;
        gap: 10px;
        align-items: center;
        background: transparent;
        //@media (max-width: 425px){
        //    display: block;
        //}
    }

    &__tabs {
        display: flex;
        align-self: self-end;
        gap: 1px;
        @media (max-width: 478px){
            margin-right: 0;
        }
    }

    &__tab {
        width: 82px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        background: #e6e6e6;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        transition: 0.2s;
        color: #1C1C1C;
        @media (max-width: 425px){
            padding: 5px 10px;
        }
        &.--active {
            color: #fff;
            background-color: $color-main-purple;
        }
    }

    &__body {
        flex: 1 0 auto;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;
    }

    &__name {
        font-size: 16px;
        font-family: "Roboto Flex", sans-serif;
        font-weight: 500;
        line-height: 24px;
        pointer-events: auto;
        flex: 1 0 auto;

        & span {
            color: #17171c;
        }
    }

    &__tab-item {
        background: #e5e5e5;
        border-radius: 50%;
        margin: 15px 0 0 10px;
        width: 30px;
        height: 30px;
        position: relative;
        cursor: pointer;
        transition: 0.2s;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 7px;
            left: 7px;
            width: 16px;
            height: 16px;
        }

        &:hover,
        &.--active {
            background: $color-main-purple;
        }
    }

    &__communication {
        //margin: 15px 0 0 15px;

        &:before {
            background: url('assets/img/communication.svg') no-repeat 50%;
        }
    }
    &__profile:before {
        background: url('assets/img/profile.svg') no-repeat 50%;
    }

    &__tasks:before {
        background: url('assets/img/tasks.svg') no-repeat 50%;
    }

    &__close {
        display: flex;
        justify-content: center;
        width: 32px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        margin: 0 0 0 15px;
        right: -40px;
        background: white;
        border-radius: 50%;
        &:before {
            content: '';
            top: 50%;
            transform: translateY(-50%);
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            background: url('assets/img/close.svg');
            background-size: cover;
        }

        &:hover:before {
            background-position: 12px 0;
        }
        @media (max-width: 820px){
            top: 10px;
           right: 10px;
        }
    }
}

.modal-client-contact {
    &__delete-phone {
        border: none;
        background: transparent;
        outline: none;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        margin: 0 0 0 15px;
        width: 20px;
        height: 20px;

        &:disabled {
            opacity: 20%;
            cursor: default;
        }

        &:before {
            position: absolute;
            top: 5px;
            right: 5px;
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            background: url('assets/close-grey.svg') no-repeat center;
        }
    }

    &__btn-add-phone {
        width: 100% !important;
        background: #e8e8e8 !important;
        text-align: center;
        color: #787a7d !important;
        border: 1px solid #e8e8e8 !important;

        &:hover {
            background: #fff !important;
            color: #787a7d !important;
        }
    }
}

@media (max-width: 820px) {
    .modal-deal__col-1 {
        display: none;
    }
    .modal-deal__tab-item {
        margin: 15px 0 0 5px;
    }

    .modal {
        padding: 10px;
    }
}

@media screen and (max-width: 524px) {
    .modal-deal__tab-name {
        display: none !important;
    }
}
