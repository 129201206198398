
@import '@/styles/_variables.scss';

.deal-tabs {
    margin: 10px 20px;

    button {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 10px;
        border-bottom: 2px solid #17171c;
        font-weight: 400;

        &:hover {
            opacity: 0.8;
        }

        &.--active {
            font-weight: 500;
            border-bottom: 2px solid $color-main-purple;
        }
    }
}

.deals {
    width: 100%;
    height: 100%;
}
div + .deals{
    height: calc(100% - 42px);
}
