
@import '@/styles/_variables.scss';

.modal-box__head {
	font-family: "Roboto Flex", sans-serif;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-box__title {
	font-size: 20px;
	font-weight: 600;
}

.modal-box__close {
	cursor: pointer;
	width: 24px;
	height: 24px;
}
