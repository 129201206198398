
.deal-calendar-task {
    cursor: pointer;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    &__type {
        position: absolute;
        font-size: 9px;
        top: 6px;
        right: 6px;
        padding: 3px 7px;
        border-radius: 5px;
        color: #000000;
    }

    &__description {
        font-size: 13px;
        font-weight: 400;
        color: #575757;
    }

    &__range {
        font-size: 12px;
        font-weight: 500;
        color: #17171C;
    }
}
