
button {
    border: none;
    background: none;
    cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.remove-modal {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
    }

    &__error {
        color: red;
        font-size: 12px;
    }

    &__submit-button {
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        background-color: #B9439D;
        color: #fff;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        margin-left: auto;
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
    }
}

.closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon {
    cursor: pointer;
}

.modal-transition {
    &-enter {
        opacity: 0;

        & .remove-modal {
            opacity: 0;
            transform: scale(0.1, 0.1);
        }
    }

    &-enter-active {
        transition: opacity 0.3s ease, transform 0.3s ease;

        & .remove-modal {
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }

    &-enter-to {
        opacity: 1;

        & .remove-modal {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-leave {
        opacity: 1;

        & .remove-modal {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-leave-active {
        transition: opacity 0.3s ease, transform 0.3s ease;

        & .remove-modal {
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }

    &-leave-to {
        opacity: 0;

        & .remove-modal {
            opacity: 0;
            transform: scale(0.8);
        }
    }
}
