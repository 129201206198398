//.deal-transfer-block {
//	//border-bottom: 1px solid #FFCC90;
//	//border-top: 1px solid #FFCC90;
//	//padding: 10px 20px;
//	//background: rgba(#FFEECC, .7);
//	//background: #fff;
//	//margin: -1px 0 0;
//	display: flex;
//	justify-content: space-between;
//	align-items: center;
//
//	&.--fixed {
//		background: rgba(#FFEECC, .7);
//		padding: 10px 20px;
//		border-bottom: 1px solid #e5e5e5;
//
//		& .deal-transfer-block__info {
//			font-weight: 500;
//		}
//	}
//
//	&.--static {
//		padding: 0;
//
//		& .deal-transfer-block__info {
//			font-weight: normal;
//		}
//	}
//
//	&__info {
//		//color: #af570c;
//		//font-weight: 500;
//	}
//
//	&__buttons {
//
//		& button {
//			margin: 0 0 0 10px;
//		}
//	}
//}

body .deal-transfer-block {
    $root: '.deal-transfer-block';
    background: #f1f1f4;
    padding: 20px 20px 10px;

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // background: #ffb74d;
        background: #f4dea4;
        padding: 15px;
        border-radius: 8px;
    }

    &__info {
        //color: #af570c;
        font-weight: 400;
    }

    &__buttons {
        flex: 0 0 auto;
    }

    &__cancel-btn,
    &__refuse-btn {
        margin: 0 0 0 10px;
        color: #dc3545;
        background-color: transparent;
        border: none;
        font-weight: 500;

        &:hover {
            color: #dc3545;
            background-color: transparent;
            border: none;
            opacity: 0.7;
        }
    }

    &__accept-btn {
        margin: 0 0 0 10px;
        color: #28a745;
        background: transparent;
        border: none;
        font-weight: 500;

        &:hover {
            color: #28a745;
            background-color: transparent;
            border: none;
            opacity: 0.7;
        }
    }

    &--incoming {
        #{$root}__wrap {
            background: rgba(184, 132, 255, 0.2);
            border: 1px solid rgba(103, 58, 183, 0.4);
        }

        #{$root} {
            &__cancel-btn,
            &__refuse-btn {
                border-color: rgba(103, 58, 183, 0.4);

                &:hover {
                    color: #fff;
                    background-color: #dc3545;
                    border-color: #dc3545;
                }
            }

            &__accept-btn {
                border: none;

                &:hover {
                    color: #fff;
                    background-color: #28a745;
                    border-color: #28a745;
                }
            }
        }
    }
}
