
.message-audio__wrapper {
    display: flex;
    align-items: start;

    .tooltip-wrapper {
        margin-top: 13px;
    }

    .tooltip-icon {
        width: 14px;
        height: 14px;
    }

    .tooltip-content {
        color: #575757;

        div {
            span {
                color: #1C1C1C;
            }
        }
    }
}
