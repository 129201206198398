
button {
	display: inline-flex;
	outline: none;
	border: none;
	background: none;
}

img {
	padding: 0;
	margin: 0;
}

.void {
	display: flex;
	align-items: center;
	line-height: normal;

	.reset {
		cursor: pointer;
		padding: 5px;
	}
}
.template-wrap{
    padding: 8px;
    width: 100%;
}
.template_msg {
    padding: 0;
	border: none;
}
