
.tabs {
    height: 100%;

    &__header {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;

        &.--outlined {
            border-bottom: 4px solid #F1F1F1;
        }
    }
}

.tabs-header {

    &__item {
        &.--outlined {
            padding-bottom: 15.5px;
            font-size: 15px;
            line-height: 17.58px;
            margin-right: 24px;
            position: relative;
            color: black;
            transition: 0.5s;
            cursor: pointer;

            &::after {
                opacity: 0;
            }

            &.--active {
                opacity: 1;
                color: #B1409B;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    opacity: 1;
                    left: 0;
                    bottom: -4px;
                    background: #B1409B;

                }
            }
        }

        &.--filled {
            padding: 8px 20px;
            font-size: 14px;
            line-height: 20px;
            border-radius: 12px 12px 0 0;
            background: #E6E6E6;
            color: #1C1C1C;
            cursor: pointer;
            &.--active {
                color: white;
                background: #B9439D;
            }
        }

    }
}
