
@import '../../styles/variables';

.funnels-info {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #575757;

    &__wrapper {
        display: flex;
        align-self: flex-start;
        gap: 16px;
        margin-left: 20px;
    }

    &__name {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: #1C1C1C;
    }
}

.move-to-funnel{
    display: flex;
    background: #B9439D;
    border-radius: 4px;
    padding: 4px 20px;
    justify-content: center;
    color: white;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}
.profile__body {
    position: relative;
}

.profile__main-box {
    max-width: 400px;
    align-items: center;
    gap: 20px;
}

.deal-body {
    width: 100%;
}

.deal-info {
    margin-top: 10px;
    // padding: 16px;
    // background: #ffffff;
    border-radius: 8px;
    border: 1px solid #f1f1f4;
}

.text-right {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #1C1C1C;
    cursor: pointer;
    gap: 5px;
}

.btn {
    padding: 10px 60px;
    font-size: 15px;
    border-radius: 5px;
}

.deal-description {
    margin: 10px auto;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #575757;
    font-size: 13px;
}

.btn-move {
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: -30px;
    right: 20px;
    border: none;
    background: none;

    &:hover {
        background: transparent;
        border: none;
        opacity: 0.8;
    }

    .tooltip-text {
        left: -100px;
        bottom: -100%;
    }
}

.profile__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.is-read-only {
    opacity: 0.5;
    pointer-events: none;
}
