
.header-settings {
    width: 30px;
    height: 50px;
    flex: 0 0 30px;
    position: relative;
    display: inline-block;
    transition: 0.2s;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &.mobile {
        margin-right: 110px;
        order: 4;
    }

    &:hover {
        border-bottom-color: #4A1C3F;
        background: rgba(255, 255, 255, .2);
    }

    &:hover .header-settings__icon:before {
        opacity: 0.8;
    }

    &__icon {
        width: 30px;
        height: 52px;
        position: relative;

        &:before {
            content: '';
            display: block;
            background: url('./../assets/img/setting.svg') no-repeat left;
            background-size: cover;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 14px;
            left: 4px;
            transition: 0.2s;
        }
    }

    &__dropdown {
        position: absolute;
        max-width: 300px;
        min-width: 300px;
        top: 50px;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transform: translateX(-50%);
        background: #fff;
        border-radius: 4px;
        padding: 10px 15px;
        border: 1px solid #dbdbdb;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        transition: 0.2s;
        cursor: auto;

        &.--show {
            top: 60px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        &:after,
        &:before {
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            border-width: 6px;
            border-bottom-color: #dbdbdb;
        }

        &:after {
            border-width: 5px;
            border-bottom-color: #fff;
        }
    }

    &__item {
        position: relative;
    }

    &__item + &__item {
        margin: 10px 0 0;
    }

    &__popup {
        //position: absolute;
        //top: 0;
        //left: 0;
        //right: 0;
        //bottom: 0;
        //z-index: 1;
        display: flex;
        padding: 15px;
        background: rgba(255, 255, 255, 0.9);

        & > span {
            margin: auto;
            color: #f44336;
            font-weight: 500;
            line-height: 1.2;
            text-align: center;
        }
    }

    &__head {
        font-weight: 700;
        margin: 0 0 4px;
    }

    &__body label {
        font-weight: 400 !important;
    }

    &__operating-mode {
        margin: 5px 0 0;
        font-size: 12px;
        line-height: 1.2;
        font-style: italic;
        color: #e8a04b;
    }
}
