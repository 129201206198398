
.document {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #DBEAFE;
    padding: 0.75rem;
    border-radius: 0.5rem;

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        &-open {
            padding: 0;
            margin: 0;
            border: none;
            background-color: inherit;
            color: #3B82F6;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                color: #2563eb;
                text-decoration: underline;
            }

            &:active {
                color: #1d4ed8;
                transform: scale(0.98);
            }
        }

        &-delete {
            padding: 0;
            margin: 0;
            border: none;
            background-color: inherit;
            color: #EF4444;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                color: #dc2626;
                text-decoration: underline;
            }

            &:active {
                color: #b91c1c;
                transform: scale(0.98);
            }
        }
    }
}
