*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.tw-absolute {
  position: absolute !important
}

.tw-relative {
  position: relative !important
}

.tw-inset-0 {
  inset: 0px !important
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.tw-mb-0 {
  margin-bottom: 0px !important
}

.tw-mb-1 {
  margin-bottom: 0.25rem !important
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important
}

.tw-mb-4 {
  margin-bottom: 1rem !important
}

.tw-mt-10 {
  margin-top: 2.5rem !important
}

.tw-block {
  display: block !important
}

.tw-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important
}

.tw-h-10 {
  height: 2.5rem !important
}

.tw-h-2\.5 {
  height: 0.625rem !important
}

.tw-h-6 {
  height: 1.5rem !important
}

.tw-h-\[12px\] {
  height: 12px !important
}

.tw-h-\[8\.88px\] {
  height: 8.88px !important
}

.tw-max-h-36 {
  max-height: 9rem !important
}

.tw-min-h-32 {
  min-height: 8rem !important
}

.tw-w-0\.5 {
  width: 0.125rem !important
}

.tw-w-2\.5 {
  width: 0.625rem !important
}

.tw-w-\[164px\] {
  width: 164px !important
}

.tw-w-\[406px\] {
  width: 406px !important
}

.tw-w-\[740px\] {
  width: 740px !important
}

.tw-w-\[8\.88px\] {
  width: 8.88px !important
}

.tw-w-full {
  width: 100% !important
}

.tw-grow {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important
}

.-tw-rotate-45 {
  --tw-rotate: -45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.tw-rotate-45 {
  --tw-rotate: 45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.tw-cursor-not-allowed {
  cursor: not-allowed !important
}

.tw-cursor-pointer {
  cursor: pointer !important
}

.tw-resize-none {
  resize: none !important
}

.tw-flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important
}

.tw-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important
}

.tw-justify-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important
}

.tw-justify-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important
}

.tw-gap-2 {
  gap: 0.5rem !important
}

.tw-gap-4 {
  gap: 1rem !important
}

.tw-gap-y-1 {
  row-gap: 0.25rem !important
}

.tw-rounded {
  border-radius: 0.25rem !important
}

.tw-rounded-full {
  border-radius: 9999px !important
}

.tw-rounded-lg {
  border-radius: 0.5rem !important
}

.tw-rounded-md {
  border-radius: 0.375rem !important
}

.tw-border {
  border-width: 1px !important
}

.tw-border-0 {
  border-width: 0px !important
}

.tw-border-b {
  border-bottom-width: 1px !important
}

.tw-border-solid {
  border-style: solid !important
}

.tw-border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important
}

.tw-border-forty {
  --tw-border-opacity: 1 !important;
  border-color: rgb(230 230 230 / var(--tw-border-opacity)) !important
}

.tw-border-gray {
  --tw-border-opacity: 1 !important;
  border-color: rgb(166 166 166 / var(--tw-border-opacity)) !important
}

.tw-border-primary {
  --tw-border-opacity: 1 !important;
  border-color: rgb(28 28 28 / var(--tw-border-opacity)) !important
}

.tw-bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important
}

.tw-bg-gray {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(166 166 166 / var(--tw-bg-opacity)) !important
}

.tw-bg-green-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important
}

.tw-bg-pink {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 67 157 / var(--tw-bg-opacity)) !important
}

.tw-bg-red-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important
}

.tw-bg-transparent {
  background-color: transparent !important
}

.tw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.tw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important
}

.tw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.tw-pb-3 {
  padding-bottom: 0.75rem !important
}

.tw-pb-4 {
  padding-bottom: 1rem !important
}

.tw-pl-3 {
  padding-left: 0.75rem !important
}

.tw-pl-3\.5 {
  padding-left: 0.875rem !important
}

.tw-pt-2 {
  padding-top: 0.5rem !important
}

.tw-text-left {
  text-align: left !important
}

.tw-text-center {
  text-align: center !important
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important
}

.tw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important
}

.tw-font-medium {
  font-weight: 500 !important
}

.tw-text-gray {
  --tw-text-opacity: 1 !important;
  color: rgb(166 166 166 / var(--tw-text-opacity)) !important
}

.tw-text-pink {
  --tw-text-opacity: 1 !important;
  color: rgb(185 67 157 / var(--tw-text-opacity)) !important
}

.tw-text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(28 28 28 / var(--tw-text-opacity)) !important
}

.tw-text-secondary {
  --tw-text-opacity: 1 !important;
  color: rgb(87 87 87 / var(--tw-text-opacity)) !important
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.tw-outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important
}

.hover\:tw-border-pink:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(185 67 157 / var(--tw-border-opacity)) !important
}

.focus\:tw-border-pink:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(185 67 157 / var(--tw-border-opacity)) !important
}
