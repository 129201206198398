
.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;
}

.modal__title {
    font-size: 18px;
    font-weight: 500;
}

.modal__close {
    background: none;
    border: none;
    cursor: pointer;
}

.reason-wrap {
    background: #FEE2E2;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 40px;
    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #B91C1C;
    }
    .text{
        font-size: 12px;
        line-height: 16px;
        color: #17171C;
    }
    .text-container{
        margin-bottom: 16px;
    }
}

.close-deal-schedule {
  pointer-events: none;
  background: #ededed;
  margin-bottom: 20px;
}
