
.container {
    position: relative;
    user-select: none;
}

.arrow {
	position: absolute;
	top: 15px;
	transform: translateY(-50%);
	right: 17px;
	transition: 0.5s;

	&--opened {
		transform: translateY(-50%) rotate(180deg);
	}
}

.input {
    padding-right: 40px;
    cursor: pointer;
    background: white!important;
	border: 1px solid transparent;
	font-weight: 500;
}

.list {
	width: 100%;
	position: absolute;
	padding: 0;
	background: #fff;
	border-radius: 5px;
	z-index: 100000;
	max-height: 300px;
	overflow: auto;
	box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6);

	&__item {
		list-style: none;
		padding: 10px;
		cursor: pointer;

		&:hover {
			color: #a23a99;
			transition: 0.5s;
		}

		&--active {
			color: #a23a99;
		}
	}
}

@media screen and (max-width: 1440px) {
	.input {
		width: 200px;
	}

	.arrow {
		right: 55px;
	}
	
}
