@import "./../variables";

.message-form-files {
	padding: 0 76px 0 38px;

	&__item {
		display: flex;
		justify-content: space-between;
		margin: 12px 0;

		&:nth-last-child(1) {
			margin: 12px 0 0;
		}
	}

	&__wrap-icon {
		flex: 0 0 36px;
		margin: 0 10px 0 0;
	}

	&__icon {
		width: 36px;
		height: 36px;
		position: relative;

		&.--uploading:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/cloud-upload.svg');
			width: 30px;
			top: 0px;
			left: 3px;
			height: 30px;
			background-size: cover;
		}

		&.--completed:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/cloud-upload.svg');
			width: 30px;
			top: 0px;
			left: 3px;
			height: 30px;
			background-size: cover;
			background-position: -30px 0;
		}
	}

	&__content-wrap {
		flex: 1 0 auto;
	}

	&__title-wrap {
		display: flex;
		justify-content: space-between;
		margin: 0 0 2px;
	}

	&__title {
		flex: 1 0 auto;
		font-size: 12px;
		line-height: 1;
		color: #333;
	}

	&__percent {
		flex: 0 0 30px;
		font-size: 12px;
		text-align: right;
		margin: 0 0 0 5px;
		line-height: 1;
		color: #8F8F8F;
	}

	&__delete-wrap {
		flex: 0 0 14px;
		margin: 0 0 0 5px;
	}

	&__delete {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: #FF5151;
		position: relative;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			display: block;
			background: url('assets/img/message-form-file-delete.svg');
			width: 6px;
			top: 4px;
			left: 4px;
			height: 6px;
		}
	}

	&__status {
		font-size: 10px;
		line-height: 1;
		margin: 0 0 6px;

		&.--completed {
			color: #4CAF50;
		}

		&.--uploading {
			color: #8F8F8F;
		}
	}

	&__progress-bar {
		height: 3px;
		border-radius: 5px;
		overflow: hidden;
		background: #e5e5e5;

		& span {
			display: block;
			height: 3px;
			border-radius: 5px;
			overflow: hidden;
			background: $color-main-purple;
			transition: width .3s ease;
			pointer-events: none;
		}
	}
}