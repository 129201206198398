
.row {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.modal-box-1 {
    max-width: 450px;
}
