@import './../variables';

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
}

input[type='search'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
}

.select-title {
    color: $color-main-purple;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid black;
    margin-bottom: 12px;
}

input[type='radio'],
input[type='checkbox'] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
    cursor: not-allowed;
}

input[type='file'] {
    display: block;
}

input[type='range'] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
}

.form-control {
    display: block;
    width: 100%;
    height: 28px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
    border-color: $color-main-purple;
    outline: 0;
    //box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
    color: #999999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999999;
}

.form-control::-webkit-input-placeholder {
    color: #999999;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
    cursor: not-allowed;
}

textarea.form-control {
    height: auto;
    resize: vertical;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='date'].form-control,
    input[type='time'].form-control,
    input[type='datetime-local'].form-control,
    input[type='month'].form-control {
        line-height: 34px;
    }
    input[type='date'].input-sm,
    input[type='time'].input-sm,
    input[type='datetime-local'].input-sm,
    input[type='month'].input-sm,
    .input-group-sm input[type='date'],
    .input-group-sm input[type='time'],
    .input-group-sm input[type='datetime-local'],
    .input-group-sm input[type='month'] {
        line-height: 30px;
    }
    input[type='date'].input-lg,
    input[type='time'].input-lg,
    input[type='datetime-local'].input-lg,
    input[type='month'].input-lg,
    .input-group-lg input[type='date'],
    .input-group-lg input[type='time'],
    .input-group-lg input[type='datetime-local'],
    .input-group-lg input[type='month'] {
        line-height: 46px;
    }
}

.form-group {
    margin-bottom: 15px;
}

.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
    cursor: not-allowed;
}

.radio label,
.checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
}

.form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0;
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

select.input-sm {
    height: 30px;
    line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
    height: auto;
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
    height: auto;
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

select.input-lg {
    height: 46px;
    line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
    height: auto;
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
    height: auto;
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.has-feedback {
    position: relative;
}

.has-feedback .form-control {
    padding-right: 42.5px;
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
    color: #3c763d;
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
}

.has-success .form-control-feedback {
    color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
    color: #8a6d3b;
}

.has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
    color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    color: #a94442;
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
}

.has-error .form-control-feedback {
    color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
    top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
    top: 0;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

.invalid-feedback {
    width: 100%;
    //margin-top: .25rem;
    line-height: 100%;
    margin: 10px 0 0;
    font-size: 80%;
    color: #dc3545;
}

.form__hr {
    height: 1px;
    background: #e5e5e5;
    margin: 10px 0;
}

select.form-control {
    padding: 0 6px;
}

select.form-control option:disabled,
select.form-control option[disabled] {
    color: #999999;
}

textarea.form-control {
    padding: 3px 12px;
}

.form-grid {
    display: flex;
    justify-content: space-between;
    margin-right: -5px;
    margin-left: -5px;
    position: relative;

    &__col {
        margin: 0 5px;
    }
}
