@import './../variables';

.messages {
    &__wrap {
        position: relative;

        &.--last {
            padding-bottom: 38px;
        }
    }

    &__note-button {
        background: #FEF3C7;
        border: 1px solid #FCD34D;
        border-radius: 5px;
        padding: 4px 8px;
        position: absolute;
        bottom: 12px;
        right: 20px;
        cursor: pointer;
        z-index: 1000;
        transition: 0.2s;
        transform: translateY(0);
        display: flex;
        align-items: center;

        &-icon {
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 4px;
            flex-shrink: 0;
        }

        &.--hidden {
            transform: translateY(calc(100% + 12px));
            @media (max-width: 525px){
                display: none;
            }
        }
    }

    &__note-button {
        background: #FEF3C7;
        border: 1px solid #FCD34D;
        border-radius: 5px;
        padding: 4px 8px;
        position: absolute;
        bottom: 12px;
        right: 20px;
        cursor: pointer;
        @media (max-width: 525px){
            bottom: 52px;
        }
    }

    &__wrapper {
        height: 100%;

        &.--hide {
            opacity: 0;
        }
    }

    &__date {
        overflow: hidden;
        text-align: center;
        position: relative;
        margin: 10px 20px;
        //position: sticky;
        top: 0;
        z-index: 9;

        &:before,
        &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            height: 1px;
            background-color: #A6A6A6;
            margin-top: 1px;
        }

        &:before {
            margin-left: -100%;
            left: -14px;
        }

        &:after {
            margin-right: -100%;
            right: -14px;
        }

        & span {
            display: inline-block;
            vertical-align: middle;
            padding: 1px 10px;
            font-size: 14px;
            color: #aaaaaa;
            background: #fcf9fc;
            border-radius: 10px;
            // border: 1px solid #fcf9fc;
        }
    }

    &__date-sticky {
        overflow: hidden;
        text-align: center;
        margin: 10px 20px;
        position: sticky;
        top: 5px;
        z-index: 2000;
        margin: -35px 0 0;

        & span {
            display: inline-block;
            vertical-align: middle;
            padding: 1px 10px;
            font-size: 14px;
            color: #aaaaaa;
            background: #fcf9fc;
            border-radius: 10px;
            // border: 1px solid #e5e5e5;
        }
    }

    &__empty {
        display: flex;
        height: 100%;

        & span {
            margin: auto;
            color: #9e9e9e;
        }
    }

    &__group {
        padding: 10px 20px;
    }
}

.reply {
    // padding-top: 16px;
    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        border-radius: 0;
        cursor: pointer;
        background: #aaaaaa;
        border-radius: 50%;
        width: 25px;
        height: 25px;
    }

    &__img {
        width: 20px;
        height: 20px;
    }
}
.quoted-row {
    color: #7e7e7e;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.quoted-link {
    text-decoration: none;
    color: #333333;
}
.quoted-icon {
    width: 16px;
    height: 16px;
}

.quotedPict {
    width: 32px;
    height: 32px;
}
.message-quote {
    word-break: break-all;
    margin-left: 7px;
    margin-top: 8px;
    padding: 8px;
    border-radius: 4px;
    background-color: #ffffff;
    cursor: pointer;
    border-left: 4px solid #65c97a;

    &.--instagram {
        border-left: 4px solid #CE8BD0;
    }

    &.--telegram {
        border-left: 4px solid #83BAF7;
    }
}

.message {
    &__attach-docs {
        display: flex;
        flex-wrap: wrap;
        gap: 0.3rem;
        margin-bottom: 20px;
        font-size: 14px;
        padding: 0 9px;

        button {
            background: inherit;
            cursor: pointer;
            color: #3B82F6;
            margin: 0;
            padding: 0;
            transition: all 0.3s ease;

            &:hover {
                text-decoration: underline;
            }
        }

        button:disabled {
            color: #9CA3AF;
            cursor: not-allowed;
        }
    }

    &__left {
        padding: 3px 100px 3px 0px;
        display: flex;
    }

    &__right {
        padding: 3px 0px 3px 100px;
        display: flex;
        justify-content: flex-end;
    }

    &__middle {
        padding: 5px 0px 5px 0px;
    }

    &__wrap {
        position: relative;
        &--sticker {
            .message__box {
                border: none !important;
                background: none !important;
                box-shadow: unset !important;
                &::before {
                    display: none !important;
                }
            }
        }
    }

    &__icon-wrap {
        width: 30px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
        }
    }

    &__content-wrap {
        //flex: 0 1 auto;
        position: relative;
    }

    &__name {
        display: none;
        line-height: 100%;
        font-size: 12px;
        color: #575757;
        margin: 0 0 5px;
        right: 0;
        //white-space: ;
        font-weight: 400;
        top: 0;
        position: relative;

        &--calling {
            display: block;
            position: unset;
            margin: 0 23px 5px;
            position: relative;

            &:before  {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background: url('assets/img/phone-ntf.svg') no-repeat;
                background-size: cover;
                position: absolute;
                top: -2px;
                left: -21px;
            }
        }

        &--whatsapp {
            margin: 0 20px 5px;
            position: relative;

            &:before  {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background: url('assets/img/whatsapp-ntf.svg') no-repeat;
                background-size: cover;
                position: absolute;
                top: -2px;
                left: -19px;
            }
        }

        &--instagram {
            margin: 0 24px 5px;
            position: relative;

            &:before  {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background: url('assets/img/instagram-icon.svg') no-repeat;
                background-size: cover;
                position: absolute;
                top: -2px;
                left: -21px;
            }
        }

        &--telegram {
            margin: 0 24px 5px;
            position: relative;

            &:before  {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background: url('assets/img/telegram.svg') no-repeat;
                background-size: cover;
                position: absolute;
                top: -2px;
                left: -21px;
            }
        }
    }

    &__box {
        background: #fff;
        border: 1px solid #E6E6E6;
        border-radius: 5px;
        padding: 0 0 6px;
        position: relative;
        min-width: 230px;
    }

    &__text {
        font-size: 14px;
        line-height: 16px;
        padding: 6px 50px 10px 10px;
        color: #1C1C1C;
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-line;
        overflow-wrap: break-word;
        font-weight: 400;
    }

    &__note-title {
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 5px;
        color: #B45309;
        font-weight: 500;
    }
 
    &__walkman {
        padding: 6px 6px 15px;
    }

    &__details {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 4px;
        left: 10px;
        right: 10px;
        z-index: 1000;
    }

    &__date,
    &__type,
    &__size {
        font-size: 11px;
        line-height: 10px;
        color: rgba(0, 0, 0, 0.5);
    }

    &__type {
        padding: 0 3px 0 0;
        flex: 0 0 auto;
    }

    &__size {
        padding: 0 10px 0 0;
        flex: 0 0 auto;
    }

    &__date {
        text-align: right;
        flex: 1 0 auto;

        & span {
            background: rgba(0, 0, 0, 0.5);
            color: rgba(255, 255, 255, 1);
            border-radius: 2px;
            padding: 3px 5px;
            display: inline-block;
        }
    }

    &__status {
        width: 16px;
        height: 10px;
        flex: 0 0 16px;
        position: relative;
        margin: 0 0 0 3px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background: url('assets/img/message-status.svg');
            background-size: cover;
            width: 16px;
            height: 10px;
            top: -2px;
            left: 0;
        }

        &.--sending:before {
            background-position: 0 0;
        }

        &.--sent:before {
            background-position: -16px 0;
        }

        &.--delivered:before {
            background-position: -32px 0;
        }

        &.--viewed:before {
            background-position: 16px 0;
        }
        &.--failed::before {
            background: url('assets/img/message-status-failed.svg');
            background-size: 100% 100%;
        }
    }

    &__missed-call-wrap {
        padding: 6px 6px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__missed-call-phone {
        border-radius: 50%;
        border: 1px solid #ff5151;
        width: 26px;
        height: 26px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;
        background: #ff5151;
        border-color: #ff5151;

        &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: url('assets/img/phone-1.svg') no-repeat;
            background-position: -20px 0px;
            background-size: cover;
            position: absolute;
            top: 7px;
            left: 7px;
        }

        &:hover {
            background: #fcfafc;
            border-color: #fcfafc;

            &:before {
                background-position: -30px 0px;
            }
        }

        &-wrap {
            width: 26px;
            height: 26px;
            flex: 0 0 26px;
            margin: 0 5px 0 0;
            display: flex;
            align-items: center;
        }
    }

    &__missed-call {
        &-wrapper {
            padding-bottom: 15px;
        }

        &-info {
            color: #ff5151;
            font-size: 12px;
            
            &-wrap {
                padding: 0 0 0 5px;
                display: flex;
                flex-direction: column;
            }

            &--length {
                color: #1C1C1C;
                font-size: 11px;
            }
        }
    }

    &__document {
        padding: 10px 10px 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__document-download {
        border-radius: 50%;
        border: 1px solid rgba(76, 175, 80, 0.8);
        width: 26px;
        height: 26px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('assets/img/message-download-sprite.svg') no-repeat;
            background-position: 0px 0px;
            background-size: cover;
            position: absolute;
            top: 6px;
            left: 6px;
        }

        &.--telegram {
            border: 1px solid #83BAF7;

            &:before {
                background: url('assets/img/message-download-sprite-tg.svg') no-repeat;
                background-size: cover;
                background-position: 0px 0px;
            }

            &:hover {
                background: #83BAF7;
                border-color: #83BAF7;

                &:before {
                    background-position: -12px 0px;
                }
            }
        }

        &:hover {
            background: rgba(76, 175, 80, 0.8);
            border-color: rgba(76, 175, 80, 0.8);

            &:before {
                background-position: -12px 0px;
            }
        }

        &-wrap {
            width: 26px;
            height: 26px;
            flex: 0 0 26px;
            margin: 0 5px 0 0;
            display: flex;
            align-items: center;
        }
    }

    &__document-info {
        color: rgba(76, 175, 80, 1);
        padding: 0 55px 0 0;
        min-width: 150px;
        word-break: break-word;
        &-wrap {
            flex: 1 0;
            padding: 0 0 0 5px;
        }

        &.--telegram {
            color: #83BAF7;
        }
    }

    &__contact {
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__contact-profile {
        border-radius: 50%;
        border: 1px solid rgba(76, 175, 80, 0.8);
        width: 26px;
        height: 26px;
        position: relative;
        transition: 0.2s;

        &.--telegram {
            border: 1px solid #83BAF7;

            &:before {
                background: url('assets/img/message-contact-sprite-tg.svg') no-repeat;
                background-size: cover;
                background-position: 0px 0px;
            }
        }

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('assets/img/message-contact-sprite.svg') no-repeat;
            background-position: 0px 0px;
            background-size: cover;
            position: absolute;
            top: 6px;
            left: 6px;
        }

        &-wrap {
            width: 26px;
            height: 26px;
            flex: 0 0 26px;
            margin: 0 5px 0 0;
            display: flex;
            align-items: center;
        }
    }

    &__contact-info {
        &-wrap {
            flex: 1 0 auto;
            padding: 0 0 0 5px;
        }

        &-name {
            color: rgba(76, 175, 80, 1);
            padding: 0 30px 0 0;
            line-height: 1;

            &.--telegram {
                color: #83BAF7;
            }
        }

        &-phone {
            font-size: 11px;
            color: #7e7e7e;
            padding: 1px 30px 0 0;
            line-height: 1;
        }
    }

    &__location {
        width: 200px;
        height: 200px;
        border-radius: 3px;
        overflow: hidden;
    }

    &__image {
        // width: 100%;
        width: 100px;
        display: block;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;

        &-zoom {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: 0.2s;
            background: rgba(255, 255, 255, 0.5);

            &:before {
                content: '';
                display: block;
                background: url('assets/img/message-zoom.svg');
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50%;
                left: 50%;
                opacity: 0.5;
                margin: -25px 0 0 -25px;
            }
        }

        &:hover .message__image-zoom {
            opacity: 1;
        }
    }

    &__video {
        width: 226px;
        // border-radius: 3px;
        cursor: pointer;
        overflow: hidden;
        position: relative;

        &-zoom {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;

            transition: 0.2s;
            background: rgba(255, 255, 255, 0.5);
            z-index: 1;

            &:before {
                content: '';
                cursor: pointer;
                display: block;
                background: url('assets/img/message-zoom.svg');
                background-position: 50px 0;
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50%;
                left: 50%;
                opacity: 0.5;
                margin: -25px 0 0 -25px;
            }
        }

        & video {
            width: 100%;
            display: block;
            pointer-events: none;
        }
    }

    &__action {
        background: rgba(0, 0, 0, 0.05);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        //left: 0;
        cursor: pointer;
        opacity: 0;
        transition: opacity ease 0.2;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        &.--note-edit {
            position: absolute;
            transition: 0.2s;

            &:hover:before {
                background: url('assets/img/note-buttons.svg') -28px 0px;
                background-size: cover;
            }

            &:before {
                position: absolute;
                content: '';
                display: block;
                background: url('assets/img/note-buttons.svg') 0px 0px;
                background-size: cover;
                width: 14px;
                top: 8px;
                left: 8px;
                height: 14px;
                background-size: cover;
            }
        }

        &.--note-delete {
            position: absolute;
            transition: 0.2s;

            &:hover:before {
                background: url('assets/img/note-buttons.svg') -42px 0px;
                background-size: cover;
            }

            &:before {
                position: absolute;
                content: '';
                display: block;
                background: url('assets/img/note-buttons.svg') -14px 0px;
                background-size: cover;
                width: 14px;
                top: 8px;
                left: 8px;
                height: 14px;
                background-size: cover;
            }
        }

        &.--audio-download {
            position: absolute;
            transition: 0.2s;

            &:hover:before {
                background: url('assets/img/btn-download.svg') -14px 0px;
                background-size: cover;
            }

            &:before {
                position: absolute;
                content: '';
                display: block;
                background: url('assets/img/btn-download.svg') 0px 0px;
                background-size: cover;
                width: 14px;
                top: 8px;
                left: 8px;
                height: 14px;
                background-size: cover;
            }
        }
    }

    &:hover {
        .message__action {
            opacity: 1;
        }
    }
}

.quote-visit {
    .message__box {
        background-color: rgb(136, 197, 255) !important;
    }
}

.message__box.--failed {
    background: #fff5f5 !important;
    border: 1px solid #ffd8d8 !important;
    &::before {
        display: none !important;
    }
}

.message__box.--missed {
    background: #FEE2E2 !important;
    border: 1px solid #FCA5A5 !important;
    &::before {
        display: none !important;
    }
}

.message.--walkman .message__wrap {
    width: 70%;
}
.message__box .message__contact:nth-child(1) {
    padding-top: 10px;
}
.message__box .message__contact:nth-last-child(2) {
    padding-bottom: 13px;
}

//Common
.messages__group .message:nth-child(1) .message__name {
    display: block;
}
.messages__group .message.--director:nth-child(1) .message__name {
    display: block;
    position: static;
}

%message-name-template {
    display: block;
    position: unset;
    text-align: right;
}

.messages__group .message + .--director .message__name {
    @extend %message-name-template;
}
.messages__group .message.--director + .message .message__name {
    @extend %message-name-template;
}
.messages__group .--director + .--director .message__name {
    display: none !important;
}

.messages__group .message:nth-child(1) .message__content-wrap {
    padding: 16px 0 0;
}
.messages__group .message:nth-last-child(1) .message__box:before {
    display: block;
}
.messages__group .message:nth-last-child(1) .message__icon {
    display: block;
}
//.messages__group .message .message__name { display: block; }
//.messages__group .message .message__content-wrap { padding: 16px 0 0; }
//.messages__group .message .message__box:before { display: block; }
//.messages__group .message .message__icon { display: block; }

//Left
.messages__group .message__left .message__box:before {
    bottom: -2px;
    left: -6px;
}
.messages__group .message__left .message__box {
    border-radius: 5px 5px 5px 0;
}
.messages__group .message__left .message__icon-wrap {
    float: left;
    position: absolute;
    bottom: 0;
    left: -43px;
}
.messages__group .message__left .message__content-wrap {
    margin: 0 0 0 40px;
}
// Reply left
.messages__group .message__left .message__reply-wrap {
    float: right;
    position: absolute;
    bottom: 0;
    right: -60px;
}
.message__left .instagram-messages .message__reply-wrap {
    float: left;
    position: absolute;
    bottom: 0;
    right: -38px;
}
.message__left .gray-whatsapp-messages .message__reply-wrap {
    float: left;
    position: absolute;
    bottom: 0;
    right: -40px;
}

//Right
.messages__group .message__right .message__box:before {
    bottom: -1px;
    right: -12px;
}
.messages__group .message__right .message__box {
    border-radius: 8px 8px 0 8px;
}
.messages__group .message__right .message__icon-wrap {
    float: right;
    position: absolute;
    bottom: 0;
    right: -43px;
}
.messages__group .message__right .message__content-wrap {
    margin: 0 40px 0 0;
}
// Reply right
.messages__group .message__right .message__reply-wrap {
    float: left;
    position: absolute;
    bottom: 0;
    left: -60px;
}
.message__right .instagram-messages .message__reply-wrap {
    float: left;
    position: absolute;
    bottom: 0;
    left: -34px;
}
.message__right .gray-whatsapp-messages .message__reply-wrap {
    float: left;
    position: absolute;
    bottom: 0;
    left: -34px;
}

//WhatsApp
.message.--whatsApp .message__icon {
    background: rgba(76, 175, 80, 0.15);
    border: 1px solid rgba(76, 175, 80, 0.3);
}
.message.--whatsApp .message__box {
    /*background: rgba(76, 175, 80, 0.15);*/
    background: #E4F3E5;
    border: 1px solid #E4F3E5;
    min-width: 230px;
}
.message.--instagram .message__box {
    background: #F2DCEF;
    border: 1px solid #F2DCEF;
    min-width: 230px;
}
.message.--telegram .message__box {
    background: #E4ECEF;
    border: 1px solid #E4ECEF;
    min-width: 230px;
}
.message.--whatsApp .message__right .message__box:before {
    background-position: -23px 0px;
}
.message.--whatsApp .message__left .message__box:before {
    background-position: -33px 0px;
}
.message.--whatsApp .message__left .message__box {
    background: #E9E9E9;
    border: 1px solid #8ACC79;
}
.message.--instagram .message__left .message__box {
    background: #E9E9E9;
    border: 1px solid #CE8BD0;
}
.message.--telegram .message__left .message__box {
    background: #E9E9E9;
    border: 1px solid #83BAF7;
}
.message.--whatsApp .message__left .message__icon {
    background: #eee;
}
// .message.--whatsApp .message__icon:before {
//     top: 6px;
//     left: 6px;
//     width: 16px;
//     height: 16px;
//     background: url('assets/img/whatsapp.svg') no-repeat;
// }
.message.--whatsApp .message__right .message__text {
    padding: 9px 65px 10px 10px;
}

//WhatsApp Text

//WhatsApp Image
.message.--whatsApp.--image .message__box {
    padding: 1px;
}
.message.--whatsApp.--image:nth-last-child(1) .message__left .message__box {
    // border-radius: 5px;
}
.message.--whatsApp.--image .message__box:before {
    display: none;
}
.message.--whatsApp.--image .message__details {
    right: 4px;
}

//Reactions
.reaction-btn {
    display: inline-block;
    padding: 3px 7px;
    border: none;
    border-radius: 100%;
    background-color: #E6E6E6;
    color: #A6A6A6;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.reaction-btn:hover {
    background: #c5c4c4;
}

.reaction-wrap {
    display: flex;
    gap: 4px;
    transform: translateY(-50%);
}

.reaction {
    background-color: #eee;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 204, 0, 0.451);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-client {
        cursor: default;
        background-color: #eee;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid rgba(50, 50, 50, 0.451);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

//Left
.messages__group .message__left .reaction-wrap {
    padding-left: 10%;
}
//Right
.messages__group .message__right .reaction-wrap {
    padding-right: 10%;
}

//WhatsApp Audio Ptt
.message.--whatsApp.--audio .message__wrap {
    width: 85%;
}
.message.--whatsApp.--audio .walkman__play {
    // border: 1px solid #4B9836;
}
//.message.--whatsApp.--audio .walkman__play:hover { background: rgba(76, 175, 80, 0.8); }
.message.--whatsApp.--audio .walkman__pause {
    border: 1px solid rgba(76, 175, 80, 0.8);
}
//.message.--whatsApp.--audio .walkman__pause:hover { background: rgba(76, 175, 80, 0.8); }
.message.--whatsApp.--audio .walkman__stop {
    border: 1px solid rgba(76, 175, 80, 0.8);
}
//.message.--whatsApp.--audio .walkman__stop:hover { background: rgba(76, 175, 80, 0.8); }
.message.--whatsApp.--audio .walkman__time {
    color: rgba(76, 175, 80, 0.8);
}
.message.--whatsApp.--audio .walkman__info {
    color: rgba(76, 175, 80, 0.8);
}
//width: 85%;

//WhatsApp Location
.message.--whatsApp.--location .message__box {
    padding: 1px;
}
.message.--whatsApp.--location:nth-last-child(1) .message__left .message__box {
    // border-radius: 5px;
}
.message.--whatsApp.--location .message__box:before {
    display: none;
}
.message.--whatsApp.--location .message__details {
    right: 4px;
}

//WhatsApp Video
.message.--whatsApp.--video .message__box {
    padding: 1px;
}
.message.--whatsApp.--video:nth-last-child(1) .message__left .message__box {
    // border-radius: 5px;
}
.message.--whatsApp.--video .message__box:before {
    display: none;
}
.message.--whatsApp.--video .message__details {
    right: 4px;
}

//MS
.message.--MS .message__wrap {
    width: 70%;
}
.message.--MS .message__right .message__box:before {
    bottom: -2px;
    right: -6px;
    background-position: 0px 0px;
}
.message.--MS .message__icon:before {
    top: 10px;
    left: 5px;
    width: 18px;
    height: 18px;
    background: url('assets/img/message-ms-icon.svg') no-repeat;
}

//Note
.message.--note .message__icon {
    background: rgba(246, 210, 156, 0.5);
    border: 1px solid rgba(241, 164, 50, 0.6);
}
.message.--note .message__box {
    /*background: rgba(76, 175, 80, 0.15);*/
    background: #FEF3C7;
    border: 1px solid #FCD34D;
}
.message.--note .message__box.director-note {
    // background-color: #85df77;
    // border-color: #57bf47;
    // &::before {
    //     display: none;
    // }
}
.message.--note .message__right .message__box:before {
    background-position: -46px 0px;
}
.message.--note .message__icon:before {
    top: 6px;
    left: 6px;
    width: 16px;
    height: 16px;
    background: url('assets/img/message-note.svg') no-repeat;
}
.message.--note .message__right .message__text {
    padding: 0 65px 0 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    font-size: 12px;
}

//Иконки
.message__left .message__action.--pos1 {
    right: -96px;
}
.message__left .message__action.--pos2 {
    right: -80px;
}
.message__right .message__action.--pos1 {
    left: -95px;
}
.message__right .message__action.--pos2 {
    left: -80px;
}

.message__left .message__action.--callD {
    right: -40px;
}
.message__right .message__action.--callD {
    left: -40px;
}

.message__left .message__action.--pos3 {
    right: -75px;
}
.message__left .message__action.--pos4 {
    right: -40px;
}
.message__right .message__action.--pos3 {
    left: -75px;
}
.message__right .message__action.--pos4 {
    left: -40px;
}

// Reactions dropdown
.reactions-dropdown {
    position: absolute !important;
    overflow: visible !important;
    width: 160px;
    height: 150px;
    bottom: 20px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translateX(-270px);
    background: #fff;
    border-radius: 4px;
    padding: 5px 12px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: 0.2s;
    z-index: 111111114;

    &.--show {
        bottom: 35px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    &:before {
        border-width: 6px;
        border-top-color: #dbdbdb;
        bottom: -12px;
        right: 12px;
    }

    &:after {
        border-width: 5px;
        border-top-color: #fff;
    }

    &__scroll {
        display: block;
        overflow: hidden scroll;
        height: 100%;
        width: calc(100% + 13px);
    }

    &__group {
        margin: 0 0 10px;
    }

    &__head {
        font-weight: 700;
        padding: 0 0 0 4px;
    }

    &__body {
        margin: 0 -3px;
        font-weight: 400;
    }

    &::-webkit-scrollbar {
        width: 12px !important;
    }
}

// .messages__wrap:first-child .messages__group .reactions-dropdown {
// 	bottom: -45px;
// }

// .communication__messages:first-child .messages__wrapper:first-child .messages__wrap:first-child .messages__group:first-child   {
// 	background-color: #000;
// }

.first_message .reactions-dropdown {
    bottom: -150px;
}

.message__left .reactions-dropdown {
    right: -300px;
}
.message__right .reactions-dropdown {
    left: 220px;
}

//Reply button
.reply-button {
    display: inline-block;
    padding: 3px 5px;
    border: none;
    border-radius: 100%;
    background-color: #E6E6E6;
    color: #A6A6A6;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    margin-right: 5px;
}

.reply-button:hover {
    background: #c5c4c4;
}

.walkman {
    display: flex;
    align-items: center;

    &__play-wrap {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        margin: 0 5px 0 0;
        display: flex;
        align-items: center;
    }

    &__pause-wrap {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        margin: 0 5px 0 0;
        display: flex;
        align-items: center;
    }

    &__stop-wrap {
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        margin: 0 10px 0 0;
        display: flex;
        align-items: center;
    }

    &__play {
        border-radius: 50%;
        //border: 1px solid rgba(0, 0, 0, 0.15);
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;
        background: url('assets/img/play.svg') no-repeat center center;
        background-size: cover;

        &:hover {
            background: url('assets/img/play-hover.svg') no-repeat center;
            background-size: cover;
        }

        &.--instagram {
            background: url('assets/player-buttons/play-inst.svg') no-repeat center center;
            background-size: cover;

            &:hover {
                background: url('assets/player-buttons/play-inst-hover.svg') no-repeat center;
                background-size: cover;
            }
        }

        &.--telegram {
            background: url('assets/player-buttons/play-tg.svg') no-repeat center center;
            background-size: cover;

            &:hover {
                background: url('assets/player-buttons/play-tg-hover.svg') no-repeat center;
                background-size: cover;
            }
        }
    }

    &__pause {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;
        display: flex;
        background: url('assets/img/pause.svg') no-repeat center center;

        &:hover {
            background: url('assets/img/pause-hover.svg') no-repeat center center;
        }

        &.--instagram {
            background: url('assets/player-buttons/pause-inst.svg') no-repeat center center;
            background-size: cover;

            &:hover {
                background: url('assets/player-buttons/pause-inst-hover.svg') no-repeat center;
                background-size: cover;
            }
        }

        &.--telegram {
            background: url('assets/player-buttons/pause-tg.svg') no-repeat center center;
            background-size: cover;

            &:hover {
                background: url('assets/player-buttons/pause-tg-hover.svg') no-repeat center;
                background-size: cover;
            }
        }
    }

    &__stop {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;
        background: url('assets/img/stop.svg') no-repeat center center;

        &:hover {
            background: url('assets/img/stop-hover.svg') no-repeat center center;
        }

        &.--instagram {
            background: url('assets/player-buttons/stop-inst.svg') no-repeat center center;
            background-size: cover;

            &:hover {
                background: url('assets/player-buttons/stop-inst-hover.svg') no-repeat center;
                background-size: cover;
            }
        }

        &.--telegram {
            background: url('assets/player-buttons/stop-tg.svg') no-repeat center center;
            background-size: cover;

            &:hover {
                background: url('assets/player-buttons/stop-tg-hover.svg') no-repeat center;
                background-size: cover;
            }
        }
    }

    &__info-wrap {
        display: flex;
        flex-direction: column;
        padding: 0 40px 0 5px;
    }

    &__timeline {
        flex: 1 0 auto;
        display: flex;
    }

    &__info {
        margin: auto 0;
        color: #047857;
        word-break: break-all;

        & span {
            font-size: 12px;
            color: #1C1C1C;
            display: inline-block;
        }
    }

    &__info {
        &.--outgoing,
        &.--incoming {
          color: #047857;
          font-size: 12px;
        }
    }

    &__info {
        &.--length {
          color: #1C1C1C;
          font-size: 11px;
        }
    }

    &__time-wrap {
    }

    &__time {
        padding: 0 0 0 10px;
        font-size: 11px;
        color: #047857;
    }

    &__waveform {
        width: 100%;
        position: relative;
    }

    &__progress {
        position: absolute;
        left: 70px;
    }
}

.system-message {
    display: flex;
    flex-direction: column;

    &__h1 {
        font-weight: 400;
        color: #B9439D;
        font-size: 14px;

        &-wrap {
            text-align: center;
        }
    }

    &__h2 {
        color: #575757;
        font-size: 12px;

        // & span {
        //     font-weight: 500;
        // }

        &-wrap {
            display: flex;
            align-items: center;
            text-align: center;
            position: relative;
          
            &::before,
            &::after {
              content: '';
              flex: 1;
              height: 1px;
              background-color: #A6A6A6;
            }
          
            &::before {
              margin-right: 10px;
            }
          
            &::after {
              margin-left: 10px; 
            }
          
            & > div {
              display: inline-block;
              padding: 0 10px; 
            }
          }
    }

    &__date {
        // float: right;
        font-size: 11px;
        line-height: 10px;
        color: rgba(0, 0, 0, 0.5);
        display: block;
        padding: 7px 0 0;
        text-align: center;
    }
}

.highlight-yellow {
    background-color: yellow;
}

.highlight-orange {
    background-color: orange;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
    .message__left {
        padding: 5px 0px 3px 3px;
    }
    .message__right {
        padding: 3px 0px 3px 5px;
    }
}

@media screen and(max-width:410px) {
    .--MS .message__wrap {
        width: 95% !important;
    }
}
