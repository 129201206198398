
.contacts {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: #e3e3e8;
    border-radius: 8px;
    padding: 10px;

    .input-container {
        display: block;
        margin-top: 0;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: start;

        .input-container {
            width: 100%;
        }
    }

    &__delete {
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
    }

    &__numberbox {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__description {
        font-size: 12px;
        color: #a6a6a6;
        margin: 0;
        padding: 0;
        margin-left: 5px;
    }
}
