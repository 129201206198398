
.categories {
    width: 100%;

    &__image-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        margin-bottom: 1rem;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__image {
            width: 6.25rem;
            height: 4.5rem;
            border-radius: 0.5rem;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__image-name {
            color: #3B82F6;
            margin-top: 0.5rem;
            text-align: center;
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        &__delete-button {
            padding: 0;
            margin: 0.5rem 0 0;
            border: none;
            background-color: inherit;
            color: #EF4444;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                color: #dc2626;
                text-decoration: underline;
            }

            &:active {
                color: #b91c1c;
                transform: scale(0.98);
            }
        }
    }

    &-header {
        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
        }

        &__button {
            color: #3B82F6;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                color: #2563eb;
                text-decoration: underline;
            }

            &:active {
                color: #1d4ed8;
                transform: scale(0.98);
            }
        }
    }

    &__documents {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
}

