
.deal {
	&__new-messages {
		display: flex;
		margin-top: 2px;
		gap: 25px;
	}

	&__new-messages-bpm {
		display: flex;
		align-items: center;
		position: relative;
	}

	&__new-message {
		margin-top: 5px;
		margin-left: 20px;
		background: #ff5151;
		font-size: 9px;
		width: 15px;
		height: 15px;
		text-align: center;
		line-height: 16px;
		border-radius: 50%;
		color: #fff;
	}
}

.scaled-gif {
	position: absolute;
	width: 16px;  
	height: 16px; 
	top: 5px;
}

.whatsapp {
	position: relative;

	&:before {
		content: url('../../../../assets/img/whatsapp-ntf.svg');
		left: -18px;
		position: absolute;
	}
}

.phone {
	position: relative;

	&:before {
		content: url('../../../../assets/img/phone-ntf.svg');
		left: -17px;
		top: 1px;
		position: absolute;
	}
}
