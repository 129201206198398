
.closed-deals-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.filter-item {
    border-radius: 8px;
    padding: 1px;
}

.filter-period {
    display: flex;
    background-color: #fff;
    border: 1px solid #a6a6a6;
    border-radius: 5px;
}

.search {
    background-color: #e6e6e6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;

    input {
        border: none;
        background-color: transparent;
        outline: none;
        color: #505162;
        font-size: 15px;
    }
}

.profile__main-box {
    width: 100%;
    background: #fff;
    padding: 10px;
}

.profile__title {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}

.no-deals-text {
    margin-top: 50px;
    color: #a6a6a6;
    font-size: 16px;
    text-align: center;
}
