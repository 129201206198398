<template>
  <div>
    <div class="message__video">
      <div
        v-if="isLoading2"
        class="placeholder"
      />
      <div
        class="message__video-zoom"
        @click="zoom"
      >
        <div
          v-if="isLoading"
          class="container-spinner"
        >
          <DownloadSpinner
            :width="50"
            :height="50"
          />
        </div>
      </div>
      <video
        ref="videoElement"
        :src="`${src}#t=0.001`"
      />
    </div>
    <div
      v-if="chatType !== 'instagram' && !deal.readOnly"
      v-click-outside="() => (show = false)"
      class="message__reply-wrap"
    >
      <button
        class="reply-button"
        @click="openAnswerWindow"
      >
        <IconReply />
      </button>
      <button
        v-if="chatType == 'whatsapp-360'"
        class="reaction-btn"
        @click="show = !show"
      >
        +
      </button>
      <SmilesMenu
        v-if="show"
        @click="react"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapActions } from 'vuex';
import DownloadSpinner from '@/components/DownloadSpinner.vue';
import IconReply from '@/components/common/Icon/IconReply.vue';
import SmilesMenu from '@/components/funnel/communication/SmilesMenu.vue';

export default {
	name: 'AppFunnelMessageVideoComponent',
	components: { DownloadSpinner, IconReply, SmilesMenu },
	props: [
		'pId',
		'pSrc',
		'chatType',
		'hash'
	],
	emits: ['react'],
	data() {
		return {
			id: this.pId,
			src: this.pSrc,
			isLoading: false,
			isLoading2: true,
			show: false,
		};
	},
	computed: {
		...mapState({
			deal: state => state.modal.deal.data,
		})
	},
	mounted() {
		// if (this.deal.limit > 40) {
		//     this.videoLoaded()
		// }
		const videoElement = this.$refs.videoElement;
		videoElement.addEventListener('loadeddata', this.videoLoaded());
	},
	methods: {
		...mapActions({
			openModal: 'modal/openModal',
			setModalData: 'modal/setModalData',
			openAnswer: 'answer/openAnswer',
		}),
		videoLoaded() {
			this.isLoading2 = false;
		},
		openAnswerWindow() {
			const data = {
				body: this.src,
				id: this.id,
				type: 'video',
				chatType: this.chatType,
				hash: this.hash,
			};
			this.openAnswer(data);
		},
		zoom() {
			if (this.isLoading) {
				return; 
			}
			if (this.src) {
				const video = document.createElement('video');
				video.src = this.src;

				video.addEventListener('loadedmetadata', () => {
					const videoWidth = video.videoWidth > window.innerWidth ? window.innerWidth - 150 : video.videoWidth;
					const videoHeight = video.videoHeight + 80 > window.innerHeight ? window.innerHeight - 190 : video.videoHeight;
		
					this.setModalData({
						modal: 'videoZoom',
						data: {
							title: 'Просмотр видео',
							src: this.src,
							width: `${videoWidth}px`,
							height: `${videoHeight}px`,
						},
					});
					this.openModal('videoZoom');
				});
				
			} else {
				this.downloadAgain();
			}
		},
		downloadAgain() {
			this.isLoading = true;
			let timeout = setTimeout(() => {
				this.isLoading = false;
			}, 60 * 1000);
			this.$socket.emit(
				'downloadAgain',
				{
					id: this.pId,
				},
				result => {
					this.isLoading = false;
					if (timeout) {
						clearTimeout(timeout);
					}
					if (result.success) {
						this.src = result.body;
					}
					if (!result.success && result.message) {
						this.$toast.error(result.message); 
					}
				},
			);
		},
		react($event) {
			this.$emit('react', $event.target.dataset.c);
			this.show = false;
		},
	},
};
</script>
<style scoped>
.placeholder{
    min-width: 200px;
    min-height: 200px;
}

.container-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
