.player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;

  &__icons {
    display: flex;
    flex: 0 1 auto;
    padding: 0 10px 0 0;
  }

  &__info {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__timer {
    flex: 0 1 90px;
    text-align: right;
  }

  &__play {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #57bf47;
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0;
  }

  &__pause {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #57bf47;
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0;
  }

  &__stop {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #57bf47;
    position: relative;
    cursor: pointer;
    display: block;
    margin: 5px 0 0 5px;
  }

  &__waveform {
    margin: -10px 0;
    position: relative;

    & wave > wave {
      //top: 10px !important;
      //bottom: 10px !important;
      border: none;
    }

    & cursor {
      top: 5px !important;
      bottom: 5px !important;
    }
  }
}