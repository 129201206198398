
.arrow-component-right {
    position: absolute;
    right: -7px;
    bottom: -6px;
}

.arrow-component-failed {
    position: absolute;
    right: -7px;
    bottom: -7px;
}

.arrow-component-left {
    position: absolute;
    left: -7px;
    bottom: -6px;
}

.arrow-component-right-note {
  position: absolute;
  right: -7px;
  bottom: -6px;
}

.arrow-component-right-missed {
  position: absolute;
  right: -7px;
  bottom: -6px;
}

.arrow-component-left-missed {
    position: absolute;
    left: -7px;
    bottom: -6px;
}
